import React, { useEffect, useState } from "react";

import { Container } from "reactstrap";
import { Row, Col, Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import EmployeeCard from "./EmployeeCard";
import { setEmployeeListInfo } from "redux/actions/super-admin/EmployeeListAction";
// import history from "helpers/History";
import PaginationComponent from "components/pagination/Pagination";
import DashboardHeader from "components/Headers/DashboardHeader";
import { useHistory } from "react-router-dom";
import SearchIcon from "../../../../assets/img/icons/customIcons/searchicondark.svg";
import WorkingIcon from "../../../../assets/img/icons/common/working.png";
import BenchIcon from "../../../../assets/img/icons/common/bench.png";
import ContractIcon from "../../../../assets/img/icons/common/contract.png";
import CustomFilter from "components/customFilter";
// import moment from "moment";

function EmployeeList({ companyId }) {
  const dispatch = useDispatch();

  const history = useHistory();

  const [searchFormData, setSearchFormData] = useState({
    experience: null,
    page: null,
    fromDate: null,
    toDate: null,
    search: null,
    limit: null,
    name: null,
  });
  const [tableHeight, setTableHeigth] = useState(300);
  const [tab, setTab] = useState(1);
  const [isFilter, setIsFilter] = useState(false);
  const fetchEmployeeListResult = useSelector(
    (state) => state.EmployeeListReducer.result
  );

  const fetchEmployeeListLoader = useSelector(
    (state) => state.EmployeeListReducer.loader
  );

  const handleEmployeeSearchOnChange = (e, v) => {
    setSearchFormData({ ...searchFormData, [e]: v });
  };

  const clearDate = () => {
    const data = { ...searchFormData, fromDate: null, toDate: null };
    setSearchFormData(data);
    employeeListing("listing", data, companyId);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    employeeListing("listing", searchFormData, companyId);
  };
  const handleSearchOnSelect = (val, name) => {
    let formData =
      name === "experience"
        ? { ...searchFormData, experience: val }
        : { ...searchFormData, search: val };
    setSearchFormData(formData);

    // const data = { ...searchFormData, search: val };

    employeeListing("listing", formData, companyId);
  };

  const handleSearchOnChange = (name, val) => {
    if (name === "name") {
      let formData = { ...searchFormData, name: val };
      setSearchFormData(formData);
      employeeListing("listing", { ...searchFormData, name: val }, companyId);
    } else {
      let formData = { ...searchFormData, search: val };
      setSearchFormData(formData);
      employeeListing("listing", { ...searchFormData, search: val }, companyId);
    }
  };

  const handlePagination = (tab) => {
    setTab(tab);
    const finalData = {
      ...searchFormData,
      page: tab,
    };

    employeeListing("listing", finalData, companyId);
  };

  const employeeListing = (type, searchFormData, companyIdValue) => {
    dispatch(setEmployeeListInfo(type, searchFormData, companyIdValue));
  };

  useEffect(() => {
    employeeListing("listing", null, companyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addEmployeeSubmithandler = (e, type) => {
    e.preventDefault();
    history.push({
      pathname: "/admin/add-employees",
      state: {
        type,
      },
    });
  };
  // eslint-disable-next-line
  useEffect(() => {
    const divElement = document.getElementById("tableDiv");

    if (fetchEmployeeListResult?.pages?.length > 1) {
      setTableHeigth(divElement.scrollHeight - 72 + "px");
    } else {
      setTableHeigth(divElement.scrollHeight - 12 + "px");
    }
  });

  return (
    <>
      {companyId ? (
        <Container className="mt-2 main-contentarea" fluid>
          <Row>
            <Col id="tableDiv" md={isFilter ? "9" : "12"}>
              <Row>
                <Col md="10">
                  <div className=" justify-content-between">
                    <h3 class="tab-title">Employee Listing</h3>
                    <div class="status-indication">
                      <p>
                        <span
                          // className="btn-status bg-green"
                          data-toggle="tooltip"
                          style={{ marginTop: "-2px", marginRight: "6px" }}
                        >
                          <img alt="" src={BenchIcon} width={"8px"} />
                        </span>
                        Not Booked
                      </p>
                      <p>
                        <span
                          // className="btn-status bg-orange"
                          style={{ marginTop: "-2px", marginRight: "6px" }}
                          data-toggle="tooltip"
                        >
                          <img alt="" src={WorkingIcon} width={"8px"} />
                        </span>
                        Booked
                      </p>
                      <p>
                        <span
                          // className="btn-status bg-yellow"
                          data-toggle="tooltip"
                          style={{ marginTop: "-2px", marginRight: "6px" }}
                        >
                          <img alt="" src={ContractIcon} width={"8px"} />
                        </span>
                        Booked Ahead
                      </p>
                    </div>
                  </div>
                </Col>

                <Col
                  md="2"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <div className="common-outer-filter">
                    <div className="btn-group new-cutom-filter ">
                      <button
                        type="button"
                        onClick={() => setIsFilter(!isFilter)}
                        className={"btn dropdown-toggle"}
                      >
                        <img alt="" src={SearchIcon} width={"18px"} />
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>

              <EmployeeCard
                searchFormData={{ ...searchFormData, page: tab }}
                companyIdVal={companyId}
                loader={fetchEmployeeListLoader}
              />

              <div
                style={{
                  display: fetchEmployeeListLoader ? "none" : "contents",
                }}
              >
                <PaginationComponent
                  totalCount={fetchEmployeeListResult?.itemCount}
                  handlePagination={handlePagination}
                  activePage={fetchEmployeeListResult?.activePage}
                />
              </div>
            </Col>
            {/* {isFilter && ( */}
              <Col
                md="3"
                style={{
                  height: tableHeight,
                  minHeight: "308px",
                  display: isFilter ? "block" : "none",
                }}
              >
                <CustomFilter
                  tableHeight={tableHeight}
                  isFilter={isFilter}
                  setIsFilter={setIsFilter}
                  clearDate={clearDate}
                  type="employee"
                  handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
                  handleSearch={handleSearch}
                  handleSearchOnSelect={handleSearchOnSelect}
                  handleSearchOnChange={handleSearchOnChange}
                />
              </Col>
            {/* )} */}
          </Row>
        </Container>
      ) : (
        <>
          <DashboardHeader />
          <Container className="mt-2 main-contentarea" fluid>
            <Row>
              <Col id="tableDiv" md={isFilter ? "9" : "12"}>
                <Row>
                  <Col md={"6"}>
                    <h3 class="tab-title">Employee Listing</h3>
                    <div class="status-indication">
                      <p>
                        <span
                          // className="btn-status bg-green"
                          data-toggle="tooltip"
                          style={{ marginTop: "-2px", marginRight: "6px" }}
                        >
                          <img alt="" src={BenchIcon} width={"8px"} />
                        </span>
                        Not Booked
                      </p>
                      <p>
                        <span
                          // className="btn-status bg-orange"
                          style={{ marginTop: "-2px", marginRight: "6px" }}
                          data-toggle="tooltip"
                        >
                          <img alt="" src={WorkingIcon} width={"8px"} />
                        </span>
                        Booked
                      </p>
                      <p>
                        <span
                          // className="btn-status bg-yellow"
                          data-toggle="tooltip"
                          style={{ marginTop: "-2px", marginRight: "6px" }}
                        >
                          <img alt="" src={ContractIcon} width={"8px"} />
                        </span>
                        Booked Ahead
                      </p>
                    </div>
                  </Col>

                  <Col md={"6"}>
                    <div
                      className="d-flex"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        outline
                        className="add-button-common border-0 mr-1"
                        type="button"
                        onClick={(e) =>
                          addEmployeeSubmithandler(e, "single_upload")
                        }
                      >
                        {" "}
                        <span className="add-button">+</span>
                        Add Employee
                      </Button>
                      <Button
                        outline
                        onClick={(e) =>
                          addEmployeeSubmithandler(e, "bulk_upload")
                        }
                        className="add-button-common border-0 mr-1"
                        type="button"
                        title="Import"
                      >
                        <i class="fa fa-upload"></i> Bulk Import
                      </Button>

                      <div className="add-button-common border-0 p-0">
                        <div className="btn-group new-cutom-filter ">
                          <button
                            type="button"
                            onClick={() => setIsFilter(!isFilter)}
                            className={"btn dropdown-toggle"}
                          >
                            <img alt="" src={SearchIcon} width={"18px"} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                <EmployeeCard
                  searchFormData={{ ...searchFormData, page: tab }}
                  companyIdVal={companyId}
                  loader={fetchEmployeeListLoader}
                />

                <div
                  style={{
                    display: fetchEmployeeListLoader ? "none" : "contents",
                  }}
                >
                  <PaginationComponent
                    totalCount={fetchEmployeeListResult?.itemCount}
                    handlePagination={handlePagination}
                    activePage={fetchEmployeeListResult?.activePage}
                  />
                </div>
              </Col>

              {/* {isFilter && ( */}
                <Col
                  md="3"
                  style={{
                    height: tableHeight,
                    minHeight: "308px",
                    display: isFilter ? "block" : "none",
                  }}
                >
                  <CustomFilter
                    tableHeight={tableHeight}
                    type="employee"
                    handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
                    handleSearch={handleSearch}
                    handleSearchOnSelect={handleSearchOnSelect}
                    isFilter={isFilter}
                    setIsFilter={setIsFilter}
                    clearDate={clearDate}
                    handleSearchOnChange={handleSearchOnChange}
                  />
                </Col>
              {/* )} */}
            </Row>
          </Container>
        </>
      )}
    </>
  );
}

export default EmployeeList;
