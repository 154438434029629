const AllBookingReducer = (
  state = { loader: false, result: [], error: null },
  action
) => {
  switch (action.type) {
    case "ALL_BOOKING_LIST_BEGINS":
      return {
        loader: true,
        result: state.result,
        error: null,
      };
    case "ALL_BOOKING_LIST_SUCCESS":
      return {
        loader: false,
        result: action.payload,
        error: null,
      };
    case "ALL_BOOKING_LIST_FAILURE":
      return {
        loader: false,
        result: {},
        error: action.payload,
      };

    case "ALL_BOOKING_DELETE_SUCCESS": {
      let currentAllBookingRequestsList = { ...state.result };
      let allBookingRequestsList =
        currentAllBookingRequestsList && currentAllBookingRequestsList.booking;
      allBookingRequestsList =
        allBookingRequestsList &&
        allBookingRequestsList.filter((item) => item._id !== action.payload);
      currentAllBookingRequestsList = {
        ...currentAllBookingRequestsList,
        booking: allBookingRequestsList,
      };
      return {
        loader: false,
        result: currentAllBookingRequestsList,
        error: null,
      };
    }
    case "ALL_BOOKING_LIST_RESET":
      return {
        loader: false,
        result: [],
        error: null,
      };
    default:
      return state;
  }
};

export default AllBookingReducer;
