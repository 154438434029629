import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
  Input,
  FormFeedback,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import Tables from "views/examples/Tables";
import BookingModal from "components/Modals/BookingModal";
import { axiosAccountInstance } from "api/Request";
import { getApprovedRequestsInfo } from "redux/actions/admin/ApprovedRequestAction";
import toast from "../../../../components/Toastify";
import { fetchUserData } from "helpers/storageData";
import moment from "moment";
import deleteIcn from "../../../../assets/img/icons/customIcons/delete.svg";
import viewIcn from "../../../../assets/img/icons/customIcons/view.svg";
import extendbookingIcn from "../../../../assets/img/icons/customIcons/extend-booking.svg";
import closebookingIcn from "../../../../assets/img/icons/customIcons/close-booking.svg";
import SOWModal from "components/Modals/SOWModal";
import { getAllRequestInfo } from "redux/actions/admin/AllRequestAction";

function AllRequestListCard({ loader, companyId }) {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [data, setData] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [bookingData, setBookingData] = useState({});
  const [bookingModal, setBookingModal] = useState(false);
  const [sowModal, setSOWModal] = useState(false);
  const [sowData, setSOWData] = useState({});
  const [sowHistory, setSOWHistory] = useState([]);
  const [employeeID, setEmployeeID] = useState("");
  const [rejectRequestReason, setRejectRequestReason] = useState("");
  const [sowErrors, setSOWErrors] = useState(null);

  const [isApproveButtonDisabled, setIsApproveButtonDisabled] = useState(true); //multiple click disabled
  const socketInstance = useSelector((state) => state.socketReducer.result);
  const [extentionObj, setExtentionObj] = useState({});
  const [modalExtention, setModalExtention] = useState(false);
  const [bookingIdObj, setBookingIdObj] = useState("");
  const fetchResultAllListRequests = useSelector(
    (state) => state.AllRequestReducer.result
  );
  const [closeDate, setCloseDate] = useState("");
  const [isUpload, setIsUpload] = useState(false);
  const [signedSOWdoc, setSignedSOWdoc] = useState();
  const [errors, setErrors] = useState(null);
  useEffect(() => {
    console.log(
      "fetchResultAllListRequests?.booking  ",
      fetchResultAllListRequests?.booking
    );
    setData(fetchResultAllListRequests?.booking || []);
  }, [fetchResultAllListRequests]);
  // TO_DO : Socket instance delete situation

  const deleteHandler = (data) => {
    setBookingIdObj(data);
    setModal(!modal);
  };
  const PendingDeleteHandler = (id) => {
    setDeleteId(id);
    setDeleteModal(!deleteModal);
  };
  const submit = () => {
    axiosAccountInstance
      .delete(`/deletebooking?id=${deleteId}`)
      .then((result) => {
        // dispatching success
        if (result.data.statusCode === 200) {
          toast.success(result.data.body.message);
          socketInstance.emit("message", {
            type: "UPDATE_DELETE_BOOKING",
            key: deleteId,
            extraInfo: fetchUserData().companyName,
          });
          dispatch(getAllRequestInfo("1", "1", null, ""));
          // dispatch(setPendingRequestInfo("1", "1", null, null));
        }
      })
      .catch((err) => {
        toast.error(err.response.data.body.message);
      });
    setDeleteModal(false);
    setModal(false);
  };

  const validation = () => {
    const errorsFound = {};

    if (!closeDate || closeDate === "") {
      errorsFound["closeDate"] = "Closing Date is required";
    }

    if (Object.keys(errorsFound).length !== 0) {
      setErrors(errorsFound);
      return false;
    }
    return true;
  };

  const submitClosure = () => {
    const isValid = validation();
    if (isValid) {
      const data = {
        bookingId: bookingIdObj.bookingId,
        closingDate: closeDate,
      };
      axiosAccountInstance
        .post("/booking/closure_request", data)
        .then((res) => {
          if (res.data.statusCode === 200) {
            socketInstance.emit("message", {
              type: "CREATE_BOOKING_CLOSURE_REQUEST",
              subType: "CREATE_BOOKING_CLOSURE_REQUEST",
              key: data?.bookingId,
              extraInfo: fetchUserData()?.companyName,
            });
            toast.success(res.data.body.message);
            dispatch(getAllRequestInfo("1", "1", null, ""));
            // dispatch(getApprovedRequestsInfo("1", "1", "approved", null, ""));
            // dispatch(getApprovedRequestsInfo("1", "1", "approved"));
          }
        })
        .catch((err) => {
          toast.error(err.response.data.body.message);
        });
      setCloseDate("");
      setModal(false);
    }
  };

  const submitRequest = (data, type) => {
    if (data.sow_status === "rejected") {
      const isValid = validationSOW(data);
      setCompanyApproval(isValid, data, type);
    } else {
      setCompanyApproval(true, data, type);
    }
  };
  const sowRequestHandler = (id, data, isUploadSow) => {
    setIsUpload(isUploadSow);
    console.log("sow data  ", data);
    setSOWModal(!modal);
    setSOWData(data);
    setEmployeeID(id);
    setSOWHistory(data?.activity?.filter((obj) => obj.action.includes("SOW")));
  };
  const setCompanyApproval = (isValid = true, data, requesttype) => {
    if (isValid) {
      axiosAccountInstance
        .post("/booking/final/sow_request/approve", data)
        .then((res) => {
          if (res.data.statusCode === 200) {
            if (requesttype === "approved") {
              socketInstance.emit("message", {
                type: "UPDATE_FINAL_SOW_REQUEST",
                subType: "UPDATE_FINAL_SOW_APPROVED_REQUEST_LIST",
                key: sowData?.bookingId,
                extraInfo: fetchUserData()?.companyName,
              });
              toast.success(res.data.body.message);
            } else {
              socketInstance.emit("message", {
                type: "UPDATE_FINAL_SOW_REQUEST",
                subType: "UPDATE_FINAL_SOW_REJECTED_REQUEST_LIST",
                key: sowData?.bookingId,
                extraInfo: fetchUserData()?.companyName,
              });
              toast.success(res.data.body.message);
            }
            dispatch(getAllRequestInfo("1", "1", null, ""));
            // dispatch(getApprovedRequestsInfo("1", "1", "approved"));
            setSOWModal(false);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.body.message);
        });
      setRejectRequestReason("");
    }
  };

  const submitExtention = () => {
    const isValid = validationExtention();
    if (isValid) {
      const data = { ...extentionObj, durationtoDate: closeDate };
      axiosAccountInstance
        .post("/booking/extention_create", data)
        .then((res) => {
          if (res.data.statusCode === 201) {
            toast.success(res.data.body.message);
            socketInstance.emit("message", {
              type: "CREATE_BOOKING_EXTENTION",
              subType: "UPDATE_PENDING_BOOKING_EXTENTION_APPROVAL_LIST",
              key: data?.employeeId,
              extraInfo: fetchUserData()?.companyName,
            });
            dispatch(getApprovedRequestsInfo("1", "1", "approved", null, ""));
            // dispatch(setApprovedRecentBooking("1", "1", "approved"));
            setModalExtention(false);
          }
          
        })
        .catch((err) => {
          toast.error(err.response.data.body.data);
        });
      setCloseDate("");
      setModal(false);
    } else {
      console.log("INVALID EXTENTION");
    }
  };

  const validationExtention = () => {
    const errorsFound = {};

    if (!closeDate || closeDate === "") {
      errorsFound["closeDate"] = "Closing Date is required";
    }

    if (Object.keys(errorsFound).length !== 0) {
      setErrors(errorsFound);
      return false;
    }
    return true;
  };

  const close = () => {
    setModal(false);
    setBookingModal(false);
    setModalExtention(false);
    setSOWModal(false);
    setSOWData({});
  };
  const viewHandler = (dataObj) => {
    setBookingData(dataObj);
    setBookingModal(true);
  };

  const handleOnSOWRejectChange = (e) => {
    setRejectRequestReason(e.target.value);
    setSOWErrors({ ...errors, rejectRequestReason: null });
  };

  const imageSelect = (image) => {
    // if (image) {
    //   setIsRejectButtonDisabled(false);
    // }

    const MAX_IMAGE_SIZE = 5 * 1024 * 1024; // 5MB maximum size limit
    if (image.size > MAX_IMAGE_SIZE) {
      setErrors({
        ...errors,
        sowDocument: `File size exceeds the maximum limit of ${
          MAX_IMAGE_SIZE / (1024 * 1024)
        }MB`,
      });
      setSignedSOWdoc(null);
      setIsApproveButtonDisabled(true);
    } else {
      setErrors({ ...errors, sowDocument: null });

      setSignedSOWdoc(image);
      setIsApproveButtonDisabled(false);
    }
    // setErrors({ ...errors, sowDocument: null });
    // setApproveFormData({
    //   ...approveFormData,
    //   sowDocument: image,
    // });
    // setIsApproveButtonDisabled(false);
  };
  const onApproveSOW = async () => {
    setIsApproveButtonDisabled(true);

    const data = new FormData();
    data.append("_id", employeeID);
    data.append("sow_status", "approved");
    data.append("sowSignedDocument", signedSOWdoc);

    submitRequest(data, "approved");
  };
  const onReject = () => {
    // setIsRejectButtonDisabled(true);
    const finalData = {
      _id: employeeID,
      sow_status: "rejected",
      rejectReason: rejectRequestReason,
    };
    submitRequest(finalData, "rejected");
  };
  // const deleteHandler = (data) => {
  //   setBookingIdObj(data);
  //   setModal(!modal);
  // };
  const bookingExtentionHandler = (data) => {
    setExtentionObj(data);
    setModalExtention(!modalExtention);
  };

  const colums = [
    { columnName: "Employee ID", columnTag: "empId", type: "data" },

    { columnName: "Company", columnTag: "parentCompany", type: "data" },
    { columnName: "Designation", columnTag: "designation", type: "data" },
    { columnName: "Date Range", columnTag: "duration", type: "objectData" },
    {
      columnName: "Hourly Rate",
      columnTag: "hourlyPayment",
      type: "paymentMapping",
    },

    {
      columnName: "SOW",
      columnTag: "sow_final_status",
      type: "data",
      actionSOWApprove: sowRequestHandler,
    },

    { columnName: "Status", columnTag: "status", type: "data" },
    { columnName: "", columnTag: "", type: " " },
  ];

  const allActionButtons = !companyId
    ? [
        {
          columnName: "",
          btnTitle: "View",
          type: "actionButton",
          btnTooltip: "View",
          btnIcon: viewIcn,
          btn: "ViewBooking",
          btnType: "primary",
          actionFunction: viewHandler,
        },

        {
          columnName: "",
          btnTitle: "Extend Booking",
          type: "actionButton",
          btnIcon: extendbookingIcn,
          btnTooltip: "Extend Booking",
          btn: "ExtendBooking",
          btnType: "primary",
          actionFunction: bookingExtentionHandler,
        },

        {
          columnName: "",
          btnTitle: "Close Booking",
          type: "actionButton",
          btnIcon: closebookingIcn,
          btnTooltip: "Close Booking",
          btn: "CloseBooking",
          btnType: "danger",
          actionFunction: deleteHandler,
        },
        {
          columnName: "",
          btnTitle: "Delete",
          btnTooltip: "Delete",
          type: "actionButton",
          btn: "PendingBookingDelete",
          icon: <i className="fa fa-trash" aria-hidden="true"></i>,
          tooltip: "Delete",
          btnType: "danger",
          btnIcon: deleteIcn,
          actionFunction: PendingDeleteHandler,
        },
      ]
    : [
        {
          columnName: "",
          btnTitle: "View",
          type: "actionButton",
          btnTooltip: "View",
          btnIcon: viewIcn,
          btn: "ViewBooking",
          btnType: "primary",
          actionFunction: viewHandler,
        },
      ];
  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  const validationSOW = () => {
    const errorsFound = {};

    if (!rejectRequestReason || rejectRequestReason === "") {
      errorsFound["rejectRequestReason"] = "Reject Request Reason is required";
    }

    if (Object.keys(errorsFound).length !== 0) {
      setSOWErrors(errorsFound);
      return false;
    }
    return true;
  };
  const handleOnChange = (e) => {
    setCloseDate(e.target.value);
    setErrors({ ...errors, closeDate: null });
  };
  return (
    <div>
      <Tables
        coloums={colums}
        data={data}
        actionButtons={allActionButtons}
        isAllRequest={true}
        loader={loader}
        companyId={companyId}
      />

      <Modal toggle={close} size="sm" centered isOpen={deleteModal}>
        <Row>
          <div className="col">
            <ModalHeader className="text-uppercase font-weight-bolder">
              {" "}
              Delete Request
            </ModalHeader>
          </div>
          <Row>
            <Col>
              <ModalHeader className="text-uppercase">
                <Button onClick={close} className="mx-1" close />
              </ModalHeader>
            </Col>
          </Row>
        </Row>
        <ModalBody>
          <label className="font-weight-normal">
            Do you want to delete booking?
          </label>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" onClick={submit} color="danger">
            Delete
          </Button>{" "}
          {/* <Button size="sm" onClick={close}>
                  Cancel
                </Button> */}
        </ModalFooter>
      </Modal>

      <Modal toggle={close} centered isOpen={modalExtention}>
        <Row>
          <div className="col">
            <ModalHeader className="text-uppercase font-weight-bolder">
              {" "}
              Extend Booking
            </ModalHeader>
          </div>
          <Row>
            <Col>
              <ModalHeader className="text-uppercase">
                <Button onClick={close} className="mx-1" close />
              </ModalHeader>
            </Col>
          </Row>
        </Row>
        <ModalBody>
          <label className="font-weight-normal">
            Do you want to extend booking?
          </label>
          <Row>
            <Col>
              <FormGroup>
                <Label
                  style={{ fontSize: "10px" }}
                  for="employeeName"
                  className="text-uppercase"
                >
                  {`Starting Date : ${moment(
                    extentionObj?.duration?.fromDate
                  ).format("MMMM DD, YYYY")}`}
                </Label>
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Label
                  style={{ fontSize: "10px" }}
                  for="employeeName"
                  className="text-uppercase"
                >
                  {`To Date : ${moment(extentionObj?.duration?.toDate).format(
                    "MMMM DD, YYYY"
                  )}`}
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup row>
            <Label for="exampleText" sm={12}>
              Extend Upto
            </Label>
            <Col sm={12}>
              <Input
                id="exampleText"
                name="date"
                type="date"
                value={closeDate}
                onChange={handleOnChange}
                invalid={!!errors && !!errors.closeDate}
              />
              <FormFeedback className={errors?.closeDate && "invalid-field"}>
                {errors?.closeDate}
              </FormFeedback>
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" onClick={submitExtention} color="primary">
            Yes
          </Button>{" "}
          <Button size="sm" onClick={close} color="primary">
            No
          </Button>{" "}
          {/* <Button size="sm" onClick={close}>
                  Cancel
                </Button> */}
        </ModalFooter>
      </Modal>

      <Modal toggle={close} centered isOpen={modal}>
        <Row>
          <div className="col">
            <ModalHeader className="text-uppercase font-weight-bolder">
              {" "}
              Close Booking
            </ModalHeader>
          </div>
          <Row>
            <Col>
              <ModalHeader className="text-uppercase">
                <Button onClick={close} className="mx-1" close />
              </ModalHeader>
            </Col>
          </Row>
        </Row>
        <ModalBody>
          <label className="font-weight-normal">
            Do you want to close booking?
          </label>
          <Row>
            <Col>
              <FormGroup>
                <Label
                  style={{ fontSize: "10px" }}
                  for="employeeName"
                  className="text-uppercase"
                >
                  {`Starting Date : ${moment(
                    bookingIdObj?.duration?.fromDate
                  ).format("MMMM DD, YYYY")}`}
                </Label>
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Label
                  style={{ fontSize: "10px" }}
                  for="employeeName"
                  className="text-uppercase"
                >
                  {`To Date : ${moment(bookingIdObj?.duration?.toDate).format(
                    "MMMM DD, YYYY"
                  )}`}
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup row>
            <Label for="exampleText" sm={12}>
              Closing Date
            </Label>
            <Col sm={12}>
              <Input
                id="exampleText"
                name="date"
                type="date"
                value={closeDate}
                onChange={(e) => handleOnChange(e)}
                min={disablePastDate()}
                invalid={!!errors && !!errors.closeDate}
              />
              <FormFeedback className={errors?.closeDate && "invalid-field"}>
                {errors?.closeDate}
              </FormFeedback>
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" onClick={submitClosure} color="primary">
            Yes
          </Button>{" "}
          <Button size="sm" onClick={close} color="primary">
            No
          </Button>{" "}
          {/* <Button size="sm" onClick={close}>
                  Cancel
                </Button> */}
        </ModalFooter>
      </Modal>

      <SOWModal
        isOpen={sowModal}
        closeModal={close}
        sowData={sowData}
        onApproveSOW={onApproveSOW}
        onReject={onReject}
        sowHistory={sowHistory}
        handleOnSOWRejectChange={handleOnSOWRejectChange}
        rejectRequestReason={rejectRequestReason}
        isApproveButtonDisabled={isApproveButtonDisabled}
        errors={errors}
        imageSelect={imageSelect}
        sowErrors={sowErrors}
        isUpload={isUpload}
        companyId={companyId}
      />
      <BookingModal modal={bookingModal} close={close} data={bookingData} />
    </div>
  );
}

export default AllRequestListCard;
