import { axiosAccountInstance } from "api/Request";

import React, { useState } from "react";
import toast from "../../components/Toastify";
import {
  Container,
  Button,
  Input,
  FormFeedback,
  Card,
  CardBody,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
// import bcrypt from "bcryptjs";
import history from "helpers/History";
import { fetchUserData } from "helpers/storageData";
import DashboardHeader from "components/Headers/DashboardHeader";

function LoginUpdatePassword() {
  const userDataDetails = fetchUserData();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [isOldPasswordVisible, setIsOldPasswordVisible] = useState(false);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);

  const email = localStorage.getItem("email");

  // const hashedNewPassword = bcrypt.hashSync(
  //   newPassword,
  //   "$2a$10$CwTycUXWue0Thq9StjUM0u"
  // );
  // const hashedoldPassword = bcrypt.hashSync(
  //   oldPassword,
  //   "$2a$10$CwTycUXWue0Thq9StjUM0u"
  // );
  // const hashedConfirmPassword = bcrypt.hashSync(
  //   confirmPassword,
  //   "$2a$10$CwTycUXWue0Thq9StjUM0u"
  // );

  const validation = () => {
    const errorsFound = {};
    if (!confirmPassword || confirmPassword === "")
      errorsFound["confirmPassword"] = "Confirm password is required";
    if (confirmPassword !== newPassword) {
      errorsFound["confirmPassword"] = "Confirm password doesn't match";
    }
    if (!newPassword || newPassword === "")
      errorsFound["newPassword"] = "Password is required";
    if (!oldPassword || oldPassword === "")
      errorsFound["oldPassword"] = "Old password is required";

    if (Object.keys(errorsFound).length !== 0) {
      setErrors(errorsFound);
      return false;
    }
    return true;
  };
  const passwordValidation = (data) => {
    if (!data || data === "" || data !== newPassword) {
      setErrors({
        ...errors,
        confirmPassword: "Confirm password doesn't match",
      });
    }
  };

  const submitHandle = (e) => {
    e.preventDefault();

    const isValid = validation();
    if (isValid) {
      setDisabled(true);
      const data = {
        email: email,
        oldPassword: oldPassword,
        password: newPassword,
        confirmPassword: confirmPassword,
      };
      axiosAccountInstance
        .post("/account/password", data)
        .then((res) => {
          if (res.data.statusCode === 200) {
            toast.success(res.data.body.message);
          }
          setDisabled(false);
          // history.push(`/admin/user-profile/${userDataDetails?.userId}`);
          history.push({
            pathname: `/admin/user-profile`,
            state: { ...userDataDetails },
          });
        })
        .catch((err) => {
          setDisabled(false);
          toast.error(err.response.data.body.message);
        });
    }
  };

  const goBack = () => {
    //  history.push(`/admin/user-profile/${userDataDetails?.email}`);
    history.push({
      pathname: `/admin/user-profile`,
      state: { ...userDataDetails },
    });
  };
  return (
    <>
      <DashboardHeader />
      <Container className="mt-2 main-contentarea" fluid>
        <div className="mb-3 d-flex   align-items-center"></div>

        <Card className="card bg-white shadow border-0 my-4 mt-11 update-password">
          <CardBody>
            <Form role="form">
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Old Password"
                    type={isOldPasswordVisible ? "text" : "password"}
                    // type="password"
                    // autoComplete="new-email"
                    value={oldPassword}
                    onChange={(e) => {
                      setOldPassword(e.target.value);
                      setErrors({ ...errors, oldPassword: null });
                    }}
                    invalid={!!errors && !!errors.oldPassword}
                  />
                  {oldPassword.length > 0 && (
                    <InputGroupAddon>
                      <InputGroupText
                        onClick={() =>
                          setIsOldPasswordVisible(!isOldPasswordVisible)
                        }
                      >
                        <i
                          class="fa fa-eye"
                          style={{ paddingTop: "4px", cursor: "pointer" }}
                          aria-hidden="true"
                        ></i>
                      </InputGroupText>
                    </InputGroupAddon>
                  )}
                </InputGroup>
                <FormFeedback
                  className={errors?.oldPassword && "invalid-field"}
                >
                  {errors?.oldPassword}
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="New Password"
                    type={isNewPasswordVisible ? "text" : "password"}
                    // type="password"
                    // autoComplete="new-password"
                    value={newPassword}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                      setErrors({ ...errors, newPassword: null });
                    }}
                    invalid={!!errors && !!errors.newPassword}
                  />
                  {newPassword.length > 0 && (
                    <InputGroupAddon>
                      <InputGroupText
                        onClick={() =>
                          setIsNewPasswordVisible(!isNewPasswordVisible)
                        }
                      >
                        <i
                          class="fa fa-eye"
                          style={{ paddingTop: "4px", cursor: "pointer" }}
                          aria-hidden="true"
                        ></i>
                      </InputGroupText>
                    </InputGroupAddon>
                  )}
                </InputGroup>
                <FormFeedback
                  className={errors?.newPassword && "invalid-field"}
                >
                  {errors?.newPassword}
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Confirm New Password"
                    // type="password"
                    type={isConfirmPasswordVisible ? "text" : "password"}
                    // autoComplete="new-password"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      setErrors({ ...errors, confirmPassword: null });
                      passwordValidation(e.target.value);
                    }}
                    invalid={!!errors && !!errors.confirmPassword}
                  />
                  {confirmPassword.length > 0 && (
                    <InputGroupAddon>
                      <InputGroupText
                        onClick={() =>
                          setIsConfirmPasswordVisible(!isConfirmPasswordVisible)
                        }
                      >
                        <i
                          class="fa fa-eye"
                          style={{ paddingTop: "4px", cursor: "pointer" }}
                          aria-hidden="true"
                        ></i>
                      </InputGroupText>
                    </InputGroupAddon>
                  )}
                </InputGroup>
                <FormFeedback
                  className={errors?.confirmPassword && "invalid-field"}
                >
                  {errors?.confirmPassword}
                </FormFeedback>
              </FormGroup>
              <div className="d-flex justify-content-between align-items-center">
                <Button color="secondary" onClick={goBack} type="submit">
                  Back
                </Button>
                <Button
                  disabled={disabled}
                  onClick={submitHandle}
                  className="my-2"
                  color="primary"
                  type="submit"
                  //   disabled={loader}
                >
                  {disabled ? "Please wait" : "Update"}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default LoginUpdatePassword;
