const ForgotPasswordReducer = (state = { loader: false, result: [], error: null }, action) => {
    switch (action.type) {
        case "FORGOT_PASSWORD_BEGINS":
            return {
                loader: true,
                result: {},
                error: null,
            };
        case "FORGOT_PASSWORD_SUCCESS":
            return {
                loader: false,
                result: action.payload,
                error: null,
            };
        case "FORGOT_PASSWORD_FAILURE":
            return {
                loader: false,
                result: {},
                error: action.payload,
            };
        case "FORGOT_PASSWORD_RESET":
            return {
                loader: false,
                result: {},
                error: null,
            };
        default:
            return state;
    }
}
export default ForgotPasswordReducer;