import { toast } from "react-toastify";
import { css } from "glamor";

const customToast = {
  success: (msg, options = {}) => {
    return toast.success(msg, {
      ...options,
      className: `${css({
        color: "white !important",
        minHeight: "60px !important",
        borderRadius: "8px !important",
        background: "#21b49b !important",
        boxShadow: "2px 2px 20px 2px rgba(0,0,0,0.3) !important",
      })}`,
      progressClassName: css({
        background: "white !important",
      }),
    });
  },
  error: (msg, options = {}) => {
    return toast.error(msg, {
      ...options,
      className: `${css({
        color: "#fff !important",
        minHeight: "60px !important",
        borderRadius: "8px !important",
        background: "#fa5d4d !important",
        boxShadow: "2px 2px 20px 2px rgba(0,0,0,0.3) !important",
      })}`,
      progressClassName: css({
        background: "white !important",
      }),
    });
  },
  warn: (msg, options = {}) => {
    return toast.error(msg, {
      ...options,
      className: `${css({
        color: "#fff !important",
        minHeight: "60px !important",
        borderRadius: "8px !important",
        background: "#fa5 !important",
        boxShadow: "2px 2px 20px 2px rgba(0,0,0,0.3) !important",
      })}`,
      progressClassName: css({
        background: "white !important",
      }),
    });
  },
};

export default customToast;
