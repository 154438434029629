const authReducer = (
  state = { loader: false, result: {}, error: null },
  action
) => {
  switch (action.type) {
    case "STORE_AUTH_BEGINS":
      return {
        loader: true,
        result: {},
        error: null,
      };

    case "STORE_AUTH_SUCCESS":
      return {
        loader: false,
        result: action.payload,
        error: null,
      };
    case "STORE_AUTH_FAILURE":
      return {
        loader: false,
        result: {},
        error: action.payload,
      };
    case "STORE_AUTH_RESET":
      return {
        loader: false,
        result: {},
        error: null,
      };
    default:
      return state;
  }
};

export default authReducer;
