import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import forgotpswdImg from "../../assets/img/new/forgotPswd.jpeg";
import logobox from "../../assets/img/icons/customIcons/logo.svg";
// Third Party Imports
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  FormFeedback,
} from "reactstrap";
import { setForgotPasswordInfo } from "redux/actions/auth/ForgotPasswordAction";
import { MARKETING_WEBSITE } from "helpers/utils";
function ForgotPassword() {
  const loader = useSelector((state) => state.ForgotPasswordReducer.loader);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState(null);

  const validateEmail = (mail) => {
    // eslint-disable-next-line no-useless-escape
    const regex =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    if (!mail || regex.test(mail) === false) {
      setErrors({ ...errors, email: "Please enter a valid email address" });
      return false;
    } else {
      setErrors({ ...errors, email: "" });
      return true;
    }
  };

  const validation = () => {
    const errorsFound = {};
    // if (!employeeId) errorsFound["employeeId"] = "Oops! please add employeeId";

    if (validateEmail(email) === false) {
      errorsFound["email"] = "Please enter a valid mail";
    }
    if (!email || email === "") errorsFound["email"] = "Email is required";

    if (Object.keys(errorsFound).length !== 0) {
      setErrors(errorsFound);
      return false;
    }
    return true;
  };

  const submitHandle = (e) => {
    const isValid = validation();
    if (isValid) {
      const data = email;
      e.preventDefault();
      dispatch(setForgotPasswordInfo(data));
    }
  };
  const navigateToExternalLink = () => {
    window.location.href = MARKETING_WEBSITE;
  };

  return (
    <>
      <Col md="5" className="bg-leftside">
        <div className="login-left">
          
          <img src={forgotpswdImg} alt="" />
        </div>
      </Col>
      <Col md="7" className="bg-rightside">
        <div className="account-switch d-md-block d-none">
          <Link to="/auth/login">
            {/* <small>Back to login</small> */}
            <Button className="switchbtns ml-3" color="outline-primary">
              Signin
            </Button>
          </Link>
        </div>
        <Card className="border-0 formboxes">
          <CardHeader className="bg-transparent border-0">
            <div className="mt-2">
              <p className="title-signup">Forgot Password</p>
              <p className="mb-0 mt-2" style={{ fontSize: "13px" }}>
                No worries!! Enter your email and we will sent you a reset link
              </p>
            </div>
          </CardHeader>

          <CardBody className="pt-3">
            <Form role="form">
              <FormGroup>
                <InputGroup className="input-group-alternative shadow-none border">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setErrors({ ...errors, email: null });
                      validateEmail(e.target.value);
                    }}
                    invalid={!!errors && !!errors.email}
                  />
                </InputGroup>
                <FormFeedback className={errors?.email && "invalid-field"}>
                  {errors?.email}
                </FormFeedback>

                {/* {emailvalidate && (
                  <span style={{ font: "10px", color: "red" }}>
                    Invalid Email
                  </span>
                )} */}
              </FormGroup>

              <div className="">
                <Button
                  onClick={submitHandle}
                  className="my-2 border-radius-100"
                  color="primary"
                  type="button"
                  disabled={loader}
                >
                  {loader ? "Please wait" : "Submit"}
                </Button>
                <p className="d-md-none d-block already-account">
                  <a className="" href="/auth/login">
                    Signin
                  </a>
                </p>
                {/* <Link class="d-md-none d-block" to="/auth/login">
                  Login
                </Link> */}
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}

export default ForgotPassword;
