import { ActionTypes } from "redux/constants/ActionTypes";

const initalState = {
  employees: {},
};
export const EmployeeCreationReducer = (state = initalState, { type, payload }) => {
  switch (type) {
    case ActionTypes.ADD_EMPLOYESS:
      return {
        ...state,employees:payload
      }

    default:
      return state;
  }
};
