import { axiosAccountInstance } from "api/Request";
import { getSearchUrl } from "helpers/utils";
// import { getTokenValidity } from "helpers/getTokenValidity";

export const setCompanyApprovalInfo = (type, searchData) => (dispatch) => {
  let url = `/superadmin/company/approval_requests?type=${type}`;
  url = getSearchUrl(url, searchData);

  dispatch({
    type: "APPROVAL_REQUEST_LIST_BEGINS",
  });
  axiosAccountInstance
    .get(url)
    .then((result) => {
      // dispatching success
      dispatch({
        type: "APPROVAL_REQUEST_LIST_SUCCESS",
        payload: result.data.body.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: "APPROVAL_REQUEST_LIST_FAILURE",
        payload: "FALIURE",
      });
      // const error = err.response
      // if(error.status === 401 && error.data.body.message === 'Unauthorized/Token_expired '){
      //   getTokenValidity(error.config)
      // }else{
      //   console.log("error couldnot be solved")
      // }
    });
};

export const postCompanyApproveReject = (data) => (dispatch) => {
  dispatch({
    type: "REQUEST_BEGINS",
  });
  axiosAccountInstance
    .post(`/superadmin/company/request/approve`)
    .then(
      function (result) {
        // dispatching success
        dispatch({
          type: "REQUEST_SUCCESS",
          payload: result.body.data,
        });
      }
      // (err) => {
      //   dispatch({
      //     type: "REQUEST_FAILURE",
      //     payload: "FALIURE",
      //   });
      //   // const error = err.response
      //   // if(error.status === 401 && error.data.body.message === 'Unauthorized/Token_expired '){
      //   //   getTokenValidity(error.config)
      //   // }else{
      //   //   console.log("error couldnot be solved")
      //   // }
      // }
    )
    .catch((err) => {
      dispatch({
        type: "REQUEST_FAILURE",
        payload: "FALIURE",
      });
    });
};


export const toogleLoader = (loader) => (dispatch) => {
  dispatch({
    type: "TOGGLE_LOADER",
    payload: loader,
  });
}