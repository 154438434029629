import { axiosAccountInstance } from "api/Request";

// import { getTokenValidity } from "helpers/getTokenValidity";

export const getPendingCountInfo = () => (dispatch) => {
  let url = `/booking/pending_requests`;

  dispatch({
    type: "PENDING_COUNT_REQUEST_LIST_BEGINS",
  });

  axiosAccountInstance
    .get(url)
    .then(
      (result) => {
        if (result.data.statusCode === 200) {
          dispatch({
            type: "PENDING_COUNT_REQUEST_LIST_SUCCESS",
            payload: result.data.body.data,
          });
        }
      }
      // (err) => {
      //   if (err.data.statusCode === 400) {
      //     toast.error("No Data Found");
      //     dispatch({
      //       type: "PENDING_COUNT_REQUEST_LIST_FAILURE",
      //       payload: "FALIURE",
      //     });
      //   }

      //   // const error = err.response
      //   // if(error.status === 401 && error.data.body.message === 'Unauthorized/Token_expired '){
      //   //   getTokenValidity(error.config)
      //   // }else{
      //   //   console.log("error couldnot be solved")
      //   // }
      // }
    )
    .catch((err) => {
      dispatch({
        type: "PENDING_COUNT_REQUEST_LIST_FAILURE",
        payload: "FALIURE",
      });
    });
};

export const updatePendingCount = (type,updatedCount) => (dispatch)=> {
  switch (type) {
    case "OUTBOUND_PENDING_LIST_COUNT_UPDATE":
  dispatch({
    type: "OUTBOUND_PENDING_LIST_COUNT_UPDATE",
    payload: updatedCount,
  });
  break;
  
  case "OUTBOUND_PENDING_EXTENSION_LIST_COUNT_UPDATE":
  dispatch({
    type: "OUTBOUND_PENDING_EXTENSION_LIST_COUNT_UPDATE",
    payload: updatedCount,
  });

  case "OUTBOUND_PENDING_PRECLOSURE_LIST_COUNT_UPDATE":
  dispatch({
    type: "OUTBOUND_PENDING_PRECLOSURE_LIST_COUNT_UPDATE",
    payload: updatedCount,
  });
  case "INBOUND_PENDING_LIST_COUNT_UPDATE":
    dispatch({
      type: "INBOUND_PENDING_LIST_COUNT_UPDATE",
      payload: updatedCount,
    });
  break;
  case "INBOUND_PENDING_EXTENSION_LIST_COUNT_UPDATE":
    dispatch({
      type: "INBOUND_PENDING_EXTENSION_LIST_COUNT_UPDATE",
      payload: updatedCount,
    });
  break;
  case "INBOUND_PENDING_PRECLOSURE_LIST_COUNT_UPDATE":
    dispatch({
      type: "INBOUND_PENDING_PRECLOSURE_LIST_COUNT_UPDATE",
      payload: updatedCount,
    });
  break;
  default:
          break;
  }
 
};