import React from "react";
import { Form, Input } from "reactstrap";

function CompanySearchBar({ handleEmployeeSearchOnChange, handleSearch }) {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <Form className="search-custom one" onSubmit={handleSearch}>
            <span className="icons-search">
              <img width={'21'}
                alt="..."
                src={
                  require("../../assets/img/icons/customIcons/searchicondark.svg").default
                }
              />
            </span>
            <Input
              type="text"
              className="form-control"
              name="search"
              onChange={(e) => handleEmployeeSearchOnChange(e)}
              placeholder="Search by company name"
            />
          </Form>
        </div>
      </div>
    </>
  );
}

export default CompanySearchBar;
