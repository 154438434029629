import React, { useEffect, useState } from "react";
import CompanyRejectedListCard from "./CompanyRejectedListCard";
import { Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import PaginationComponent from "components/pagination/Pagination";
import { setCompanyRejectedInfo } from "redux/actions/super-admin/CompanyRejectedAction";

function CompanyRejectedList({
  handleEmployeeSearchOnChange,
  handleSearch,
  searchFormData,
  setSearchFormData,
  data,
}) {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const fetchRejectedCompaniesList = useSelector(
    (state) => state?.CompanyRejectedReducer?.result
  );
  const fetchRejectedCompaniesListLoader = useSelector(
    (state) => state?.CompanyRejectedReducer?.loader
  );

  useEffect(() => {
    setTitle(data);

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const handlePagination = (tab) => {
    // setSearchFormData({
    //   ...searchFormData,
    //   page: tab,
    // });
    const finalData = {
      ...searchFormData,
      page: tab,
    };

    dispatch(setCompanyRejectedInfo("deleted", finalData));
  };
  return (
    <>
      <Row className="mb-2">
        <Col md="12">
          <h3 className="tab-title">{title}</h3>
        </Col>
        {/* <Col lg="7" md="12">
          <CompanySearchBar
            handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
            handleSearch={handleSearch}
          />
        </Col> */}
        {/* <Col lg="5" md="12">
          <SearchDate
            handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
            handleSearch={handleSearch}
          />
        </Col> */}
      </Row>
      <CompanyRejectedListCard loader={fetchRejectedCompaniesListLoader} />

      <div
        style={{
          display: fetchRejectedCompaniesListLoader ? "none" : "contents",
        }}
      >
        <PaginationComponent
          totalCount={fetchRejectedCompaniesList?.itemCount}
          handlePagination={handlePagination}
          activePage={fetchRejectedCompaniesList?.activePage}
        />
      </div>
      {/* {!fetchRejectedCompaniesListLoader && 
        <PaginationComponent
        totalCount={fetchRejectedCompaniesList?.itemCount}
        handlePagination={handlePagination}
      />} */}
    </>
  );
}

export default CompanyRejectedList;
