import { axiosAccountInstance } from "api/Request";
import { getSearchUrl } from "helpers/utils";

export const setCompanyBookingApprovalInfo =
  (createdBy, page, status, searchData) => (dispatch) => {
    let url = `/booking/approval_requests?createdBy=${createdBy}&status=${status}`;
    url = getSearchUrl(url, searchData);
    dispatch({
      type: "BOOKING_APPROVAL_REQUEST_LIST_BEGINS",
    });

    axiosAccountInstance
      .get(url)
      .then((result) => {
        dispatch({
          type: "BOOKING_APPROVAL_REQUEST_LIST_SUCCESS",
          payload: result.data.body.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "BOOKING_APPROVAL_REQUEST_LIST_FAILURE",
          payload: "FALIURE",
        });
      });
  };
