const PendingSOWInboundReducer = (
  state = { loader: false, result: [], error: null },
  action
) => {
  switch (action.type) {
    case "PENDING_SOW_INBOUND_LIST_BEGINS":
      return {
        loader: true,
        result: state.result,
        error: null,
      };
    case "PENDING_SOW_INBOUND_LIST_SUCCESS":
      return {
        loader: false,
        result: action.payload,
        error: null,
      };
    case "PENDING_SOW_INBOUND_LIST_FAILURE":
      return {
        loader: false,
        result: [],
        error: action.payload,
      };

    case "PENDING_SOW_INBOUND_DELETE_SUCCESS": {
      let currentPendingSOWInboundList = { ...state.result };
      let pendingSOWInboundList =
        currentPendingSOWInboundList && currentPendingSOWInboundList.booking;
      pendingSOWInboundList =
        pendingSOWInboundList &&
        pendingSOWInboundList.filter((item) => item._id !== action.payload);
      currentPendingSOWInboundList = {
        ...currentPendingSOWInboundList,
        booking: pendingSOWInboundList,
      };
      return {
        loader: false,
        result: currentPendingSOWInboundList,
        error: null,
      };
    }
    case "PENDING_SOW_INBOUND_LIST_RESET":
      return {
        loader: false,
        result: {},
        error: null,
      };
    default:
      return state;
  }
};

export default PendingSOWInboundReducer;
