import { authRequest } from "api/Request";
import toast from "../../../components/Toastify";
import "react-toastify/dist/ReactToastify.css";
export const setAuthInfo = (data) => (dispatch) => {
  dispatch({
    type: "STORE_AUTH_BEGINS",
  });
  return authRequest({
    method: "POST",
    url: "/login",
    data,
  })
    .then(
      function (result) {
        const accesToken = result.body.data.token;
        const refreshToken = result.body.data.refreshToken;
        const baseData = result.body.data;
        const userData = {
          role: baseData.role ? baseData.role : null,
          userId: baseData.userId ? baseData.userId : null,
          email: baseData.email ? baseData.email : null,
          createdAt: baseData.createdAt ? baseData.createdAt : null,
          updatedAt: baseData.updatedAt ? baseData.updatedAt : null,
          companyId: baseData.companyId ? baseData.companyId : null,
          companyName: baseData.companyName ? baseData.companyName : null,
          name: baseData.name ? baseData.name : null,
        };
        // dispatching success
        if (result.statusCode === 200) {
          toast.success(result.body.message);
          dispatch({
            type: "STORE_IS_LOGIN",
            payload: true,
          });
          dispatch({
            type: "STORE_AUTH_SUCCESS",
            payload: "SUCCESS",
          });
        }

        if (accesToken !== "") {
          localStorage.setItem("Token", accesToken);
          localStorage.setItem("Refresh_Token", refreshToken);
          localStorage.setItem("email", result.body.data.email);
          localStorage.setItem("user", JSON.stringify(userData));
        }
      }

      // (err) => {
      //   if (err.data.statusCode === 400) {
      //     toast.error("Error! Please enter correct credentials for login");
      //     dispatch({
      //       type: "STORE_AUTH_FAILURE",
      //       payload: "FALIURE",
      //     });
      //   }
      //   // toast.error("Error Occureed"); // Error: "Promise rejected"

      //   // if (err.data.errors.hasOwnProperty("email")) {
      //   //   setemailvalidateValidate("Invalid Email");
      //   // }
      //   // if (err.data.errors.hasOwnProperty("password")) {
      //   //   setpasswordvalidateValidate(err.data.errors.password[0]);
      //   // }
      //   // dispatching success
      // }
    )
    .catch((err) => {
      console.log(err);
      if (err.status === 404) {
        toast.error(err.statusText);
      } else {
        toast.error(err.data?.body?.message);
      }
      dispatch({
        type: "STORE_AUTH_FAILURE",
        payload: "FALIURE",
      });
      return err;
    });
};
export const resetAuthData = () => (dispatch) => {
  dispatch({
    type: "STORE_AUTH_RESET",
    payload: "RESET",
  });
};
