import { axiosAccountInstance } from "api/Request";

export const fetchExpiringRequests =
  (options) => (dispatch) => {
    let url = `/booking/request/expiring`;

    if (options?.page) {
      url += `?page=${options.page}`;
    }
    dispatch({
      type: "EXPIRING_REQUEST_LIST_BEGINS",
    });

    axiosAccountInstance
      .get(url)
      .then(
        (result) => {
          dispatch({
            type: "EXPIRING_REQUEST_LIST_SUCCESS",
            payload: result.data.body.data,
          });
        }
      )
      .catch((err) => {
        dispatch({
          type: "EXPIRING_REQUEST_LIST_FAILURE",
          payload: "FALIURE",
        });
      });
  };
