import axios from "axios";
import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import errorImg from "../../assets/img/new/emailError.jpeg";
import logobox from "../../assets/img/icons/customIcons/logo.svg";
import whiteTick from "../../assets/img/icons/customIcons/check-white.svg";
import whiteCross from "../../assets/img/icons/customIcons/cross-white.svg";

import {
  Button,
  Card,
  CardHeader,
  Col,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";

import toast from "../../components/Toastify";
import history from "helpers/History";
import finalImg from "../../assets/img/new/emailVerified.jpg";
import { site_name } from "helpers/utils";
import { MARKETING_WEBSITE } from "helpers/utils";

export default function Verify({ match }) {
  const token = match.params.token;

  const [error, setError] = useState(null);
  const [tokenValidationPerformed, setTokenValidationPerformed] =
    useState(false);
  const [isResending, setIsResending] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    setIsVerifying(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}verify/${token}`)
      .then((res) => {
        setSuccess(true);
        setTokenValidationPerformed(true);
      })
      .catch((err) => {
        // console.log("er",err)
        if (err.response.status === 409) {
          setTokenValidationPerformed(true);
          setError(err.response?.data?.body?.message || "An error has occured");
          return setSuccess(true);
        }
        if (err.response.status === 403) {
          setTokenValidationPerformed(true);
          setError(err.response?.data?.body?.message);
          return setTokenExpired(true);
          
        }

      })
      .finally(() => {
        setIsVerifying(false);
      });
  }, [token]);

  const validateEmail = (mail) => {
    const regex =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    if (!mail || regex.test(mail) === false) {
      setEmailError("Please enter a valid email address");
      return false;
    } else {
      setEmailError("");
      return true;
    }
  };

  const sendVerificationLink = async (email) => {
    setIsResending(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}resend-verification-code`, {
        email,
      })
      .then((res) => {
        toast.success(res.data.body.message);
        history.push("auth/login");
      })
      .catch((err) => {
        toast.error(err.response.data.body.message);
      })
      .finally(() => {
        setIsResending(false);
      });
  };
  const navigateToExternalLink = () => {
    window.location.href = MARKETING_WEBSITE;
  };
  return (
    <>
      <Col md="5" className="bg-leftside">
        <div className="login-left">
          {/* <div className="logo-brand">
            <img alt="" src={logobox} onClick={navigateToExternalLink}/>
          </div> */}
          <img alt="" src={(isVerifying || success) ? finalImg : errorImg} />
        </div>
      </Col>
      <Col md="7" className="bg-rightside">
        {success && (
          <div className="form-wizard">
            <div className="steps completed">
              <img alt="" src={whiteTick} />
            </div>
            <div className="steps completed">
              <img alt="" src={whiteTick} />
            </div>
            <div className="steps completed">
              <img alt="" src={whiteTick} />
            </div>
          </div>
        )}
        {tokenValidationPerformed &&  tokenExpired && (
          <div className="form-wizard">
            <div className="steps completed">
              <img alt="" src={whiteTick} />
            </div>
            <div className="steps completed">
              <img alt="" src={whiteTick} />
            </div>
            <div className="steps failed">
              <img alt="" src={whiteCross} />
            </div>
          </div>
        )}
        <div className="account-switch">
          <Link to="/auth/login">
            {/* <small>Back to login</small> */}
            <Button className="switchbtns ml-3" color="outline-primary">
              Login
            </Button>
          </Link>
        </div>
        <Card className="border-0 formboxes">
          <CardHeader className="bg-transparent border-0">
            <div className="mt-2 text-center">
              {isVerifying && (
                <p className="title-signup text-center">Verifying email...</p>
              )}

              {success && (
                <>
                  <Card className="formboxes border-0">
                    <CardHeader className="bg-transparent border-0 ">
                      <div className="mt-2">
                        <p
                          className="title-signup mb-0"
                          style={{ textAlign: "center" }}
                        >
                          Email Verified
                        </p>
                        <p className="mb-4">
                          Congratulations! Your email address has been
                          successfully verified. Have a great experience with{" "}
                          {site_name}.
                        </p>

                        {/* <small>Back To Login</small> */}
                        <a className="d-block mb-3" href=" /auth/login">
                          Back to login
                        </a>
                      </div>
                    </CardHeader>
                  </Card>
                </>
              )}

              {/* {tokenValidationPerformed && !success && ( */}
              {tokenValidationPerformed &&  tokenExpired && (
                <>
                  <Card className="text-center p-5 shadow-lg">
                    <i className="fa fa-times-circle fa-7x mb-3 text-danger"></i>
                    <p
                      className="title-signup text-center"
                      style={{ "font-size": "0.9rem" }}
                    >
                      {/* {error && ( */}
                        <>
                          <span>Failed To Verify Email.</span>
                          <br />
                          <span>Token Expired.</span>
                        </>
                      {/* )} */}
                    </p>

                    <InputGroup className="input-group-alternative shadow-none border">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="email"
                        placeholder="Email"
                        type="email"
                        autoComplete="new-email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          validateEmail(e.target.value);
                        }}
                        invalid={emailError}
                      />
                    </InputGroup>
                    {emailError && (
                      <span
                        className="text-danger text-left mt-1"
                        style={{ "font-size": "0.8rem" }}
                      >
                        Email is invalid.
                      </span>
                    )}
                    <Button
                      className="switchbtns my-3"
                      color="primary"
                      disabled={emailError || isResending}
                      onClick={() => sendVerificationLink(email)}
                    >
                      Resend email verification link
                    </Button>
                  </Card>
                </>
              )}
            </div>
          </CardHeader>
        </Card>
      </Col>
    </>
  );
}
