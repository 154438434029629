import { axiosAccountInstance } from "api/Request";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  FormFeedback,
} from "reactstrap";
import toast from "../../../../../components/Toastify";
import approveIcn from "../../../../../assets/img/icons/customIcons/approve.svg";
import rejectIcn from "../../../../../assets/img/icons/customIcons/reject.svg";
import viewIcn from "../../../../../assets/img/icons/customIcons/view.svg";

import { setPreClosureRequestInfo } from "redux/actions/admin/PreClosureRequestAction";
import Tables from "views/examples/Tables";
import { fetchUserData } from "helpers/storageData";
import BookingModal from "components/Modals/BookingModal";
import SOWUploadModal from "components/Modals/SOWUploadModal";
import { updatePendingCount } from "redux/actions/admin/PendingCountRequestAction";
function CompanyPreClosureCard({ loader }) {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [, setData] = useState([]);
  const [bookingID, setBookingID] = useState("");
  const [errors, setErrors] = useState(null);
  const [rejectRequestReason, setRejectRequestReason] = useState("");
  const [bookingData, setBookingData] = useState({});
  const [bookingModal, setBookingModal] = useState(false);
  const [btnStatus, setbtnStatus] = useState(false);
  const [sowData, setSOWData] = useState([]);
  const pendingPreClosureRequestList = useSelector(
    (state) => state.PendingPreClosureRequestReducer.result
  );
  const [sowHistory, setSOWHistory] = useState([]);

  const [approveFormData, setApproveFormData] = useState({
    _id: null,
    sow_status: null,
    sowDocument: null,
  });

  const [isApproveButtonDisabled, setIsApproveButtonDisabled] = useState(true); //multiple click disabled
  const [sowModal, setSOWModal] = useState(false);

  const imageSelect = (image) => {
    if (image) {
    }

    const MAX_IMAGE_SIZE = 5 * 1024 * 1024; // 5MB maximum size limit
    if (image.size > MAX_IMAGE_SIZE) {
      setErrors({
        ...errors,
        sowDocument: `File size exceeds the maximum limit of ${
          MAX_IMAGE_SIZE / (1024 * 1024)
        }MB`,
      });
      setApproveFormData({
        ...approveFormData,
        sowDocument: null,
      });
      setIsApproveButtonDisabled(true);
    } else {
      setErrors({ ...errors, sowDocument: null });
      setApproveFormData({
        ...approveFormData,
        sowDocument: image,
      });
      setIsApproveButtonDisabled(false);
    }
    // setErrors({ ...errors, sowDocument: null });
    // setApproveFormData({
    //   ...approveFormData,
    //   sowDocument: image,
    // });
    // setIsApproveButtonDisabled(false);
  };

  const onApproveSOW = async () => {
    setIsApproveButtonDisabled(true);
    const finalData = approveFormData;
    if (!finalData?.sowDocument) {
      setErrors({ ...errors, sowDocument: "SOW document required" });
    } else {
      const data = new FormData();
      data.append("_id", finalData?._id);
      data.append("sowDocument", finalData?.sowDocument);
      data.append("sow_status", finalData?.sow_status);
      submitRequestSOW(data, "approved");
    }
  };

  const sowRequestHandler = (id, data) => {
    setIsApproveButtonDisabled(false);
    console.log("sow data  ", data);
    setSOWModal(true);
    setSOWData(data);
    setSOWHistory(data?.activity?.filter((obj) => obj.action.includes("SOW")));
  };

  useEffect(() => {
    setData(pendingPreClosureRequestList?.closureRequests || []);
  }, [pendingPreClosureRequestList]);

  const handleOnChange = (e) => {
    setRejectRequestReason(e.target.value);

    setErrors({ ...errors, rejectRequestReason: null });
  };

  const rejectHandler = (bookingID) => {
    setBookingID(bookingID);
    setModal(!modal);
  };
  const close = () => {
    setRejectRequestReason("");
    setModal(false);
    setBookingModal(false);
  };

  const onApprove = (bookingId) => {
    const finalData = {
      bookingId,
      status: "approved",
    };
    submitRequest(finalData);
    setModal(false);
  };

  const viewHandler = (dataObj) => {
    setBookingData(dataObj);
    setBookingModal(true);
  };

  const validation = (data) => {
    const errorsFound = {};

    if (!rejectRequestReason || rejectRequestReason === "") {
      errorsFound["rejectRequestReason"] = "Reject Request Reason is required";
    }

    if (Object.keys(errorsFound).length !== 0) {
      setErrors(errorsFound);
      return false;
    }
    return true;
  };
  const totalPendingCount = useSelector(
    (state) => state.PendingCountRequestReducer.result
  );
  const socketInstance = useSelector((state) => state.socketReducer.result);

  const setCompanyApproval = (isValid = true, data) => {
    if (isValid) {
      setbtnStatus(true);
      let subType;
      axiosAccountInstance
        .post("/booking/closure_request/approve", data)
        .then((res) => {
          if (res.data.statusCode === 200) {
            if (data.status === "approved") {
              toast.success(res.data.body.message);
              subType = "UPDATE_CLOSURE_APPROVED_REQUEST_LIST";
            } else {
              toast.success(res.data.body.message);
              subType = "UPDATE_CLOSURE_REJECTED_REQUEST_LIST";
            }
            setbtnStatus(false);
            socketInstance.emit("message", {
              type: "UPDATE_PRE_CLOSURE_REQUEST",
              subType,
              key: data?.bookingId,
              extraInfo: fetchUserData()?.companyName,
            });
            setSOWModal(false);
            dispatch(setPreClosureRequestInfo());
            const currentCount=totalPendingCount?.inbound_bookings?.length > 0 ? (totalPendingCount?.inbound_bookings[3]?.pending_pre_closure_request)-1 : 0
            
            dispatch(updatePendingCount("INBOUND_PENDING_PRECLOSURE_LIST_COUNT_UPDATE",currentCount));
            setModal(false);
          }
        })
        .catch((err) => {
          setbtnStatus(false);
          toast.error(err.response.data.body.message);
        });
      setRejectRequestReason("");
    }
  };

  const setCompanyApprovalSOW = (isValid = true, data) => {
    if (isValid) {
      setbtnStatus(true);
      let subType;
      axiosAccountInstance
        .post("/booking/sow_request/approve", data)
        .then((res) => {
          if (res.data.statusCode === 200) {
            if (data.status === "approved") {
              toast.success(res.data.body.message);
              subType = "UPDATE_CLOSURE_APPROVED_REQUEST_LIST";
            } else {
              toast.success(res.data.body.message);
              subType = "UPDATE_CLOSURE_REJECTED_REQUEST_LIST";
            }
            setbtnStatus(false);
            socketInstance.emit("message", {
              type: "UPDATE_PRE_CLOSURE_REQUEST",
              subType,
              key: data?.bookingId,
              extraInfo: fetchUserData()?.companyName,
            });
            setSOWModal(false);
            dispatch(setPreClosureRequestInfo());
            // dispatch(setSowInfo("0", "1", "approved", null, "pending"));

            setModal(false);
          }
        })
        .catch((err) => {
          setbtnStatus(false);
          toast.error(err.response.data.body.message);
        });
      setRejectRequestReason("");
    }
  };
  // const submitRequest = (data) => {
  //   axiosAccountInstance
  //     .post("/booking/closure_request/approve", data)
  //     .then((res) => {
  //       if (res.data.statusCode === 200) {
  //         if (data.status === "approved") {
  //           toast.success("Request Approved Successfully");
  //         } else {
  //           toast.success("Request Rejected Successfully");
  //         }
  //         dispatch(setPreClosureRequestInfo());
  //       }
  //     })
  //     .catch((err) => {
  //       toast.error("Something went wrong please try again");
  //       console.log(err);
  //     });
  // };
  const submitRequest = (data) => {
    if (data.status === "rejected") {
      const isValid = validation(data);
      setCompanyApproval(isValid, data);
    } else {
      setCompanyApproval(true, data);
    }
  };
  const submitRequestSOW = (data) => {
    if (data.status === "rejected") {
      const isValid = validation(data);
      setCompanyApprovalSOW(isValid, data);
    } else {
      setCompanyApprovalSOW(true, data);
    }
  };

  const onReject = () => {
    const finalData = {
      bookingId: bookingID,
      status: "rejected",
      reason: rejectRequestReason,
    };
    submitRequest(finalData);
  };

  // const {
  //   companyName,
  //   contactNumber,
  //   address,
  //   email,
  //   duration,
  //   actualEndDate,
  //   requestEndDate,
  // } = item;
  const colums = [
    { columnName: "Employee Name", columnTag: "name", type: "data" },

    { columnName: "Company", columnTag: "bookedCompany", type: "data" },
    { columnName: "Designation", columnTag: "designation", type: "data" },
    { columnName: "Date Range", columnTag: "duration", type: "objectData" },
    {
      columnName: "Hourly Rate",
      columnTag: "hourlyPayment",
      type: "paymentMapping",
    },

    {
      columnName: "SOW",
      columnTag: "sow_final_status",
      type: "data",
      actionSOWApprove: sowRequestHandler,
    },

    { columnName: "Status", columnTag: "preclosureStatus", type: "data" },
    { columnName: "", columnTag: "", type: " " },
  ];

  const actionButtons = [
    {
      columnName: "",
      btnTitle: "View",
      type: "actionButton",
      btn: "ViewBooking",
      btnType: "primary",
      btnIcon: viewIcn,
      actionFunction: viewHandler,
      btnTooltip: "View",
    },
    {
      columnName: "",
      type: "actionButton",
      btnTitle: "Approve",
      btnTooltip: "Approve",
      btnType: "primary",
      btn: "PreClousureRequestApproval",
      btnStatus: btnStatus,
      btnIcon: approveIcn,
      // loading:loading,
      actionFunction: onApprove,
    },
    {
      columnName: "",
      btnTitle: "Reject",
      btnTooltip: "Reject",
      type: "actionButton",
      btn: "PreClousureRequestReject",
      btnType: "danger",
      btnIcon: rejectIcn,
      actionFunction: rejectHandler,
    },
  ];
  return (
    <div>
      <Tables
        coloums={colums}
        data={pendingPreClosureRequestList?.closureRequests}
        actionButtons={actionButtons}
        loader={loader}
      />

      <Modal toggle={close} centered isOpen={modal}>
        <Row>
          <div className="col">
            <ModalHeader className="text-uppercase font-weight-bolder">
              Reject Pre-closure Request
            </ModalHeader>
          </div>
          <Row>
            <Col>
              <ModalHeader className="text-uppercase">
                <Button onClick={close} className="mx-1" close />
              </ModalHeader>
            </Col>
          </Row>
        </Row>
        <ModalBody>
          <label className="font-weight-normal">
            Do you want to reject pre-closure requests?
          </label>
          <FormGroup row>
            <Label for="exampleText" sm={12}>
              Rejection Remarks
            </Label>
            <Col sm={12}>
              <Input
                id="exampleText"
                name="text"
                type="textarea"
                onChange={handleOnChange}
                value={rejectRequestReason}
                invalid={!!errors && !!errors.rejectRequestReason}
              />
              <FormFeedback
                className={errors?.rejectRequestReason && "invalid-field"}
              >
                {errors?.rejectRequestReason}
              </FormFeedback>
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" onClick={() => onReject()} color="primary">
            Yes
          </Button>{" "}
          {/* <Button size="sm" onClick={close}>
                      Cancel
                    </Button> */}
        </ModalFooter>
      </Modal>
      <SOWUploadModal
        sowModal={sowModal}
        close={close}
        onApproveSOW={onApproveSOW}
        sowHistory={sowHistory}
        isApproveButtonDisabled={isApproveButtonDisabled}
        errors={errors}
        imageSelect={imageSelect}
        sowData={sowData}
      />
      <BookingModal modal={bookingModal} close={close} data={bookingData} />
    </div>
  );
}

export default CompanyPreClosureCard;
