import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { fetchToken } from "helpers/storageData";
import history from "helpers/History";
import { useSelector } from "react-redux";

const Routes = () => {
  console.log("env......", process.env);

  const [authToken, setToken] = useState(fetchToken());
  // redirecting to login page if no auth token
  const token = fetchToken();
  const auth = useSelector((state) => state.loginLogoutReducer.isLogin);

  useEffect(() => {
    if (
      !token &&
      window.location.pathname.substr(1, 18) !== "auth/resetpassword" &&
      window.location.pathname.substr(1, 11) !== "auth/verify"
    ) {
      history.push("/auth/login");
    } else {
      if (window.location.pathname === "/auth/login")
        history.push("/admin/index");
    }
    // eslint-disabe-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    setToken(fetchToken());
  }, [auth]);

  return (
    <Switch>
      {(auth === true || authToken !== null) && (
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
      )}

      <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
      <Redirect from="*" to="/auth/login" />
    </Switch>
  );
};

export default Routes;
