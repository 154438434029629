import PaginationComponent from "components/pagination/Pagination";
// import AnimatedSearchBar from "components/SearchBars/AnimatedSearchBar";
// import SearchDate from "components/SearchBars/SearchDate";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Row, Col } from "reactstrap";
import { fetchExpiringRequests } from "redux/actions/admin/ExpiringRequestAction";

import CompanyExpiringRequestcard from "./CompanyExpiringRequestcard";
import ExpiringBookingsCard from "./CompanyExpiringBookingsCard";
import { fetchExpiringBookings } from "redux/actions/admin/ExpiringBookingsAction";

function CompanyExpiringRequestList({
  // handleEmployeeSearchOnChange,
  // handleSearch,
  // searchFormData,
  type,
  data,
  // handleClearDate
}) {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");

  const pendingCompanyExpiringRequestList = useSelector(
    (state) => state.ExpiringRequestReducer.result
  );
  const pendingCompanyExpiringRequestListLoader = useSelector(
    (state) => state.ExpiringRequestReducer.loader
  );

  useEffect(() => {
    setTitle(data);

    return null;
  }, [data]);

  const handlePagination = (tab) => {
    if (type === "requests") {
      dispatch(fetchExpiringRequests({ page: tab }));
    } else if (type === "myBookings") {
      dispatch(fetchExpiringBookings({ page: tab }));
    }
  };

  return (
    <>
      <Row className="mb-2 second-search">
        <Col md="12">
          <h3 className="tab-title">{title}</h3>
        </Col>
        {/* <Col lg="7" md="12">
          <AnimatedSearchBar
            handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
            handleSearch={handleSearch}
          />
        </Col>
        <Col lg="5" md="12">
          <SearchDate
            handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
            handleSearch={handleSearch}
            clearDate={handleClearDate}
          />
        </Col> */}
      </Row>
      {type === "requests" ? (
        <CompanyExpiringRequestcard
          loader={pendingCompanyExpiringRequestListLoader}
        />
      ) : (
        <ExpiringBookingsCard
          loader={pendingCompanyExpiringRequestListLoader}
        />
      )}

      <div
        style={{
          display: pendingCompanyExpiringRequestListLoader
            ? "none"
            : "contents",
        }}
      >
        <PaginationComponent
          totalCount={pendingCompanyExpiringRequestList?.itemCount}
          handlePagination={handlePagination}
          activePage={pendingCompanyExpiringRequestList?.activePage}
        />
      </div>
    </>
  );
}

export default CompanyExpiringRequestList;
