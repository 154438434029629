import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import CompanyPendingRequestList from "./company-pending-request/CompanyPendingRequestList";
import { useDispatch, useSelector } from "react-redux";
import { setPreClosureRequestInfo } from "redux/actions/admin/PreClosureRequestAction";
import CompanyPreClosureRequestsList from "./company-Pre-closure-requests/CompanyPreClosureRequestsList";
import PendingSOWApprovalList from "./company-pending-SOW-approval/PendingSOWApprovalList";
import { setSowInfo } from "redux/actions/admin/SowActions";
import DashboardHeader from "components/Headers/DashboardHeader";
import pendingIcon from "../../../../assets/img/icons/customIcons/pendingIcon.svg";
import approvedIcon from "../../../../assets/img/icons/customIcons/maps-and-flags.svg";
import rejectedIcon from "../../../../assets/img/icons/customIcons/cross-button.svg";
import { setCompanyBookingExtentionInfo } from "redux/actions/admin/BookingExtentionRequestAction";
import CompanyBookingExtentionPendingList from "./company-bookingextentionpending-request/CompanyBookingExtentionPendingList";
import CompanyAllRequestList from "./company-all-request/CompanyAllRequestList";
import { getAllBookingRequestInfo } from "redux/actions/admin/AllBookingRequestAction";
import { getCompanyBookingPendingInfo } from "redux/actions/admin/BookingPendingRequestAction";
import moment from "moment";

function MainContainer(state) {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("0");
  const [searchFormData, setSearchFormData] = useState({
    experience: null,
    skillSet: null,
    fromDate: null,
    toDate: null,
    search: state.location.state?.empId ? state.location.state?.empId : null,
    bookedCompany: null,
  });
  const [empId, setEmpId] = useState(
    state.location.state?.empId ? state.location.state?.empId : null
  );

  const handleClearSearch = () => {
    setSearchFormData({
      experience: null,
      skillSet: null,
      fromDate: null,
      toDate: null,
      search: null,
      bookedCompany: null,
    });
  };
  const notification = useSelector((state) => state.NotificationReducer.result);
  const handleEmployeeSearchOnChange = (e, v) => {
    setSearchFormData({ ...searchFormData, [e]: v });
  };

  const getCompanyBookingApprovalInfo = (createdBy, page, type, searchData) => {
    dispatch(getCompanyBookingPendingInfo(createdBy, page, type, searchData));
  };
  const getSowInfo = (createdBy, page, type, searchData, sow_status) => {
    dispatch(setSowInfo(createdBy, page, type, searchData, sow_status));
  };
  const getPreClosureRequestInfo = (searchData) => {
    dispatch(setPreClosureRequestInfo(searchData));
  };
  const getAllBookingInfo = (createdBy, page, searchData, sow_status) => {
    dispatch(getAllBookingRequestInfo(createdBy, page, searchData, sow_status));
  };
  const getBookingExtentionList = (
    createdBy,
    page,
    extentionStatus,
    searchData
  ) => {
    dispatch(
      setCompanyBookingExtentionInfo(
        createdBy,
        page,
        extentionStatus,
        searchData
      )
    );
  };

  useEffect(() => {
    if (state.location.state?.empId) {
      getAllBookingInfo("0", "1", searchFormData, "");
    } else if (notification?.empId) {
      setActiveTab("0");
      getAllBookingInfo(
        "0",
        "1",
        { ...searchFormData, search: notification?.empId },
        ""
      );
    } else {
      getAllBookingInfo("0", "1", null, "");
    }

    // getCompanyBookingPendingInfo("0", "1", "pending");
    // getSowInfo("0", "1", "approved", "pending");
    // getPreClosureRequestInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  const handleClearDate = () => {
    const data = { ...searchFormData, fromDate: null, toDate: null };
    setSearchFormData(data);
    switch (activeTab) {
      case "0":
        getAllBookingInfo("0", "1", data, "");

        break;
      case "1":
        getCompanyBookingApprovalInfo("0", "1", "pending");
        break;
      case "2":
        getSowInfo("0", "1", "sowPending", data, "pending");
        break;
      case "3":
        getPreClosureRequestInfo(data);
        break;
      case "4":
        getBookingExtentionList("0", "1", "pending", data);
        break;
      default:
        break;
    }
  };
  const handleSearch = (e) => {
    e.preventDefault();
    switch (activeTab) {
      case "0":
        getAllBookingInfo("0", "1", searchFormData, "");

        break;
      case "1":
        getCompanyBookingApprovalInfo("0", "1", searchFormData, "");
        break;
      case "2":
        getSowInfo("0", "1", "sowPending", searchFormData, "pending");
        break;
      case "3":
        getPreClosureRequestInfo(searchFormData);
        break;
      case "4":
        getBookingExtentionList("0", "1", "pending", searchFormData);
        break;
      default:
        break;
    }
  };

  const handleSearchOnSelect = (value, name) => {
    let val =
      name === "bookedCompany"
        ? { ...searchFormData, bookedCompany: value }
        : name === "experience"
        ? { ...searchFormData, experience: value }
        : name === "date"
        ? {
            ...searchFormData,
            fromDate: moment(value.startDate).format("YYYY-MM-DD"),
            toDate: moment(value.endDate).format("YYYY-MM-DD"),
          }
        : { ...searchFormData, search: value };

    setSearchFormData(val);
    switch (activeTab) {
      case "0":
        getAllBookingInfo("0", "1", val, "");

        break;
      case "1":
        getCompanyBookingApprovalInfo("0", "1", val, "");

        break;
      case "2":
        getSowInfo("0", "1", "sowPending", val, "pending");
        break;
      case "3":
        getPreClosureRequestInfo(val);
        break;
      case "4":
        getBookingExtentionList("0", "1", "pending", val);
        break;
      default:
        break;
    }
  };

  const toggle = (tab) => {
    setEmpId(null);
    dispatch({
      type: "NOTIFICATION_RESET",
    });
    if (activeTab !== tab) {
      switch (tab) {
        case "0":
          getAllBookingInfo("0", "1", null, "");
          handleClearSearch();
          break;
        case "1":
          getCompanyBookingApprovalInfo("0", "1", null, null);

          handleClearSearch();
          break;
        case "2":
          getSowInfo("0", "1", "sowPending", null, "pending");
          handleClearSearch();
          break;
        case "3":
          getPreClosureRequestInfo();
          handleClearSearch();
          break;
        case "4":
          getBookingExtentionList("0", "1", "pending", null);
          handleClearSearch();
          break;
        default:
          break;
      }

      setActiveTab(tab);
    }
  };

  const data = [
    {
      title: "All Bookings",     //Inbound 
      tabId: "0",

      icon: pendingIcon,
      color: "blue",
    },
    {
      title: "Pending Bookings",
      tabId: "1",

      icon: pendingIcon,
      color: "blue",
    },
    {
      title: "Pending SOWs",
      tabId: "2",

      icon: approvedIcon,
      color: "green",
    },
    {
      title: "Pre-Closure Requests",
      tabId: "3",

      icon: rejectedIcon,
      color: "pink",
    },
    {
      title: "Extension Requests",
      tabId: "4",

      icon: rejectedIcon,
      color: "pink",
    },
  ];

  const clearEmpId = () => {
    setEmpId("");
  };

  return (
    <div>
      <DashboardHeader
        tabCards={[]}
        selectedCard={(tabId) => toggle(tabId)}
        activeTab={activeTab}
      />
      {/* Booking Request */}
      <Container className="mt-4 main-contentarea container-fluid" fluid>
        <div className="custom-tabs">
          {/* all requests */}
          {activeTab === "0" && (
            <CompanyAllRequestList
              list={data}
              toggle={toggle}
              handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
              handleSearch={handleSearch}
              searchFormData={searchFormData}
              setSearchFormData={setSearchFormData}
              data={"All Bookings"}
              empId={empId}
              clearEmpId={clearEmpId}
              handleClearDate={handleClearDate}
              handleSearchOnSelect={handleSearchOnSelect}
            />
          )}
          {activeTab === "1" && (
            <CompanyPendingRequestList
              list={data}
              toggle={toggle}
              handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
              handleSearch={handleSearch}
              searchFormData={searchFormData}
              setSearchFormData={setSearchFormData}
              data={"Pending Bookings"}
              handleClearDate={handleClearDate}
              handleSearchOnSelect={handleSearchOnSelect}
            />
          )}

          {activeTab === "2" && (
            <PendingSOWApprovalList
              list={data}
              toggle={toggle}
              handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
              handleSearch={handleSearch}
              searchFormData={searchFormData}
              setSearchFormData={setSearchFormData}
              data={"Pending SOWs"}
              handleClearDate={handleClearDate}
              handleSearchOnSelect={handleSearchOnSelect}
            />
          )}

          {activeTab === "3" && (
            <CompanyPreClosureRequestsList
              list={data}
              toggle={toggle}
              handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
              handleSearch={handleSearch}
              searchFormData={searchFormData}
              setSearchFormData={setSearchFormData}
              data={"Pre-Closure Requests"}
              handleClearDate={handleClearDate}
              handleSearchOnSelect={handleSearchOnSelect}
            />
          )}
          {activeTab === "4" && (
            <CompanyBookingExtentionPendingList
              list={data}
              toggle={toggle}
              handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
              handleSearch={handleSearch}
              searchFormData={searchFormData}
              setSearchFormData={setSearchFormData}
              data={"Extension Requests"}
              handleClearDate={handleClearDate}
              handleSearchOnSelect={handleSearchOnSelect}
            />
          )}
        </div>
      </Container>
    </div>
  );
}

export default MainContainer;
