import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import viewIcn from "../../../../../assets/img/icons/customIcons/view.svg";

import Tables from "views/examples/Tables";
import BookingModal from "components/Modals/BookingModal";
function CompletedListCard({ loader }) {
  const [bookingData, setBookingData] = useState({});
  const [bookingModal, setBookingModal] = useState(false);
  const [, setData] = useState([]);
  const fetchResultCompletedListRequests = useSelector(
    (state) => state.CompletedRecentBookingReducer.result
  );

  useEffect(() => {
    setData(fetchResultCompletedListRequests?.booking || []);
  }, [fetchResultCompletedListRequests]);

  const close = () => {
    setBookingModal(false);
  };

  const viewHandler = (dataObj) => {
    setBookingData(dataObj);
    setBookingModal(true);
  };
  // const completedDate = moment(item?.duration?.toDate).format("DD-MM-YYYY");
  const colums = [
    { columnName: "Company", columnTag: "bookedCompany", type: "data" },
    { columnName: "Designation", columnTag: "designation", type: "data" },
    { columnName: "Date Range", columnTag: "duration", type: "objectData" },
    {
      columnName: "Hourly Rate",
      columnTag: "hourlyPayment",
      type: "paymentMapping",
    },
    // { columnName: "Currency", columnTag: "currencyCode", type: "data" },
    {
      columnName: "Working Days",
      columnTag: "workingDays",
      type: "data",
    },
    { columnName: "Status", columnTag: "status", type: "data" },

    { columnName: "", columnTag: "", type: "" },
  ];

  const actionButtons = [
    {
      columnName: "",
      btnTitle: "View",
      type: "actionButton",
      btnTooltip: "View",
      btnIcon: viewIcn,
      btn: "ViewBooking",
      btnType: "primary",
      actionFunction: viewHandler,
    },
  ];
  return (
    <div>
      <Tables
        coloums={colums}
        data={fetchResultCompletedListRequests?.booking}
        actionButtons={actionButtons}
        loader={loader}
      />

      {/* <Row className="justify-content-center mt-1">
          <Col md="12">
            <Card className="card-stats  my-1">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase font-weight-bolder text-darker mb-1"
                    >
                      {bookedCompany}
                    </CardTitle>
                    <p className="h5 font-weight-normal mb-0">
                        {item?.designation}
                      </p>
                      <p className="h5 font-weight-normal mb-0">
                        {item?.experience?.numberOfYears} years  {item?.experience?.numberOfMonths} months
                      </p>
                  </div>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase font-weight-bolder text-darker mb-1"
                    >
                      Skills
                    </CardTitle>
                    {skillsett?.map((skill, index) => (
                      <p className="h5 font-weight-normal mb-0">
                        {skill.domain} - {skill.numberOfYears} years{" "}
                        {skill.numberOfMonths} months
                      </p>
                    ))}
                  </div>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase font-weight-bolder text-darker mb-1"
                    >
                      Status
                    </CardTitle>

                    <p className="h5 font-weight-normal mb-0">{status}</p>
                  </div>
                  <div className="col-lg-3 col-sm-4">
                    <div className="card-box">
                      <CardTitle
                        tag="h5"
                        className="text-uppercase font-weight-bolder text-darker mb-1"
                      >
                        Hourly Payment
                      </CardTitle>
                      <p className="h5 font-weight-normal mb-0">
                        {item?.hourlyPayment}({item?.currencyCode})
                      </p>
                    </div>
                  </div>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase font-weight-bolder text-darker mb-1"
                    >
                      Completed Date
                    </CardTitle>
                    <p className="h5 font-weight-normal mb-0">{completedDate}</p>
                  </div>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase font-weight-bolder text-darker mb-1"
                    >
                      Total Working Days
                    </CardTitle>
                    <p className="h5 font-weight-normal mb-0">{workingDays} working Days</p>
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row> */}
      <BookingModal modal={bookingModal} close={close} data={bookingData} />
    </div>
  );
}

export default CompletedListCard;
