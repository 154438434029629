import React from "react";
import { Form, FormGroup, Input, InputGroup, Button } from "reactstrap";
import rejectIcn from "../../assets/img/icons/customIcons/reject.svg";

function SearchDate({
  clearDate,
  handleEmployeeSearchOnChange,
  handleSearch,
  toDate,
  fromDate,
}) {
  const clearInputs = () => {
    document.getElementById("fromDate").value = null;
    document.getElementById("toDate").value = null;
    clearDate();
  };

  return (
    <div className={clearDate && `d-flex flex-row`}>
      <div>
        <Form
          className="navbar-search text-right"
          style={{ background: "transparent" }}
          onSubmit={handleSearch}
        >
          <div className="date-boxes">
            <FormGroup className="mb-0 date-group py-1 first">
              <InputGroup className="input-group-alternative">
                <Input
                  className="w-auto"
                  required
                  type="date"
                  name="fromDate"
                  id="fromDate"
                  value={fromDate ? fromDate : fromDate}
                  onChange={(e) => handleEmployeeSearchOnChange(e)}
                  placeholder="From date"
                />
              </InputGroup>
            </FormGroup>
            <FormGroup className="mb-0 date-group py-1">
              <InputGroup className="input-group-alternative">
                <Input
                  className="w-auto"
                  required
                  type="date"
                  name="toDate"
                  value={toDate ? toDate : toDate}
                  id="toDate"
                  onChange={(e) => handleEmployeeSearchOnChange(e)}
                  placeholder="To date"
                />
              </InputGroup>
            </FormGroup>
            <Button
              type="submit"
              className="w-100 searchicon-btn"
              color="primary"
            >
              <img
                width={"21"}
                alt="..."
                src={
                  require("../../assets/img/icons/customIcons/searchicondark.svg")
                    .default
                }
              />
            </Button>
            {clearDate && (
              <div>
                <Button
                  onClick={clearInputs}
                  className="w-100 searchicon-btn"
                  color="primary"
                >
                  <img width={"21"} alt="..." src={rejectIcn} />
                </Button>
              </div>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
}

export default SearchDate;
