import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
// import { PaginationItem, PaginationLink } from "reactstrap";

const PaginationComponent = ({ totalCount, handlePagination, activePage }) => {
  const pageCount = Math.ceil(totalCount / 10);

  const [atvPage, setAtvPage] = useState(null);

  useEffect(() => {
    if (activePage) setAtvPage(activePage);
    return () => setAtvPage(null);
  }, [activePage]);

  const handlePageClick = (event) => {
    handlePagination(event.selected + 1);
  };

  return (
    <div id="container">
      <div className="pagination">
        {pageCount && pageCount > 1 ? (
          <ReactPaginate
            nextLabel=" >>"
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            marginPagesDisplayed={3}
            pageCount={pageCount}
            previousLabel="<<"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
            forcePage={atvPage && atvPage - 1}
          />
        ) : (
          ""
        )}
        {pageCount === 1 ? "" : ""}
      </div>
    </div>
  );
};

export default PaginationComponent;
