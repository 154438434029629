const NotificationReducer = (state = { result: [], error: null }, action) => {
  switch (action.type) {
    case "NOTIFICATION_UPDATE":
      return {
        loader: true,
        result: action.payload,
        error: null,
      };

    case "NOTIFICATION_RESET":
      return {
        loader: false,
        result: [],
        error: null,
      };
    default:
      return state;
  }
};

export default NotificationReducer;
