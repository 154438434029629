import { axiosAccountInstance } from "api/Request";
import { getSearchUrl } from "helpers/utils";

// import { getTokenValidity } from "helpers/getTokenValidity";

export const setRejectedRecentBooking =
  (createdBy, page, status, searchData, companyId) => (dispatch) => {
    let url = `superadmin/company/bookings/recent?status=${status}`;
    if(companyId){
      url = url + `&companyId=${companyId}`
    }
    url = getSearchUrl(url, searchData);
    dispatch({
      type: "REJECTED_RECENT_BOOKINGS_REQUEST_LIST_BEGINS",
    });

    axiosAccountInstance
      .get(url)
      .then(
        (result) => {
          if (result.data.statusCode === 200) {
            dispatch({
              type: "REJECTED_RECENT_BOOKINGS_REQUEST_LIST_SUCCESS",
              payload: result.data.body.data,
            });
          }
        }
  
      )
      .catch((err) => {
        dispatch({
          type: "REJECTED_RECENT_BOOKINGS_REQUEST_LIST_FAILURE",
          payload: "FALIURE",
        });
      });
  };
