/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Container } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import { getPendingPreClosureRequestsInfo } from "redux/actions/admin/PendingPreClosureAction";
import AllPreClosureList from "./admin-all-preclosure-request/AllPreClosureList";
import PendingPreClosureList from "./admin-pending-preclosure/PendingPreClosureList";
import PreClosureApprovedList from "./admin-approved-preclosure-request/PreClosureApprovedList";
import PreClosureRejectedList from "./admin-preclosure-rejected-request/PreClosureRejectedList";
import moment from "moment";

function PreClosureDetails({ empId }) {
  const notification = useSelector((state) => state.NotificationReducer.result);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("0");
  const [empIds, setEmpIds] = useState(empId ? empId : null);
  const [searchFormData, setSearchFormData] = useState({
    experience: null,
    skillSet: null,
    fromDate: null,
    toDate: null,
    search: empIds ? empIds : null,
    parentCompany: null,
  });
  const handleClearSearch = () => {
    setSearchFormData({
      experience: null,
      skillSet: null,
      fromDate: null,
      toDate: null,
      search: null,
      parentCompany: null,
    });
  };
  const list = [
    {
      title: "All",
      tabId: "0",
      color: "blue",
    },
    {
      title: "Pending",
      tabId: "1",
      color: "blue",
    },
    {
      title: "Approved",
      tabId: "2",
      color: "green",
    },
    {
      title: "Rejected",
      tabId: "3",
      color: "pink",
    },
  ];

  // HANDLING EMPLOYEE SEARCH
  const handleEmployeeSearchOnChange = (e, v) => {
    setSearchFormData({ ...searchFormData, [e]: v });
  };

  const handleClearDate = () => {
    const data = { ...searchFormData, fromDate: null, toDate: null };
    setSearchFormData(data);
    switch (activeTab) {
      case "0":
        getPreClosureList("1", "1", data, "", "");
        break;
      case "1":
        getPreClosureList("1", "1", data, "", "pending");
        break;
      case "2":
        getPreClosureList("1", "1", data, "", "approved");
        break;
      case "3":
        getPreClosureList("1", "1", data, "", "rejected");
        break;

      default:
        break;
    }
  };

  // HANDLE SEARCH FUNCTION
  const handleSearch = (e) => {
    e.preventDefault();
    switch (activeTab) {
      case "0":
        getPreClosureList("1", "1", searchFormData, "", "");
        break;
      case "1":
        getPreClosureList("1", "1", searchFormData, "", "pending");
        break;
      case "2":
        getPreClosureList("1", "1", searchFormData, "", "approved");
        break;
      case "3":
        getPreClosureList("1", "1", searchFormData, "", "rejected");
        break;

      default:
        break;
    }
  };
  const handleSearchOnSelect = (value, name) => {
    let val =
      name === "parentCompany"
        ? { ...searchFormData, parentCompany: value }
        : name === "experience"
        ? { ...searchFormData, experience: name }
        : name === "date"
        ? {
            ...searchFormData,
            fromDate: moment(value.startDate).format("YYYY-MM-DD"),
            toDate: moment(value.endDate).format("YYYY-MM-DD"),
          }
        : { ...searchFormData, search: value };
    setSearchFormData(val);
    switch (activeTab) {
      case "0":
        getPreClosureList("1", "1", val, "", "");
        break;
      case "1":
        getPreClosureList("1", "1", val, "", "pending");
        break;
      case "2":
        getPreClosureList("1", "1", val, "", "approved");
        break;
      case "3":
        getPreClosureList("1", "1", val, "", "rejected");
        break;

      default:
        break;
    }
  };
  // PENDING PRECLOSURE DATA
  const getPreClosureList = (
    createdBy,
    page,

    searchData,
    companyId,
    status
  ) => {
    dispatch(
      getPendingPreClosureRequestsInfo(
        createdBy,
        page,

        searchData,
        companyId,
        status
      )
    );
  };

  //   const bookingExtentionResult = useSelector(
  //     (state) => state.BookingExtentionApprovalRequestReducer.result
  //   );
  //   const bookingExtentionLoader = useSelector(
  //     (state) => state.BookingExtentionApprovalRequestReducer.loader
  //   );

  const toggle = (tab) => {
    setEmpIds(null);
    dispatch({
      type: "NOTIFICATION_RESET",
    });
    if (activeTab !== tab.target.value) {
      switch (tab.target.value) {
        case "0":
          getPreClosureList("1", "1", null, "", "");
          handleClearSearch();
          break;
        case "1":
          getPreClosureList("1", "1", null, "", "pending");
          handleClearSearch();
          break;
        case "2":
          getPreClosureList("1", "1", null, "", "approved");
          handleClearSearch();
          break;
        case "3":
          getPreClosureList("1", "1", null, "", "rejected");
          handleClearSearch();
          break;

        default:
          break;
      }

      setActiveTab(tab.target.value);
    }
  };

  useEffect(() => {
    if (empIds) {
      getPreClosureList("1", "1", searchFormData, "", "");
    }
    if (notification?.empId) {
      setActiveTab("0");
      getPreClosureList(
        "1",
        "1",
        { ...searchFormData, search: notification?.empId },
        "",
        ""
      );
    }
  }, [notification]);

  const clearEmpId = () => {
    setEmpIds("");
  };

  return (
    <>
      <Row>
        {/* <Col md="12" style={{ bottom: "162px" }}>
          <select className="custom-select select-positioned" onChange={(event) => toggle(event)}>
            {list?.map((item, key) => (
              <option key={key} value={item?.tabId}>
                {item?.title}
              </option>
            ))}
          </select>
        </Col> */}
      </Row>
      <Container className="mt-4 main-contentarea container-fluid" fluid>
        <div className="custom-tabs">
          {/* All Booking preclosure */}

          {activeTab === "0" && (
            <>
              <AllPreClosureList
                list={list}
                toggle={toggle}
                handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
                handleSearch={handleSearch}
                searchFormData={searchFormData}
                setSearchFormData={setSearchFormData}
                data={"All Pre-Closure Requests"}
                empIds={empIds}
                clearEmpId={clearEmpId}
                handleClearDate={handleClearDate}
                handleSearchOnSelect={handleSearchOnSelect}
              />
            </>
          )}
          {/* Pending preclosure */}
          {activeTab === "1" && (
            <>
              <PendingPreClosureList
                list={list}
                toggle={toggle}
                handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
                handleSearch={handleSearch}
                searchFormData={searchFormData}
                setSearchFormData={setSearchFormData}
                data={"Pending Pre-Closure Requests"}
                handleClearDate={handleClearDate}
                handleSearchOnSelect={handleSearchOnSelect}
              />
            </>
          )}

          {/* APPROVED */}
          {activeTab === "2" && (
            <>
              <PreClosureApprovedList
                list={list}
                toggle={toggle}
                handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
                handleSearch={handleSearch}
                searchFormData={searchFormData}
                setSearchFormData={setSearchFormData}
                data={"Approved Pre-Closure Requests"}
                handleClearDate={handleClearDate}
                handleSearchOnSelect={handleSearchOnSelect}
              />
            </>
          )}
          {/* REJECTED */}
          {activeTab === "3" && (
            <>
              <PreClosureRejectedList
                list={list}
                toggle={toggle}
                handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
                handleSearch={handleSearch}
                searchFormData={searchFormData}
                setSearchFormData={setSearchFormData}
                data={["Rejected Pre-Closure Requests"]}
                handleClearDate={handleClearDate}
                handleSearchOnSelect={handleSearchOnSelect}
              />
            </>
          )}
        </div>
      </Container>
    </>
  );
}

export default PreClosureDetails;
