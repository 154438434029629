import { axiosAccountInstance } from "api/Request";
import { getSearchUrl } from "helpers/utils";
// import { getTokenValidity } from "helpers/getTokenValidity";

export const setCompanyRejectedInfo = (type, searchData) => (dispatch) => {
  let url = `/superadmin/company/approval_requests?type=${type}`;
  url = getSearchUrl(url, searchData);

  dispatch({
    type: "REJECTED_REQUEST_LIST_BEGINS",
  });
  axiosAccountInstance
    .get(url)
    .then((result) => {
   
      // dispatching success
      dispatch({
        type: "REJECTED_REQUEST_LIST_SUCCESS",
        payload: result.data.body.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: "REJECTED_REQUEST_LIST_FAILURE",
        payload: "FALIURE",
      });
      // const error = err.response
      // if(error.status === 401 && error.data.body.message === 'Unauthorized/Token_expired '){
      //   getTokenValidity(error.config)
      // }else{
      //   console.log("error couldnot be solved")
      // }
    });
};
