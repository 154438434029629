import { axiosAccountInstance } from "api/Request";
import { getSearchUrl } from "helpers/utils";
import toast from "../../../components/Toastify";
// import { getTokenValidity } from "helpers/getTokenValidity";
// OUTBOUND
export const getPendingPreClosureRequestsInfo =
  (createdBy, page, searchData, companyId, status) => (dispatch) => {
    let url = `/booking/preclosure_list?createdBy=1&preclosureStatus=${status}`;
    if (companyId) {
      url = url + `&companyId=${companyId}`;
    }
    url = getSearchUrl(url, searchData);
    dispatch({
      type: "PRE_CLOSURE_REQUEST_LIST_BEGINS",
    });
    // http://localhost:3000/booking/preclosure_list?createdBy=0&preclosureStatus=pending
    axiosAccountInstance
      .get(url)
      .then(
        (result) => {
          dispatch({
            type: "PRE_CLOSURE_REQUEST_LIST_SUCCESS",
            payload: result.data.body.data,
          });
        }
        // (err) => {
        //   dispatch({
        //     type: "PRECLOSURE_LIST_FAILURE",
        //     payload: "FALIURE",
        //   });
        //   // const error = err.response
        //   // if(error.status === 401 && error.data.body.message === 'Unauthorized/Token_expired '){
        //   //   getTokenValidity(error.config)
        //   // }else{
        //   //   console.log("error couldnot be solved")
        //   // }
        // }
      )
      .catch((err) => {
        dispatch({
          type: "PRE_CLOSURE_REQUEST_LIST_FAILURE",
          payload: "FALIURE",
        });
      });
  };

export const setAllRequestDeleteInfo = (id, callBackHandler) => (dispatch) => {
  dispatch({
    type: "PRECLOSURE_LIST_BEGINS",
  });
  axiosAccountInstance
    // .delete(`/deletebooking?id=${id}`)
    .delete(`/booking/preclosure/delete?id=${id}`)
   
    .then((result) => {
      // dispatching success
      if (result.data.statusCode === 200) {
        toast.success(result.data.body.message);
        dispatch({
          type: "PRECLOSURE_DELETE_SUCCESS",
          payload: id,
          api: "delete",
        });
        // callBackHandler();
      }
    })
    .catch((err) => {
      toast.error(err.response.data.body.message);
      dispatch({
        type: "PRECLOSURE_LIST_FAILURE",
        payload: "FALIURE",
      });

      // const error = err.response
      // if(error.status === 401 && error.data.body.message === 'Unauthorized/Token_expired '){
      //   getTokenValidity(error.config, 'PRECLOSURE_DELETE_SUCCESS', dispatch, id )
      // }else{
      //   console.log("error couldnot be solved")
      // }
    });
};
