const ProfileUpdateReducer = (state = { user: [] }, action) => {
    switch (action.type) {
      case "PROFILE_UPDATE":
        return {
            user: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default ProfileUpdateReducer;
  