import PaginationComponent from "components/pagination/Pagination";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "reactstrap";
import { setCompanyBookingExtentionInfo } from "redux/actions/admin/BookingExtentionRequestAction";
import CompanyBookingextentionPendingCard from "./CompanyBookingextentionPendingCard";
import pendingReq from "../../../../../assets/img/icons/customIcons/filter.svg";
import CustomFilter from "components/customFilter";
import SearchIcon from "../../../../../assets/img/icons/customIcons/searchicondark.svg";

function CompanyBookingExtentionPendingList({
  handleEmployeeSearchOnChange,
  handleSearch,
  searchFormData,
  data,
  handleClearDate,
  list,
  toggle,
  isDashboard,
  handleSearchOnSelect,
}) {
  const dispatch = useDispatch();
  const [showToggle, setShowToggle] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [tableHeight, setTableHeigth] = useState(300);

  const [title, setTitle] = useState("");

  const responseData = useSelector(
    (state) => state.BookingExtentionApprovalRequestReducer.result
  );
  const responseLoader = useSelector(
    (state) => state.BookingExtentionApprovalRequestReducer.loader
  );

  useEffect(() => {
    setTitle(data);

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handlePagination = (tab) => {
    const finalData = {
      ...searchFormData,
      page: tab,
    };

    dispatch(setCompanyBookingExtentionInfo("0", "1", "pending", finalData));
  };

  // eslint-disable-next-line
  useEffect(() => {
    const divElement = document.getElementById("tableDiv");
    if (responseData?.pages?.length > 1) {
      setTableHeigth(divElement.scrollHeight - 72 + "px");
    } else {
      setTableHeigth(divElement.scrollHeight - 12 + "px");
    }
  });

  return (
    <div className="d-flex">
      <Col id="tableDiv" md={isFilter ? "9" : "12"}>
        <Row className="mb-2 second-search">
          <Col md="10">
            <h3 className="tab-title">
              {`${title} (${
                responseData?.itemCount ? responseData?.itemCount : "0"
              })`}{" "}
            </h3>
          </Col>
          {!isDashboard && (
            <>
              <Col
                md="2"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <div className="common-outer-filter">
                  {list && (
                    <div className="btn-group new-cutom-filter  ">
                      <button
                        type="button"
                        onClick={() => {
                          setShowToggle(!showToggle);

                          console.log("heyyyyy");
                        }}
                        className={"btn dropdown-toggle mr-3"}
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img alt="" src={pendingReq} width={"18px"} />
                      </button>
                      <div className="dropdown-menu dropdown-menu-right py-0">
                        {list?.map((item, key) => (
                          <button
                            key={key}
                            className="dropdown-item"
                            onClick={() => toggle(item?.tabId)}
                            type="button"
                            style={{
                              backgroundColor:
                                item?.tabId === "3" ? "#5e72e4" : "none",
                              color: item?.tabId === "3" ? "white" : "none",
                            }}
                          >
                            {item?.title}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* <SearchDate
                    handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
                    handleSearch={handleSearch}
                    clearDate={handleClearDate}
                  /> */}
                </div>
                <div className="common-outer-filter">
                  <div className="btn-group new-cutom-filter ">
                    <button
                      type="button"
                      onClick={() => setIsFilter(!isFilter)}
                      className={"btn dropdown-toggle mr-3"}
                    >
                      <img alt="" src={SearchIcon} width={"18px"} />
                    </button>
                  </div>
                </div>
              </Col>
            </>
          )}
        </Row>
        <CompanyBookingextentionPendingCard loader={responseLoader} />

        <div
          style={{
            display: responseLoader ? "none" : "contents",
          }}
        >
          <PaginationComponent
            totalCount={responseData?.itemCount}
            handlePagination={handlePagination}
            activePage={responseData?.activePage}
          />
        </div>
      </Col>
      {/* {isFilter && ( */}
        <Col
          md="3"
          style={{
            height: tableHeight,
            minHeight: "670px",
            display: isFilter ? "block" : "none",
          }}
        >
          <CustomFilter
            tableHeight={tableHeight}
            minHeight={"670px"}
            handleSearchOnSelect={handleSearchOnSelect}
            handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
            handleSearch={handleEmployeeSearchOnChange}
            type="inbound"
            list={list}
            isFilter={isFilter}
            setIsFilter={setIsFilter}
            handleClearDate={handleClearDate}
          />
        </Col>
       {/* )} */}
    </div>
  );
}

export default CompanyBookingExtentionPendingList;
