// eslint-disable-next-line
import { Autocomplete, TextField } from "@mui/material";
import { axiosAccountInstance } from "api/Request";
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
const AnimatedSearchBar = ({
  handleEmployeeSearchOnChange,
  handleSearch,
  type,
  // eslint-disable-next-line
  handleSearchOnSelect,
}) => {
  const useStyles = makeStyles((theme) => ({
    autocomplete: {
      width: 300,
      backgroundColor: "white",
      borderRadius: "100px",
      "& .MuiInputBase-root.MuiInput-root:after": {
        borderBottom: "0px",
      },
      "& .MuiInputBase-root.MuiInput-root:before": {
        borderBottom: "0px",
      },
      "& .MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled):before": {
        borderBottom: "0px",
      },
      "& .MuiAutocomplete-popupIndicator": {
        display: "none",
      },
    },
    searchInput: {
      paddingLeft: "10px",
      marginRight: "10px",
      paddingTop: "2px",
    },
  }));
  const classes = useStyles();

  // eslint-disable-next-line
  const [options, setOptions] = useState([]);
  // eslint-disable-next-line
  const [value, setValue] = useState(null);
  const [, setSearchText] = useState("");
  // eslint-disable-next-line
  const handleChange = (event) => {
    const newSearchText = event.target.value;
    setSearchText(newSearchText);
    // handleEmployeeSearchOnChange(event);
    // Delayed API call after 5000ms
    setTimeout(() => {
      fetchOptions(newSearchText);
    }, 500);
  };

  const fetchOptions = async (searchData) => {
    try {
      axiosAccountInstance
        .get(`${process.env.REACT_APP_API_URL}list_skills?filter=${searchData}`)
        .then((result) => {
          setOptions(result?.data?.body?.data);
        });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="search-boxes">
          {type !== "manager" && type !== "employee" && (
            <form className="mr-3" style={{ background: "transparent" }}></form>
          )}

          {(type === "manager" || type === "employee") && (
            <form
              className="d-flex mr-3"
              style={{ marginBottom: "10px" }}
              onSubmit={handleSearch}
            >
              <div
                style={{
                  paddingLeft: "10px",
                  marginRight: "10px",
                  paddingTop: "2px",
                }}
              >
                <img
                  width={"16"}
                  alt="..."
                  src={
                    require("../../assets/img/icons/customIcons/searchicondark.svg")
                      .default
                  }
                />
              </div>
              <TextField
                className={classes.autocomplete}
                // {...params}
                name="search"
                placeholder="Search by name"
                onChange={(e) =>
                  handleEmployeeSearchOnChange("search", e.target.value)
                }
                variant="standard"
              />
            </form>
          )}

          {type !== "manager" && (
            <form
              className="d-flex"
              style={{ marginBottom: "10px" }}
              onSubmit={handleSearch}
            >
              <div
                style={{
                  paddingLeft: "10px",
                  marginRight: "10px",
                  paddingTop: "2px",
                }}
              >
                <img
                  width={"16"}
                  alt="..."
                  src={
                    require("../../assets/img/icons/customIcons/searchicondark.svg")
                      .default
                  }
                />
              </div>
              <TextField
                className={classes.autocomplete}
                // {...params}
                name="experience"
                placeholder="Search by experience"
                onChange={(e) =>
                  handleEmployeeSearchOnChange("experience", e.target.value)
                }
                variant="standard"
              />
            </form>

            //nnn
          )}

          {type === "inbound" && (
            <form
              className="d-flex"
              style={{ marginBottom: "10px" }}
              onSubmit={handleSearch}
            >
              <div
                style={{
                  paddingLeft: "10px",
                  marginRight: "10px",
                  paddingTop: "2px",
                }}
              >
                <img
                  width={"16"}
                  alt="..."
                  src={
                    require("../../assets/img/icons/customIcons/searchicondark.svg")
                      .default
                  }
                />
              </div>
              <TextField
                className={classes.autocomplete}
                // {...params}
                name="parentCompany"
                placeholder="Search by parent company"
                onChange={(e) =>
                  handleEmployeeSearchOnChange("parentCompany", e.target.value)
                }
                variant="standard"
              />
            </form>

            //nnn
          )}

          {type === "outbound" && (
            <form
              className="d-flex"
              style={{ marginBottom: "10px" }}
              onSubmit={handleSearch}
            >
              <div
                style={{
                  paddingLeft: "10px",
                  marginRight: "10px",
                  paddingTop: "2px",
                }}
              >
                <img
                  width={"16"}
                  alt="..."
                  src={
                    require("../../assets/img/icons/customIcons/searchicondark.svg")
                      .default
                  }
                />
              </div>
              <TextField
                className={classes.autocomplete}
                // {...params}
                name="bookedCompany"
                placeholder="Search by booked company"
                onChange={(e) =>
                  handleEmployeeSearchOnChange("bookedCompany", e.target.value)
                }
                variant="standard"
              />
            </form>

            //nnn
          )}
        </div>
      </div>
    </div>
  );
};

export default AnimatedSearchBar;
