// import { useState } from "react";
import React from "react";
import { Rating } from "react-simple-star-rating";
import {
  Button,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Label,
  FormFeedback,
  // Tooltip,
} from "reactstrap";
function AddSkills(props) {
  const { handleOnChange, skillSets, deleteSkillSet, errorsData ,addSkillSet} = props;
  // const [isHover, setIsHover] = useState(false);

  const errors = errorsData?.skillSetsError;

  // const toggle = () => {
  //   setIsHover(!isHover);
  // };
  
  return (
    <>
      {skillSets &&
        skillSets.map((formData, keyIndex) => (
          <Row className="justify-content-center">
            <Col md="12" className="mb-3">
              <Row>
                <Col md="4">
                  <FormGroup>
                    <Label
                      style={{ fontSize: "10px" }}
                      for="employeeName"
                      className="text-uppercase"
                    >
                      Skill<span style={{ color: "red" }}>*</span>
                    </Label>
                    <InputGroup className="input-group-alternative shadow-none border">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>

                      <Input
                        placeholder="Skill"
                        type="text"
                        name="domain"
                        onChange={(e) => handleOnChange(e, keyIndex, "domain")}
                        value={formData?.domain}
                        invalid={
                          errors &&
                          !!errors[keyIndex] &&
                          !!errors[keyIndex].domain
                        }
                      />
                    </InputGroup>
                    <FormFeedback
                      className={
                        errors && !!errors[keyIndex]?.domain && "invalid-field"
                      }
                    >
                      {errors && errors[keyIndex]?.domain}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label
                      style={{ fontSize: "10px" }}
                      for="fromdate"
                      className="text-uppercase"
                    >
                      total experience in years<span style={{ color: "red" }}>*</span>
                    </Label>
                    <InputGroup className="input-group-alternative shadow-none border">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-hat-3" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        // min={0}
                        placeholder="Experience in years"
                        type="number"
                        name="numberOfYears"
                        min={0}
                        onKeyPress={(e) => {
                          if (e.key === "e") {
                            e.preventDefault();
                          }
                        }}
                        value={formData?.numberOfYears}
                        
                        onChange={(e) =>
                          handleOnChange(e, keyIndex, "numberOfYears")
                        }
                        invalid={
                          errors &&
                          !!errors[keyIndex] &&
                          !!errors[keyIndex].numberOfYears
                        }
                      />
                    </InputGroup>
                    <FormFeedback
                      className={
                        errors &&
                        !!errors[keyIndex]?.numberOfYears &&
                        "invalid-field"
                      }
                    >
                      {errors && errors[keyIndex]?.numberOfYears}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col md="4">
                  <div className="d-flex justify-content-between">
                  <FormGroup >
                    <Label
                      style={{ fontSize: "10px", display: "block", marginTop: "5px" }}
                      for="fromdate"
                      className="text-uppercase"
                    >
                      Rating<span style={{ color: "red" }}>*</span>
                    </Label>

                    <Rating className="rating-area"
                      initialValue={formData?.rating}
                      name="rating"
                      onClick={(e) => handleOnChange(e, keyIndex, "rating")}
                      // onPointerEnter={onPointerEnter}
                      // onPointerLeave={onPointerLeave}
                      // onPointerMove={onPointerMove}
                    />

                   
                    <FormFeedback
                      className={
                        errors && !!errors[keyIndex]?.rating && "invalid-field"
                      }
                    >
                      {errors && errors[keyIndex]?.rating}
                    </FormFeedback>
                  </FormGroup>
                  <div className="button-area">
                  <label> &nbsp; </label>
                  


                  
<div className="text-center">
  {keyIndex !== 0 && (
    
    <Button
    onClick={(e) => deleteSkillSet(e, keyIndex)}
    size="sm"
    id={`Tooltip_${keyIndex}_delete`}
    color="danger"
    outline
  >
    <i className="fa fa-trash" aria-hidden="true"></i>
    {/* <Tooltip
      placement="top"
      autohide={true}
      isOpen={isHover}
      target={`Tooltip_${keyIndex}_delete`}
      toggle={(e) => toggle("delete", e)}
    >
      Delete
    </Tooltip> */}
  </Button>
  )}
   {keyIndex === 0 && skillSets.length === 1 && (
      <Button
        // onClick={addSkillSet}
        onClick={(e) => addSkillSet(e, keyIndex)}

        size="sm"
        id={`Tooltip_${keyIndex}_add`}
        color="primary"
        outline
      >
        <i className="fa fa-plus" aria-hidden="true"></i>
        {/* <Tooltip
          placement="top"
          autohide={true}
          isOpen={isHover}
          target={`Tooltip_${keyIndex}_add`}
          toggle={(e) => toggle("add", e)}
        >
          Add
        </Tooltip> */}
      </Button>
   )}
     {keyIndex === skillSets.length - 1 && skillSets.length > 1 && (
         <Button
         // onClick={addSkillSet}
         onClick={(e) => addSkillSet(e, keyIndex)}
 
         size="sm"
         id={`Tooltip_${keyIndex}_add`}
         color="primary"
         outline
       >
         <i className="fa fa-plus" aria-hidden="true"></i>
         {/* <Tooltip
           placement="top"
           autohide={true}
           isOpen={isHover}
           target={`Tooltip_${keyIndex}_add`}
           toggle={(e) => toggle("add", e)}
         >
           Add
         </Tooltip> */}
       </Button>
      )}
    
  
</div>

                  </div>
                  </div>
                </Col>

              </Row>
            </Col>
          </Row>
        ))}
    </>
  );
}

export default AddSkills;
