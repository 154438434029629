import React, { useEffect, useState } from "react";

import { Container } from "reactstrap";
import { Row, Col, Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import ManagerCard from "./ManagerCard";
// import SearchDate from "components/SearchBars/SearchDate";
import history from "helpers/History";
import PaginationComponent from "components/pagination/Pagination";
import DashboardHeader from "components/Headers/DashboardHeader";
import { setManagerListInfo } from "redux/actions/super-admin/ManagerListAction";
import SearchIcon from "../../../../assets/img/icons/customIcons/searchicondark.svg";
import CustomFilter from "components/customFilter";

const ManagerList = ({ companyId }) => {
  const dispatch = useDispatch();
  // const [employeeSearchType, setEmployeeSearchType] = useState();
  const [searchFormData, setSearchFormData] = useState({
    // experience: null,
    page: null,
    // fromDate: null,
    // toDate: null,
    search: null,
    limit: null,
    name: null,
  });
  // console.log(searchFormData)
  const [tab, setTab] = useState(1);
  const [isFilter, setIsFilter] = useState(false);
  const [tableHeight, setTableHeigth] = useState(300);

  const fetchManagerListResult = useSelector(
    (state) => state.ManagerListReducer.result
  );

  const fetchManagerLoader = useSelector(
    (state) => state.ManagerListReducer.loader
  );

  const handleEmployeeSearchOnChange = (e, v) => {
    setSearchFormData({ ...searchFormData, [e]: v });
  };
  const handleSearchOnChange = (name, val) => {
    // e.preventDefault();
    employeeListing({ ...searchFormData, search: val }, companyId);
  };

  const clearDate = () => {
    const data = { ...searchFormData, fromDate: null, toDate: null };
    setSearchFormData(data);
    employeeListing(data, companyId);
  };
  const handleSearch = (e) => {
    e.preventDefault();
    employeeListing(searchFormData, companyId);
  };

  const handlePagination = (tab) => {
    setTab(tab);
    const finalData = {
      ...searchFormData,
      page: tab,
    };

    employeeListing(finalData, companyId);
  };

  const employeeListing = (searchFormData, companyID) => {
    dispatch(setManagerListInfo(searchFormData, companyID));
  };
  useEffect(() => {
    employeeListing(null, companyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addEmployeeSubmithandler = (e) => {
    e.preventDefault();
    history.push("/admin/add-manager");
  };

  // eslint-disable-next-line
  useEffect(() => {
    const divElement = document.getElementById("tableDiv");

    if (fetchManagerListResult?.pages?.length > 1) {
      setTableHeigth(divElement.scrollHeight - 72 + "px");
    } else {
      setTableHeigth(divElement.scrollHeight - 12 + "px");
    }
  });

  return (
    <>
      {companyId ? (
        <Container className="mt-2 main-contentarea" fluid>
          <Row>
            <Col id="tableDiv" md={isFilter ? "9" : "12"}>
              <Row>
                <Col md="10">
                  <div className="d-flex justify-content-between">
                    <h3 class="tab-title">Managers Listing</h3>
                  </div>
                </Col>

                <Col
                  md="2"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <div className="common-outer-filter">
                    <div className="btn-group new-cutom-filter ">
                      <button
                        type="button"
                        onClick={() => setIsFilter(!isFilter)}
                        className={"btn dropdown-toggle"}
                      >
                        <img alt="" src={SearchIcon} width={"18px"} />
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>

              <ManagerCard
                searchFormData={{ ...searchFormData, page: tab }}
                companyId={companyId}
                loader={fetchManagerLoader}
              />

              <div
                style={{ display: fetchManagerLoader ? "none" : "contents" }}
              >
                <PaginationComponent
                  totalCount={fetchManagerListResult?.itemCount}
                  handlePagination={handlePagination}
                  activePage={fetchManagerListResult?.activePage}
                />
              </div>
            </Col>

            {/* {isFilter && ( */}
              <Col
                md="3"
                style={{
                  height: tableHeight,
                  minHeight: "308px",
                  display: isFilter ? "block" : "none",
                }}
              >
                <CustomFilter
                  tableHeight={tableHeight}
                  isFilter={isFilter}
                  setIsFilter={setIsFilter}
                  clearDate={clearDate}
                  type="manager"
                  handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
                  handleSearch={handleSearch}
                />
              </Col>
            {/* )} */}
          </Row>
        </Container>
      ) : (
        <>
          <DashboardHeader />

          <Container className="mt-2 main-contentarea" fluid>
            <Row>
              <Col id="tableDiv" md={isFilter ? "9" : "12"}>
                <Row>
                  <Col md={"7"}>
                    <h3 class="tab-title">Managers Listing</h3>
                  </Col>
                  <Col md={"5"}>
                    <div
                      className="d-flex "
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        outline
                        className="mb-2 add-button-common border-0"
                        type="button"
                        onClick={addEmployeeSubmithandler}
                      >
                        {" "}
                        <span className="add-button">+</span>
                        Add Manager
                      </Button>
                      <div className="common-outer-filter">
                        <div className="btn-group new-cutom-filter ">
                          <button
                            type="button"
                            onClick={() => setIsFilter(!isFilter)}
                            className={"btn dropdown-toggle mr-3"}
                          >
                            <img alt="" src={SearchIcon} width={"18px"} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <ManagerCard
                  searchFormData={{ ...searchFormData, page: tab }}
                  loader={fetchManagerLoader}
                />

                <div
                  style={{ display: fetchManagerLoader ? "none" : "contents" }}
                >
                  <PaginationComponent
                    totalCount={fetchManagerListResult?.itemCount}
                    handlePagination={handlePagination}
                    activePage={fetchManagerListResult?.activePage}
                  />
                </div>
              </Col>
              {/* {isFilter && ( */}
                <Col
                  md="3"
                  style={{
                    height: tableHeight,
                    minHeight: "308px",
                    display: isFilter ? "block" : "none",
                  }}
                >
                  <CustomFilter
                    tableHeight={tableHeight}
                    isFilter={isFilter}
                    setIsFilter={setIsFilter}
                    clearDate={clearDate}
                    type="manager"
                    handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
                    handleSearch={handleSearch}
                    handleSearchOnChange={handleSearchOnChange}
                  />
                </Col>
              {/* )} */}
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default ManagerList;
