import React, { useState } from "react";
import { Container } from "reactstrap";
import DashboardHeader from "components/Headers/DashboardHeader";
import pendingIcon from "../../../../assets/img/icons/customIcons/pendingIcon.svg";
import approvedIcon from "../../../../assets/img/icons/customIcons/maps-and-flags.svg";
import rejectedIcon from "../../../../assets/img/icons/customIcons/cross-button.svg";
import EmployeeList from "../employees/EmployeeList";
import BookingDetails from "views/examples/users/BookingDetailsContainer";
import ManagerList from "../managers/ManagerList";

function CompanyMainContainer({ match }) {
  const companyId = match.params.companyId;
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      switch (tab) {
        case "1":
          // DO_SOMETHING
          break;
        case "2":
          // DO_SOMETHING
          break;
        case "3":
          // DO_SOMETHING
          break;
        case "4":
          // DO_SOMETHING
          break;
        default:
          break;
      }

      setActiveTab(tab);
    }
  };

  const data = [
    {
      title: "Employee Details",
      tabId: "1",
      count: null,
      icon: pendingIcon,
      color: "blue",
    },
    {
      title: "Booking Details",
      tabId: "2",
      count: null,
      icon: approvedIcon,
      color: "green",
    },
    {
      title: "Managers List",
      tabId: "3",
      count: null,
      icon: rejectedIcon,
      color: "pink",
    },
  ];
  return (
    <div>
      <DashboardHeader
        tabCards={data}
        selectedCard={(tabId) => toggle(tabId)}
        activeTab={activeTab}
      />

      <Container className="mt-4 main-contentarea container-fluid" fluid>
        <div className="custom-tabs">
          {activeTab === "1" && <EmployeeList companyId={companyId} />}

          {activeTab === "2" && <BookingDetails companyId={companyId} />}

          {activeTab === "3" && <ManagerList companyId={companyId} />}
        </div>
      </Container>
    </div>
  );
}

export default CompanyMainContainer;
