//INBOUND
const PendingPreClosureRequestReducer = (
  state = { loader: false, result: [], error: null },
  action
) => {
  switch (action.type) {
    case "PRE_CLOSURE_LIST_BEGINS":
      return {
        loader: true,
        result: state.result,
        error: null,
      };
    case "PRE_CLOSURE_LIST_SUCCESS":
      return {
        loader: false,
        result: action.payload,
        error: null,
      };
    case "PRE_CLOSURE_LIST_FAILURE":
      return {
        loader: false,
        result: {},
        error: action.payload,
      };

    case "PRE_CLOSURE_DELETE_SUCCESS": {
      let currentPreclosureRequestsList = { ...state.result };
      let preclosureRequestsList =
        currentPreclosureRequestsList && currentPreclosureRequestsList.booking;
      preclosureRequestsList =
        preclosureRequestsList &&
        preclosureRequestsList.filter((item) => item._id !== action.payload);
      currentPreclosureRequestsList = {
        ...currentPreclosureRequestsList,
        booking: preclosureRequestsList,
      };
      return {
        loader: false,
        result: currentPreclosureRequestsList,
        error: null,
      };
    }
    case "PRE_CLOSURE_LIST_RESET":
      return {
        loader: false,
        result: [],
        error: null,
      };
    default:
      return state;
  }
};

export default PendingPreClosureRequestReducer;
