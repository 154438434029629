/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Container } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { setPendingRequestInfo } from "redux/actions/admin/PendingRequestAction";
import { getApprovedRequestsInfo } from "redux/actions/admin/ApprovedRequestAction";
import { getRejectedRequestsInfo } from "redux/actions/admin/RejectedRequestAction";
import { getCompletedRequestsInfo } from "redux/actions/admin/CompletedRequestActions";
import PendingList from "./admin-pending-requests/PendingList";
import ApprovedList from "./admin-approved-booking/ApprovedList";
import RejectedList from "./admin-rejected-requests/RejectedList";
import CompletedList from "./admin-completed-bookin/CompletedList";
import AllRequestList from "./admin-all-requests/AllRequestList";
import { getAllRequestInfo } from "redux/actions/admin/AllRequestAction";
import moment from "moment";
function BookingDetails({ companyId, empId }) {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("0");
  const [searchFormData, setSearchFormData] = useState({
    experience: null,
    skillSet: null,
    fromDate: null,
    toDate: null,
    search: empId ? empId : null,
    parentCompany: null,
  });
  //2023-06-20
  const notification = useSelector((state) => state.NotificationReducer.result);

  const [empIds, setEmpIds] = useState(empId ? empId : null);

  const handleClearSearch = () => {
    setSearchFormData({
      experience: null,
      skillSet: null,
      fromDate: null,
      toDate: null,
      search: null,
      parentCompany: null,
    });
  };
  const list = [
    {
      title: "All Bookings",
      tabId: "0",
      color: "blue",
    },
    {
      title: "Pending Bookings",
      tabId: "1",
      color: "blue",
    },
    // {
    //   title: "Pre-Closure Requests",
    //   tabId: "5",
    //   color: "purple",
    // },
    {
      title: "Approved Bookings",
      tabId: "2",
      color: "green",
    },
    {
      title: "Rejected Bookings",
      tabId: "3",
      color: "pink",
    },
    {
      title: "Completed Bookings",
      tabId: "4",
      color: "purple",
    },
  ];
  const clearEmpId = () => {
    setEmpIds("");
  };

  // HANDLING EMPLOYEE SEARCH
  const handleEmployeeSearchOnChange = (e, v) => {
    setSearchFormData({ ...searchFormData, [e]: v });
  };

  const handleClearDate = () => {
    const data = { ...searchFormData, fromDate: null, toDate: null };
    setSearchFormData(data);
    switch (activeTab) {
      case "0":
        getAllRequestList("1", "1", data, companyId);
        break;
      case "1":
        getPendingListRequests("1", "1", data, companyId);
        break;
      case "2":
        getApprovedListRequests("1", "1", "approved", data, companyId);
        break;
      case "3":
        getRejectedListRequests("1", "1", "rejected", data, companyId);
        break;
      case "4":
        getCompletedListRequests("1", "1", "completed", data, companyId);
        break;
      // case "5":
      //   getPendingPreClosureListRequests(
      //     "1",
      //     "1",
      //     "preclosure",
      //     data,
      //     companyId
      //   );
      //   break;

      default:
        break;
    }
  };

  // HANDLE SEARCH FUNCTION
  const handleSearch = (e) => {
    e.preventDefault();
    switch (activeTab) {
      case "0":
        getAllRequestList("1", "1", searchFormData, companyId);
        break;
      case "1":
        getPendingListRequests("1", "1", searchFormData, companyId);
        break;
      case "2":
        getApprovedListRequests(
          "1",
          "1",
          "approved",
          searchFormData,
          companyId
        );
        break;
      case "3":
        getRejectedListRequests(
          "1",
          "1",
          "rejected",
          searchFormData,
          companyId
        );
        break;
      case "4":
        getCompletedListRequests(
          "1",
          "1",
          "completed",
          searchFormData,
          companyId
        );
        break;
      // case "5":
      //   getPendingPreClosureListRequests("1", "1", searchFormData, companyId);
      //   break;

      default:
        break;
    }
  };

  const handleSearchOnSelect = (value, name) => {
    console.log("searach Val  ", value);

    let val =
      name === "parentCompany"
        ? { ...searchFormData, parentCompany: value }
        : name === "experience"
        ? { ...searchFormData, experience: value }
        : name === "date"
        ? {
            ...searchFormData,
            fromDate: moment(value.startDate).format("YYYY-MM-DD"),
            toDate: moment(value.endDate).format("YYYY-MM-DD"),
          }
        : { ...searchFormData, search: value };

    setSearchFormData(val);
    switch (activeTab) {
      case "0":
        getAllRequestList("1", "1", val, companyId);
        break;
      case "1":
        getPendingListRequests("1", "1", val, companyId);
        break;
      case "2":
        getApprovedListRequests("1", "1", "approved", val, companyId);
        break;
      case "3":
        getRejectedListRequests("1", "1", "rejected", val, companyId);
        break;
      case "4":
        getCompletedListRequests("1", "1", "completed", val, companyId);
        break;
      // case "5":
      //   getPendingPreClosureListRequests("1", "1", searchFormData, companyId);
      //   break;

      default:
        break;
    }
  };

  // PENDING BOOKING DATA
  const getPendingListRequests = (createdBy, page, searchData, companyID) => {
    dispatch(setPendingRequestInfo(createdBy, page, searchData, companyID));
  };
  const getAllRequestList = (createdBy, page, searchData, companyID) => {
    dispatch(getAllRequestInfo(createdBy, page, searchData, companyID));
  };
  // APPROVED_BOOKINGS DATA
  const getApprovedListRequests = (
    createdBy,
    page,
    status,
    searchData,
    companyID
  ) => {
    dispatch(
      getApprovedRequestsInfo(createdBy, page, status, searchData, companyID)
    );
  };

  // REJECTED BOOKING DATA

  const getRejectedListRequests = (
    createdBy,
    page,
    status,
    searchData,
    companyID
  ) => {
    dispatch(
      getRejectedRequestsInfo(createdBy, page, status, searchData, companyID)
    );
  };

  // const getPendingPreClosureListRequests = (
  //   createdBy,
  //   page,
  //   searchData,
  //   companyID
  // ) => {
  //   dispatch(
  //     getPendingPreClosureRequestsInfo(createdBy, page, searchData, companyID)
  //   );
  // };

  // COMPLETED BOOKING DATA
  const getCompletedListRequests = (
    createdBy,
    page,
    status,
    searchData,
    companyID
  ) => {
    dispatch(
      getCompletedRequestsInfo(createdBy, page, status, searchData, companyID)
    );
  };

  const toggle = (tab) => {
    setEmpIds(null);
    dispatch({
      type: "NOTIFICATION_RESET",
    });
    if (activeTab !== tab.target.value) {
      switch (tab.target.value) {
        case "0":
          getAllRequestList("1", "1", null, companyId);
          // handleClearSearch();

          break;
        case "1":
          getPendingListRequests("1", "1", null, companyId);
          handleClearSearch();

          break;
        case "2":
          getApprovedListRequests("1", "1", "approved", null, companyId);
          handleClearSearch();

          break;
        case "3":
          getRejectedListRequests("1", "1", "rejected", null, companyId);
          handleClearSearch();

          break;
        case "4":
          getCompletedListRequests("1", "1", "completed", null, companyId);
          handleClearSearch();

          break;
        // case "5":
        //   getPendingPreClosureListRequests("1", "1", null, companyId);
        //   handleClearSearch();

        //   break;
        default:
          break;
      }

      setActiveTab(tab.target.value);
    }
  };

  useEffect(() => {
    if (empIds) {
      getAllRequestList("1", 1, searchFormData, companyId);
    } else if (notification?.empId) {
      setActiveTab("0");
      getAllRequestList(
        "1",
        1,
        { ...searchFormData, search: notification?.empId },
        companyId
      );
    } else {
      getAllRequestList("1", 1, null, companyId);
    }
  }, [notification]);

  return (
    <>
      <Row>
        {/* <Col md="12" style={{ bottom: "162px" }}>
          <select className="custom-select select-positioned" onChange={(event) => toggle(event)}>
            {list?.map((item, key) => (
              <option key={key} value={item?.tabId}>
                {item?.title}
              </option>
            ))}
          </select>
        </Col> */}
      </Row>
      <Container className="mt-4 main-contentarea container-fluid" fluid>
        <div className="custom-tabs">
          {/* PENDING */}

          {activeTab === "0" && (
            <>
              <AllRequestList
                list={list}
                toggle={toggle}
                handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
                handleSearch={handleSearch}
                searchFormData={searchFormData}
                setSearchFormData={setSearchFormData}
                data={"All Bookings"}
                empId={empIds}
                clearEmpId={clearEmpId}
                companyId={companyId}
                handleClearDate={handleClearDate}
                handleSearchOnSelect={handleSearchOnSelect}
              />
            </>
          )}
          {activeTab === "1" && (
            <>
              <PendingList
                list={list}
                toggle={toggle}
                handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
                handleSearch={handleSearch}
                searchFormData={searchFormData}
                setSearchFormData={setSearchFormData}
                data={"Pending Bookings"}
                companyId={companyId}
                handleClearDate={handleClearDate}
                handleSearchOnSelect={handleSearchOnSelect}
              />
            </>
          )}

          {/* APPROVED */}
          {activeTab === "2" && (
            <>
              <ApprovedList
                list={list}
                toggle={toggle}
                handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
                handleSearch={handleSearch}
                searchFormData={searchFormData}
                setSearchFormData={setSearchFormData}
                data={"Approved Bookings"}
                companyId={companyId}
                handleClearDate={handleClearDate}
                handleSearchOnSelect={handleSearchOnSelect}
              />
            </>
          )}
          {/* REJECTED */}
          {activeTab === "3" && (
            <>
              <RejectedList
                list={list}
                toggle={toggle}
                handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
                handleSearch={handleSearch}
                searchFormData={searchFormData}
                setSearchFormData={setSearchFormData}
                data={["Rejected Bookings"]}
                companyId={companyId}
                handleClearDate={handleClearDate}
                handleSearchOnSelect={handleSearchOnSelect}
              />
            </>
          )}
          {/* COMPLETED */}
          {activeTab === "4" && (
            <>
              <CompletedList
                list={list}
                toggle={toggle}
                handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
                handleSearch={handleSearch}
                searchFormData={searchFormData}
                setSearchFormData={setSearchFormData}
                data={"Completed Bookings"}
                companyId={companyId}
                handleClearDate={handleClearDate}
                handleSearchOnSelect={handleSearchOnSelect}
              />
            </>
          )}
          {/* PENDING PRECLOSURE */}
          {/* {activeTab === "5" && (
            <>
              <PendingPreClosureList
                list={list}
                toggle={toggle}
                handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
                handleSearch={handleSearch}
                searchFormData={searchFormData}
                setSearchFormData={setSearchFormData}
                data={"Pre-Closure Requests"}
                companyId={companyId}
                handleClearDate={handleClearDate}
              />
            </>
          )} */}
        </div>
      </Container>
    </>
  );
}

export default BookingDetails;
