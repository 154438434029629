import { axiosAccountInstance } from "api/Request";

export const setNotificationList =
  (userId) => (dispatch) => {
    let url = `/notification/${userId}`;
    dispatch({
      type: "NOTIFICATION_LIST_BEGINS",
    });

    axiosAccountInstance
      .get(url)
      .then(
        (result) => {
          dispatch({
            type: "NOTIFICATION_LIST_SUCCESS",
            payload: result.data.body.data,
          });
        }
      )
      .catch((err) => {
        dispatch({
          type: "NOTIFICATION_LIST_FAILURE",
          payload: "FALIURE",
        });
      });
  };


