const BookingPendingReducer = (
  state = { loader: false, result: [], error: null },
  action
) => {
  switch (action.type) {
    case "PENDING_BOOKING_LIST_BEGINS":
      return {
        loader: true,
        result: state.result,
        error: null,
      };
    case "PENDING_BOOKING_LIST_SUCCESS":
      return {
        loader: false,
        result: action.payload,
        error: null,
      };
    case "PENDING_BOOKING_LIST_FAILURE":
      return {
        loader: false,
        result: {},
        error: action.payload,
      };

    case "BOOKING_APPROVAL_REQUEST_DELETE_SUCCESS": {
      let currentPendingBookingRequestsList = { ...state.result };
      let pendingBookingRequestsList =
        currentPendingBookingRequestsList &&
        currentPendingBookingRequestsList.booking;
      pendingBookingRequestsList =
        pendingBookingRequestsList &&
        pendingBookingRequestsList.filter(
          (item) => item._id !== action.payload
        );
      currentPendingBookingRequestsList = {
        ...currentPendingBookingRequestsList,
        booking: pendingBookingRequestsList,
      };
      return {
        loader: false,
        result: currentPendingBookingRequestsList,
        error: null,
      };
    }
    case "PENDING_BOOKING_LIST_RESET":
      return {
        loader: false,
        result: {},
        error: null,
      };
    default:
      return state;
  }
};

export default BookingPendingReducer;
