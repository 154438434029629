import { axiosAccountInstance } from "api/Request";
import { getSearchUrl } from "helpers/utils";


export const setSowInfo = (createdBy, page, status, searchData ,sow_status) => (dispatch) => {

    let url = `/booking/approval_requests?createdBy=${createdBy}&type=${sow_status}&status=${status}`
    url = getSearchUrl(url, searchData)
    dispatch({
      type: "SOW_LIST_BEGINS",
    });
    axiosAccountInstance
      .get(url)
      .then(
        (result) => {
          dispatch({
            type: "SOW_LIST_SUCCESS",
            payload: result.data.body.data,
          });
        }
        // (err) => {
        //     dispatch({
        //         type: "SOW_LIST_FAILURE",
        //         payload: "FALIURE",
        //     });
        //     // const error = err.response
        //     // if(error.status === 401 && error.data.body.message === 'Unauthorized/Token_expired '){
        //     //   getTokenValidity(error.config)
        //     // }else{
        //     //   console.log("error couldnot be solved")
        //     // }
        // }
      )
      .catch((err) => {
        dispatch({
          type: "SOW_LIST_FAILURE",
          payload: "FALIURE",
        });
      });
  };
