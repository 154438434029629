const AllRequestReducer = (
  state = { loader: false, result: [], error: null },
  action
) => {
  switch (action.type) {
    case "ALL_REQUEST_LIST_BEGINS":
      return {
        loader: true,
        result: state.result,
        error: null,
      };
    case "ALL_REQUEST_LIST_SUCCESS":
      return {
        loader: false,
        result: action.payload,
        error: null,
      };
    case "ALL_REQUEST_LIST_FAILURE":
      return {
        loader: false,
        result: {},
        error: action.payload,
      };

    case "ALL_REQUEST_DELETE_SUCCESS": {
      let currentAllRequestsList = { ...state.result };
      let allRequestsList =
        currentAllRequestsList && currentAllRequestsList.booking;
      allRequestsList =
        allRequestsList &&
        allRequestsList.filter((item) => item._id !== action.payload);
      currentAllRequestsList = {
        ...currentAllRequestsList,
        booking: allRequestsList,
      };
      return {
        loader: false,
        result: currentAllRequestsList,
        error: null,
      };
    }
    case "ALL_REQUEST_LIST_RESET":
      return {
        loader: false,
        result: [],
        error: null,
      };
    default:
      return state;
  }
};

export default AllRequestReducer;
