import { axiosBaseInstance } from "api/Request";
import toast from "../../../components/Toastify";

// import { getTokenValidity } from "he/lpers/getTokenValidity";

export const setForgotPasswordInfo = (email) => (dispatch) => {
  dispatch({
    type: "FORGOT_PASSWORD_BEGINS",
    payload: email,
  });
  axiosBaseInstance
    .post("/forgot/password", {
      email,
    })
    .then((result) => {
      if (result.data.statusCode === 200) {
        toast.success("Reset link has been sent to your email");
        dispatch({
          type: "FORGOT_PASSWORD_SUCCESS",
          payload: result.data.body.data,
        });
      }
    })
    .catch((err) => {
      toast.error(err.response.data.body.message);
      dispatch({
        type: "FORGOT_PASSWORD_FAILURE",
        payload: "FALIURE",
      });
    });
};
