/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import PaginationComponent from "components/pagination/Pagination";
import pendingReq from "../../../../assets/img/icons/customIcons/filter.svg";
import AllExtentionCard from "./AllExtentionCard";
import { setCompanyBookingExtentionInfo } from "redux/actions/admin/BookingExtentionRequestAction";
import history from "helpers/History";
import CustomFilter from "components/customFilter";
import SearchIcon from "../../../../assets/img/icons/customIcons/searchicondark.svg";

function AllExtentionList({
  handleEmployeeSearchOnChange,
  handleSearch,
  searchFormData,
  setSearchFormData,
  data,
  toggle,
  handleClearDate,
  list,
  empIds,
  clearEmpId,
  handleSearchOnSelect,
}) {
  const notification = useSelector((state) => state.NotificationReducer.result);
  const dispatch = useDispatch();
  const [showToggle, setShowToggle] = useState(false);
  const allBookingExtentionResult = useSelector(
    (state) => state.AllBookingExtentionRequestReducer.result
  );
  const allBookingExtentionLoader = useSelector(
    (state) => state.AllBookingExtentionRequestReducer.loader
  );
  const [isFilter, setIsFilter] = useState(false);
  const [tableHeight, setTableHeigth] = useState(300);

  const [title, setTitle] = useState("All Booking extension");
  const [empIdChip, setEmpIdChip] = useState(false);
  useEffect(() => {
    setTitle(data);
    if (empIds) {
      setEmpIdChip(true);
    } else if (notification?.empId) {
      setEmpIdChip(true);
    } else {
      setEmpIdChip(false);
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, notification]);

  const handlePagination = (tab) => {
    const finalData = {
      ...searchFormData,
      page: tab,
    };
    dispatch(setCompanyBookingExtentionInfo("1", "1", "", finalData));
  };
  const clearChip = () => {
    setEmpIdChip(false);
    dispatch(setCompanyBookingExtentionInfo("1", "1", "", null));
    clearEmpId();
    history.push("/admin/booking-details");
    dispatch({
      type: "NOTIFICATION_RESET",
    });
  };
  // eslint-disable-next-line
  useEffect(() => {
    const divElement = document.getElementById("tableDiv");
    if (allBookingExtentionResult?.pages?.length > 1) {
      setTableHeigth(divElement.scrollHeight - 72 + "px");
    } else {
      setTableHeigth(divElement.scrollHeight - 12 + "px");
    }
  });
  return (
    <div className="d-flex">
      <Col id="tableDiv" md={isFilter ? "9" : "12"}>
        <Row className="mb-2">
          <Col md="10">
            <div className="d-flex" style={{ alignItems: "center" }}>
              <h3 className="tab-title">
                {`${title} (${
                  allBookingExtentionResult?.itemCount
                    ? allBookingExtentionResult?.itemCount
                    : "0"
                })`}{" "}
              </h3>
              {empIdChip && (
                <div class="chip ml-3">
                  {empIds || notification?.empId}
                  <span onClick={clearChip} class="closebtn">
                    &times;
                  </span>
                </div>
              )}
            </div>
          </Col>

          <Col md="2" style={{ display: "flex", justifyContent: "flex-end" }}>
            <div className="common-outer-filter">
              {list && (
                <div className="btn-group new-cutom-filter ">
                  <button
                    type="button"
                    onClick={() => setShowToggle(!showToggle)}
                    className={"btn dropdown-toggle mr-3"}
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img alt="" src={pendingReq} width={"18px"} />
                  </button>
                  <div className="dropdown-menu dropdown-menu-right py-0">
                    {list?.map((item, key) => (
                      <button
                        style={{
                          backgroundColor:
                            item?.tabId === "0" ? "#5e72e4" : "none",
                          color: item?.tabId === "0" ? "white" : "black",
                        }}
                        key={key}
                        className="dropdown-item"
                        onClick={() =>
                          toggle({ target: { value: item?.tabId } })
                        }
                        type="button"
                      >
                        {item?.title}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="common-outer-filter">
              <div className="btn-group new-cutom-filter ">
                <button
                  type="button"
                  onClick={() => setIsFilter(!isFilter)}
                  className={"btn dropdown-toggle mr-3"}
                >
                  <img alt="" src={SearchIcon} width={"18px"} />
                </button>
              </div>
            </div>
          </Col>
        </Row>
        {/* BOOKING EXTENTION */}

        <AllExtentionCard loader={allBookingExtentionLoader} />
        <div
          style={{
            display: allBookingExtentionLoader ? "none" : "contents",
          }}
        >
          <PaginationComponent
            totalCount={allBookingExtentionResult?.itemCount}
            handlePagination={handlePagination}
            activePage={allBookingExtentionResult?.activePage}
          />
        </div>
      </Col>
      {/* {isFilter && ( */}
        <Col
          md="3"
          style={{
            height: tableHeight,
            minHeight: "670px",
            display: isFilter ? "block" : "none",
          }}
        >
          <CustomFilter
            tableHeight={tableHeight}
            minHeight={"670px"}
            handleSearchOnSelect={handleSearchOnSelect}
            handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
            handleSearch={handleEmployeeSearchOnChange}
            type="outbound"
            list={list}
            isFilter={isFilter}
            setIsFilter={setIsFilter}
            handleClearDate={handleClearDate}
          />
        </Col>
      {/* )} */}
    </div>
  );
}

export default AllExtentionList;
