import BookingModal from "components/Modals/BookingModal";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Tables from "views/examples/Tables";
import viewIcn from "../../../../assets/img/icons/customIcons/view.svg";
function HistoryCard({ BookingHistoryListData }) {
  const fetcgBookingHistoryListData = useSelector(
    (state) => state?.EmployeeBookingHistoryListReducer?.result
  );
  const [bookingData, setBookingData] = useState({});
  const [bookingModal, setBookingModal] = useState(false)
  const [, setData] = useState([]);
  useEffect(() => {
    if (fetcgBookingHistoryListData) {
      setData(fetcgBookingHistoryListData?.bookingHistory);
    }
    return () => {
      setData([]);
    };
  }, [fetcgBookingHistoryListData]);

  

  const close = () => {
    setBookingModal(false);

  };
  const viewHandler = (dataObj) => {
    setBookingData(dataObj);
    setBookingModal(true);
  };

  const colums = [
    { columnName: "Company", columnTag: "bookedCompany", type: "data" },
    { columnName: "Date Range", columnTag: "duration", type: "objectData" },
    { columnName: "Hourly Payment", columnTag: "hourlyPayment", type: "paymentMapping" },
    { columnName: "Working Days", columnTag: "workingDays", type: "data" },
    // { columnName: "Currency", columnTag: "currencyCode", type: "data" },
    { columnName: "Status", columnTag: "status", type: "data" },
    { columnName: "", columnTag: "", type: "" },

  ];
  const actionbuttons = [
    {
      columnName: "",
      btnTitle: "View",
      type: "actionButton",
      btn: "ViewBooking",
      btnType: "primary",
      btnTooltip: 'View',
      btnIcon: viewIcn,
      actionFunction: viewHandler,
    },


  ]
 
  return (
    <div>

      <Tables
        coloums={colums}
        data={fetcgBookingHistoryListData?.bookingHistory}
        actionButtons={actionbuttons}
      />

      <BookingModal modal={bookingModal} close={close} data={bookingData} />

    </div>
  );
}

export default HistoryCard;
