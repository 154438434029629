import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { baseURL } from "api/Request";
import moment from "moment";
import StyledDropzone from "components/drag-and-drop/DragDropFile";
import downloadImg from "../../assets/img/icons/customIcons/download.gif";

export default function SOWUploadModal({
  sowModal,
  close,
  imageSelect,
  onApproveSOW,
  sowHistory,
  isApproveButtonDisabled,
  errors,
  sowData,
}) {
  
  return (
    <>
      {(sowData?.status === "SOW Pending" ||
        sowData?.status === "SOW Rejected") && (
        <Modal toggle={close} centered isOpen={sowModal}>
          <Row>
            <div className="col">
              <ModalHeader className="text-uppercase font-weight-bolder">
                Upload SOW Document
              </ModalHeader>
            </div>
            <Row>
              <Col>
                <ModalHeader className="text-uppercase">
                  <Button onClick={close} className="mx-1" close />
                </ModalHeader>
              </Col>
            </Row>
          </Row>
          <ModalBody>
            <FormGroup row className="mb-0">
              <Label for="exampleText" sm={12} className="text-center">
                Please upload SOW document
              </Label>
              <Col sm={12}>
                <StyledDropzone
                  type="doc"
                  onChangeImage={(e) => {
                    imageSelect(e);
                  }}
                />
                <FormFeedback
                  className={errors?.sowDocument && "resume-invalid"}
                >
                  {errors?.sowDocument}
                </FormFeedback>
              </Col>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              size="sm"
              onClick={() => onApproveSOW()}
              color="primary"
              disabled={isApproveButtonDisabled}
            >
              Upload
            </Button>{" "}
            {/* <Button size="sm" onClick={close}>
                Cancel
              </Button> */}
          </ModalFooter>
          <div
            className="p-2"
            style={{ maxHeight: "400px", overflowY: "scroll" }}
          >
            <div className="activity-timeline">
              {sowHistory?.length > 0 ? (
                sowHistory?.map((act, key) => {
                  return (
                    <>
                      <div className="activityboxes">
                        <p className="action-title">{act?.action}</p>
                        <label key={key}>
                          {" "}
                          {act?.action}
                          {act?.fromDate && act?.toDate
                            ? " from " +
                              moment(act.fromDate).format("MMMM D, YYYY") +
                              " to " +
                              moment(act.toDate).format("MMMM D, YYYY")
                            : ""}
                          on {moment(act?.time).format("LLL")}
                          <br></br>
                          {act?.rejectReason?.length > 0 && (
                            <span>Remarks: {act?.rejectReason}</span>
                          )}
                        </label>
                      </div>
                    </>
                  );
                })
              ) : (
                <p></p>
              )}
            </div>
          </div>
        </Modal>
      )}

      {(sowData?.status === "approved" ||
        sowData?.status === "SOW Uploaded" ||
        sowData?.status === "completed") && (
        <Modal toggle={close} centered isOpen={sowModal}>
          <Row>
            <div className="col">
              <ModalHeader className="text-uppercase font-weight-bolder">
                SOW Document Status
              </ModalHeader>
            </div>
            <Row>
              <Col>
                <ModalHeader className="text-uppercase">
                  <Button onClick={close} className="mx-1" close />
                </ModalHeader>
              </Col>
            </Row>
          </Row>
          <ModalBody>
            <FormGroup row style={{ justifyContent: "center" }}>
              <div
                className="d-flex p-3"
                style={{ border: "1px solid #cecece", borderRadius: "10px" }}
              >
                <img
                  alt=""
                  src={downloadImg}
                  style={{ width: "40px", height: "40px", marginRight: "10px" }}
                />
                <div style={{ display: "grid" }}>
                  {sowData?.sowSignedDocument && (
                    <a
                      href={baseURL + "/" + sowData?.sowSignedDocument}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download SOW
                    </a>
                  )}
                  {sowData?.sowSignedDocument && (
                    <span
                      style={{
                        fontSize: sowData?.sowSignedDocument ? "12px" : "",
                      }}
                    >
                      Previous version
                      <a
                        style={{ marginLeft: "6px" }}
                        href={baseURL + "/" + sowData?.sowDocument}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        click here
                      </a>
                    </span>
                  )}
                  {!sowData?.sowSignedDocument && (
                    <a
                      style={{ display: "grid", alignItems: "center" }}
                      href={baseURL + "/" + sowData?.sowDocument}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download SOW
                    </a>
                  )}
                </div>
              </div>
            </FormGroup>
          </ModalBody>
          <div
            className="p-2"
            style={{ maxHeight: "400px", overflowY: "scroll" }}
          >
            <div className="activity-timeline">
              {sowHistory?.length > 0 ? (
                sowHistory?.map((act, key) => {
                  return (
                    <>
                      <div className="activityboxes">
                        <p className="action-title">{act?.action}</p>
                        <label key={key}>
                          {" "}
                          {act?.action}
                          {act?.fromDate && act?.toDate
                            ? " from " +
                              moment(act.fromDate).format("MMMM D, YYYY") +
                              " to " +
                              moment(act.toDate).format("MMMM D, YYYY")
                            : " "}
                          on {moment(act?.time).format("LLL")}
                          <br></br>
                          {act?.rejectReason?.length > 0 && (
                            <span>Remarks: {act?.rejectReason}</span>
                          )}
                        </label>
                      </div>
                    </>
                  );
                })
              ) : (
                <p></p>
              )}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
