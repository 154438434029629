import PaginationComponent from "components/pagination/Pagination";
import CompanySearchBar from "components/SearchBars/CompanySearchBar";
import SearchDate from "components/SearchBars/SearchDate";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Row, Col } from "reactstrap";
import { setCompanyApprovalInfo } from "redux/actions/super-admin/CompanyApprovalAction";
import ComapnyApprovalCard from "./ComapnyApprovalCard";

function CompanyApprovalList({
  handleEmployeeSearchOnChange,
  handleSearch,
  searchFormData,
  setSearchFormData,
  data,
}) {
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  useEffect(() => {
    setTitle(data);

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const fetchResultApprovalListRequests = useSelector(
    (state) => state.CompanyApprovalReducer.result
  );
  const fetchResultApprovalListRequestsLoader = useSelector(
    (state) => state.CompanyApprovalReducer.loader
  );
  const handlePagination = (tab) => {
    // setSearchFormData({
    //   ...searchFormData,
    //   page: tab,
    // });
    const finalData = {
      ...searchFormData,
      page: tab,
    };

    dispatch(setCompanyApprovalInfo("pending", finalData));
  };
  return (
    <>
      <Row className="mb-2 ">
        <Col md="12">
          <h3 class="tab-title">{title}</h3>
        </Col>
        <Col lg="7" md="12">
          <CompanySearchBar
            handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
            handleSearch={handleSearch}
          />
        </Col>
        <Col lg="5" md="12">
          <SearchDate
            handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
            handleSearch={handleSearch}
          />
        </Col>
      </Row>

      <ComapnyApprovalCard loader={fetchResultApprovalListRequestsLoader} />

      <div
        style={{
          display: fetchResultApprovalListRequestsLoader ? "none" : "contents",
        }}
      >
        <PaginationComponent
          totalCount={fetchResultApprovalListRequests?.itemCount}
          handlePagination={handlePagination}
          activePage={fetchResultApprovalListRequests?.activePage}
        />
      </div>
      {/* {!fetchResultApprovalListRequestsLoader && 
        <PaginationComponent
        totalCount={fetchResultApprovalListRequests?.itemCount}
        handlePagination={handlePagination}
      />} */}
    </>
  );
}

export default CompanyApprovalList;
