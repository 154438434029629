/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import PaginationComponent from "components/pagination/Pagination";
import pendingReq from "../../../../assets/img/icons/customIcons/filter.svg";
import PreClosureApprovedCard from "./PreClosureApprovedCard";
import { getPendingPreClosureRequestsInfo } from "redux/actions/admin/PendingPreClosureAction";
import SearchIcon from "../../../../assets/img/icons/customIcons/searchicondark.svg";
import CustomFilter from "components/customFilter";

function PreClosureApprovedList({
  handleEmployeeSearchOnChange,
  handleSearch,
  searchFormData,
  setSearchFormData,
  data,
  toggle,
  handleClearDate,
  list,
  handleSearchOnSelect,
}) {
  const dispatch = useDispatch();
  const [showToggle, setShowToggle] = useState(false);
  const [tableHeight, setTableHeigth] = useState(300);

  const approvedPreClosureResult = useSelector(
    (state) => state.PreClosureRequestReducer.result
  );
  const approvedPreClosureLoader = useSelector(
    (state) => state.PreClosureRequestReducer.loader
  );

  const [title, setTitle] = useState("PreClosure approved");
  const [isFilter, setIsFilter] = useState(false);

  useEffect(() => {
    setTitle(data);

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handlePagination = (tab) => {
    const finalData = {
      ...searchFormData,
      page: tab,
    };
    dispatch(
      getPendingPreClosureRequestsInfo("1", "1", finalData, "", "approved")
    );
  };
  useEffect(() => {
    const divElement = document.getElementById("tableDiv");
    if (approvedPreClosureResult?.pages?.length > 1) {
      setTableHeigth(divElement.scrollHeight - 72 + "px");
    } else {
      setTableHeigth(divElement.scrollHeight - 12 + "px");
    }
  });

  return (
    <div className="d-flex">
      <Col id="tableDiv" md={isFilter ? "9" : "12"}>
        <Row className="mb-2">
          <Col md="10">
            <h3 className="tab-title">
              {`${title} (${
                approvedPreClosureResult?.itemCount
                  ? approvedPreClosureResult?.itemCount
                  : "0"
              })`}{" "}
            </h3>
          </Col>

          <Col md="2" style={{ display: "flex", justifyContent: "flex-end" }}>
            <div className="common-outer-filter">
              {list && (
                <div className="btn-group new-cutom-filter ">
                  <button
                    type="button"
                    onClick={() => setShowToggle(!showToggle)}
                    className={"btn dropdown-toggle mr-3"}
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img alt="" src={pendingReq} width={"18px"} />
                  </button>
                  <div className="dropdown-menu dropdown-menu-right py-0">
                    {list?.map((item, key) => (
                      <button
                        style={{
                          backgroundColor:
                            item?.tabId === "2" ? "#5e72e4" : "none",
                          color: item?.tabId === "2" ? "white" : "none",
                        }}
                        key={key}
                        className="dropdown-item"
                        onClick={() =>
                          toggle({ target: { value: item?.tabId } })
                        }
                        type="button"
                      >
                        {item?.title}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="common-outer-filter">
              <div className="btn-group new-cutom-filter ">
                <button
                  type="button"
                  onClick={() => setIsFilter(!isFilter)}
                  className={"btn dropdown-toggle mr-3"}
                >
                  <img alt="" src={SearchIcon} width={"18px"} />
                </button>
              </div>
            </div>
          </Col>
        </Row>

        <PreClosureApprovedCard loader={approvedPreClosureLoader} />
        <div
          style={{
            display: approvedPreClosureLoader ? "none" : "contents",
          }}
        >
          <PaginationComponent
            totalCount={approvedPreClosureResult?.itemCount}
            handlePagination={handlePagination}
            activePage={approvedPreClosureResult?.activePage}
          />
        </div>
      </Col>
      {/* {isFilter && ( */}
        <Col
          md="3"
          style={{
            height: tableHeight,
            minHeight: "670px",
            display: isFilter ? "block" : "none",
          }}
        >
          <CustomFilter
            tableHeight={tableHeight}
            minHeight={"670px"}
            handleSearchOnSelect={handleSearchOnSelect}
            handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
            handleSearch={handleEmployeeSearchOnChange}
            type="outbound"
            list={list}
            isFilter={isFilter}
            setIsFilter={setIsFilter}
            handleClearDate={handleClearDate}
          />
        </Col>
      {/* )} */}
    </div>
  );
}

export default PreClosureApprovedList;
