/* eslint-disable eqeqeq */
export const formatDate = (date)  => {
    
      const  d = new Date()
    
    
       let month = '' + (d.getMonth() + 1)
       let day = '' + d.getDate()
       let year = d.getFullYear()
console.log("MONTH:-", month,"DAY:-", day,"YEAR:-",year)
       if(date){
        if(month == 12){
            month = 1;
            year = '' + (d.getFullYear() + 1)
        }else{
            month = '' + (d.getMonth() + 2)     
        }
      }

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}