export const getSearchUrl = (url, searchData) => {
  if (searchData) {
    if (searchData?.fromDate && searchData?.toDate) {
      url =
        url + `&fromDate=${searchData?.fromDate}&toDate=${searchData?.toDate}`;
    }
    if (searchData?.experience) {
      if (searchData?.experience?.includes("Above")) {
        url = url + `&rangeStart=10`;
      } else {
        let value = (searchData?.experience).split("-");
        console.log(value);
        url = url + `&rangeStart=${value[0]}&rangeEnd=${value[1]}`;
      }
    }
    if (searchData?.search) {
      url = url + `&search=${searchData?.search}`;
    }
    if (searchData?.page) {
      url = url + `&page=${searchData?.page}`;
    }
    if (searchData?.limit) {
      url = url + `&limit=${searchData?.limit}`;
    }
    if (searchData?.parentCompany) {
      url = url + `&parentCompany=${searchData?.parentCompany}`;
    }
    if (searchData?.bookedCompany) {
      url = url + `&bookedCompany=${searchData?.bookedCompany}`;
    }
    if (searchData?.companyName) {
      url = url + `&companyName=${searchData?.companyName}`;
    }
    if (searchData?.name) {
      url = url + `&name=${searchData?.name}`;
    }
  }

  return url;
};

export const site_name = "ScaleUpHQ";
export const MARKETING_WEBSITE = "https://scaleuphq.io";

export const experienceArray = [
  { value: "0", label: "0-1" },
  { value: "1", label: "1-3" },
  { value: "2", label: "3-6" },
  { value: "3", label: "6-10" },
  { value: "4", label: "Above 10" },
];
