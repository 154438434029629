import { axiosAccountInstance } from "api/Request";
import { getSearchUrl } from "helpers/utils";
// import { getTokenValidity } from "helpers/getTokenValidity";

export const getRejectedRequestsInfo =
  (createdBy, page, status, searchData, companyId) => (dispatch) => {
    let url = `/booking/approval_requests?createdBy=${createdBy}&status=${status}`;
  
    if(companyId){
      url = url + `&companyId=${companyId}`
    }
    url = getSearchUrl(url, searchData);
    dispatch({
      type: "REJECTED_REQUEST_LIST_BEGINS",
    });

    axiosAccountInstance
      .get(url)
      .then(
        (result) => {
          dispatch({
            type: "REJECTED_REQUEST_LIST_SUCCESS",
            payload: result.data.body.data,
          });
        }

        // (err) => {
        //   if (err.data.statusCode === 400) {
        //     toast.error("No Data Found");
        //     dispatch({
        //       type: "REJECTED_REQUEST_LIST_FAILURE",
        //       payload: "FALIURE",
        //     });
        //   }

        //   // const error = err.response
        //   // if(error.status === 401 && error.data.body.message === 'Unauthorized/Token_expired '){
        //   //   getTokenValidity(error.config)
        //   // }else{
        //   //   console.log("error couldnot be solved")
        //   // }
        // }
      )
      .catch((err) => {
        dispatch({
          type: "REJECTED_REQUEST_LIST_FAILURE",
          payload: "FALIURE",
        });
      });
  };
