// import { axiosAccountInstance } from "api/Request";
import { axiosBaseInstance } from "api/Request";
import jwt_decode from "jwt-decode";
import history from "./History";
import { fetchRefreshToken, fetchToken, fetchUserData } from "./storageData";


export const getTokenValidity = async( ) => {
  
    const token  = fetchToken()
    const refreshToken = fetchRefreshToken()
    let newDate = new Date();
    newDate = newDate.setTime(new Date().getTime() + (90 * 1000))
    let finalToken = ''
    if(token){
      var decoded = jwt_decode(token);
      var decodedR = jwt_decode(refreshToken)
      const user = fetchUserData()
        if (user) {
          if(decodedR.exp * 1000 < Date.now()){
           
            localStorage.clear()
            history.push('/auth/login')
            finalToken = '';
          }
          if (decoded.exp * 1000 < newDate) {
         
     
            const data = {
              email:user?.email,
              refreshToken:refreshToken
            }
            await axiosBaseInstance.post('/refreshtoken', data)
            .then(result =>{
              // let payloadData;
              const accesToken = result.data.body.data.token;
              const refreshToken = result.data.body.data.refreshToken;
              localStorage.setItem("Token", accesToken);
              localStorage.setItem("Refresh_Token", refreshToken);
          
              finalToken = accesToken;

              // if required in feature
             
              // if(dispatchAction){
              //  axiosAccountInstance(originalRequest)
              //  .then(res => {
              //     switch (dispatchAction) {
              //       case 'EMPLOYEE_LIST_SUCCESS':
              //       case 'EMPLOYEE_BOOKING_HISTORY_LIST_BEGINS':
              //         payloadData = res.data.body.data;
              //         break;
              //       case 'EMPLOYEE_DELETE_SUCCESS':
              //         payloadData = data;
              //         break;
              //       default:
              //         break;
              //     }
              //  })
              //  dispatchInstance({
              //   type: `${dispatchAction}`,
              //   payload: payloadData,
              //  })
              // }
            })
            .catch(err =>{
              console.log("ERROR OCCURED",err)
              finalToken = ''
            })
          }
          else{
            // token not expired 
            finalToken = token
          }
          
        }
        
    }

    return finalToken
   
}