import React, { useEffect, useState } from "react";
import PendingListCard from "./PendingListCard";
import { Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import PaginationComponent from "components/pagination/Pagination";
import { setPendingRecentBooking } from "redux/actions/super-admin/PendingRecentBookingsAction";
import pendingReq from "../../../../../assets/img/icons/customIcons/pending-req.svg";

function PendingList({
  handleEmployeeSearchOnChange,
  handleSearch,
  searchFormData,
  setSearchFormData,
  data,
  companyId,
  toggle,
  list,
  handleClearDate,
}) {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [showToggle, setShowToggle] = useState(false);

  const fetchResultPendingListRequests = useSelector(
    (state) => state.PendingRecentBookingReducer.result
  );

  const fetchResultPendingListRequestsLoader = useSelector(
    (state) => state.PendingRecentBookingReducer.loader
  );

  useEffect(() => {
    setTitle(data);

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePagination = (tab) => {
    const finalData = {
      ...searchFormData,
      page: tab,
    };

    dispatch(
      setPendingRecentBooking("1", "1", "pending", finalData, companyId)
    );
  };
  return (
    <>
      <Row className="mb-2">
        <Col md="12">
          <h3 className="tab-title">{`${title} (${
            fetchResultPendingListRequests?.itemCount
              ? fetchResultPendingListRequests?.itemCount
              : "0"
          })`}</h3>
        </Col>
        {/* <Col lg="7" md="12">
          <AnimatedSearchBar
            handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
            handleSearch={handleSearch}
          />
        </Col> */}
        <Col lg="5" md="12">
          <div className="common-outer-filter">
            {list && (
              <div className="btn-group new-cutom-filter ">
                <button
                  type="button"
                  onClick={() => setShowToggle(!showToggle)}
                  className={"btn dropdown-toggle mr-3"}
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img alt="" src={pendingReq} width={"18px"} />
                </button>
                <div
                  className={`dropdown-menu dropdown-menu-right  ${
                    showToggle ? "show" : ""
                  }`}
                >
                  {list?.map((item, key) => (
                    <button
                      key={key}
                      className="dropdown-item"
                      onClick={() => toggle({ target: { value: item?.tabId } })}
                      type="button"
                    >
                      {item?.title}
                    </button>
                  ))}
                </div>
              </div>
            )}
            {/* <SearchDate
              handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
              handleSearch={handleSearch}
              clearDate={handleClearDate}
            /> */}
          </div>
        </Col>
      </Row>

      <PendingListCard
        loader={fetchResultPendingListRequestsLoader}
        fromSuperAdmin={true}
      />

      <div
        style={{
          display: fetchResultPendingListRequestsLoader ? "none" : "contents",
        }}
      >
        <PaginationComponent
          totalCount={fetchResultPendingListRequests?.itemCount}
          handlePagination={handlePagination}
          activePage={fetchResultPendingListRequests?.activePage}
        />
      </div>
    </>
  );
}

export default PendingList;
