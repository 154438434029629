const PendingSOWOutboundReducer = (
  state = { loader: false, result: [], error: null },
  action
) => {
  switch (action.type) {
    case "PENDING_SOW_OUTBOUND_LIST_BEGINS":
      return {
        loader: true,
        result: state.result,
        error: null,
      };
    case "PENDING_SOW_OUTBOUND_LIST_SUCCESS":
      return {
        loader: false,
        result: action.payload,
        error: null,
      };
    case "PENDING_SOW_OUTBOUND_LIST_FAILURE":
      return {
        loader: false,
        result: {},
        error: action.payload,
      };

    case "PENDING_SOW_OUTBOUND_DELETE_SUCCESS": {
      let currentPendingSowOutboundList = { ...state.result };
      let pendingSOWOutboundList =
        currentPendingSowOutboundList && currentPendingSowOutboundList.booking;
      pendingSOWOutboundList =
        pendingSOWOutboundList &&
        pendingSOWOutboundList.filter((item) => item._id !== action.payload);
      currentPendingSowOutboundList = {
        ...currentPendingSowOutboundList,
        booking: pendingSOWOutboundList,
      };
      return {
        loader: false,
        result: currentPendingSowOutboundList,
        error: null,
      };
    }
    case "PENDING_SOW_OUTBOUND_DELETE_RESET":
      return {
        loader: false,
        result: {},
        error: null,
      };
    default:
      return state;
  }
};

export default PendingSOWOutboundReducer;
