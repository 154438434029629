import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
// import { axiosAccountInstance } from "api/Request";
// import { setCompanyApprovalInfo } from "redux/actions/super-admin/CompanyApprovalAction";
import history from "helpers/History";
import Tables from "views/examples/Tables";
import { axiosAccountInstance } from "api/Request";
import toast from "../../../../../components/Toastify";
import { setCompanyApprovedRequestsInfo } from "redux/actions/super-admin/ComapnyApprovedAction";
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import viewIcn from "../../../../../assets/img/icons/customIcons/view.svg";
import deleteIcn from "../../../../../assets/img/icons/customIcons/delete.svg";
function CompanyApprovedListCard({ loader, fromSuperAdmin }) {
  const dispatch = useDispatch();

  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [modal, setModal] = useState(false);
  const [adminModal, setAdminModal] = useState(false);
  const [updatePassword, setUpdatePassword] = useState(true);
  const [errors, setErrors] = useState(null);
  const [adminObj, setAdminObj] = useState({
    firstName: "",
    lastName: "",

    // name: "",
    email: "",
    companyName: "",
    password: "",
    confirmPassword: "",
  });

  const fetchApprovedCompaniesList = useSelector(
    (state) => state?.CompanyApprovedReducer?.result
  );

  const [, setData] = useState([]);

  const viewCompanyHandler = (item) => {
   history.push({pathname:`/admin/company/${item.companyId}`,state:{name:item.companyName}});
    
  };
  const updatePasswordHandler = () => {
    setAdminObj({ ...adminObj, password: "", confirmPassword: "" });
    setUpdatePassword(!updatePassword);
  };

  useEffect(() => {
    setData(fetchApprovedCompaniesList?.companies || []);
  }, [fetchApprovedCompaniesList]);

  const handleOnChange = (e) => {
    setAdminObj({ ...adminObj, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: null });
  };

  const validation = () => {
    const {
      // employeeId,
      email,
      firstName,
      lastName,
      // name,
      companyName,
      password,
      confirmPassword,
    } = adminObj;

    const errorsFound = {};
    // if (!employeeId) errorsFound["employeeId"] = "Oops! please add employeeId";
    if (!email) errorsFound["email"] = "email date is required";
    if (!firstName) errorsFound["firstName"] = "First Name is required";

    const nameRegex = /^[A-Za-z ]{1,50}$/;
    if (!nameRegex.test(firstName)) {
      errorsFound["firstName"] =
        "Allows only alphabetical characters (1-50 characters).";
    }
    if (!nameRegex.test(lastName)) {
      errorsFound["lastName"] =
        "Allows only alphabetical characters (1-50 characters).";
    }
    if (!lastName) errorsFound["lastName"] = "Last Name is required";
    // if (!name) errorsFound["name"] = "name is required";
    if (!companyName) errorsFound["companyName"] = "company name is required";
    if (!updatePassword) {
      if (!password || password === "")
        errorsFound["password"] = "Password is required";
      if (
        !confirmPassword ||
        confirmPassword === "" ||
        confirmPassword !== password
      )
        errorsFound["confirmPassword"] = "Confirm password is required";
    }

    if (Object.keys(errorsFound).length !== 0) {
      setErrors(errorsFound);
      return false;
    }
    return true;
  };

  const onAdminUpdate = async () => {
    const isValid = await validation(adminObj);
    if (isValid) {
      let finalAdminObj;

      finalAdminObj = {
        _id: adminObj?._id,
        // name: adminObj?.name,
        userId:adminObj?.userId,
        firstName: adminObj?.firstName,
        lastName: adminObj?.lastName,
        email: adminObj?.email,
        companyName: adminObj?.companyName,
        password: adminObj?.password,
        confirmPassword: adminObj?.confirmPassword,
      };
      console.log(finalAdminObj);
      axiosAccountInstance
        .post("/superadmin/company/admin/update", finalAdminObj)
        .then((res) => {
          toast.success(res.data.body.message);
          setAdminObj({
            // name: "",
            userId:"",
            firstName: "",
            lastName: "",
            email: "",
            companyName: "",
            password: "",
            confirmPassword: "",
          });
          setAdminModal(false);
          dispatch(setCompanyApprovedRequestsInfo("approved", null));
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.body.message);
        });
    }
  };
  const submit = () => {
    axiosAccountInstance
      .delete(`/superadmin/companydelete/${selectedEmployee}`)
      .then((res) => {
        toast.success(res.data.body.message);
        setModal(!modal);
      })
      .catch((err) => {
        toast.error(err.response.data.body.message);
        dispatch(setCompanyApprovedRequestsInfo("approved", null));
      });
  };

  // Close modal
  const close = () => {
    setModal(false);
    setAdminModal(false);
  };

  const editCompanyStatus = (data) => {
    console.log(data);
    axiosAccountInstance
      .post("/superadmin/companystatusupdate", data)
      .then((res) => {
        if (data?.status === "inactive") {
          toast.success("Company Disabled");
        } else {
          toast.success("Company Enabled");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.body.message);
        dispatch(setCompanyApprovedRequestsInfo("approved", null));
      });
  };

  const deleteCompany = (id) => {
    setSelectedEmployee(id);
    setModal(!modal);
  };
  const viewAdminHandler = (data) => {
    console.log(data);
    setAdminObj({ ...data, password: "", confirmPassword: "" });
    setAdminModal(!adminModal);
  };
  const colums = fromSuperAdmin
    ? [
        { columnName: "Company", columnTag: "companyName", type: "data" },

        // { columnName: "Designation", columnTag: "designation", type: "data" },
        {
          columnName: "Contact Number",
          columnTag: "contactNumber",
          type: "data",
        },
        {
          columnName: "Status",
          type: "toggle",
          btnTitle: "Status",
          btnType: "primary",
          btn: "EmployeeStatus",
          actionFunction: editCompanyStatus,
        },
        { columnName: "", columnTag: "", type: "" },
      ]
    :
     [
        { columnName: "Company", columnTag: "companyName", type: "data" },

        { columnName: "Designation", columnTag: "designation", type: "data" },
        {
          columnName: "Contact Number",
          columnTag: "contactNumber",
          type: "data",
        },
        {
          columnName: "Status",
          type: "toggle",
          btnTitle: "Status",
          btnType: "primary",
          btn: "EmployeeStatus",
          actionFunction: editCompanyStatus,
        },
        { columnName: "", columnTag: "", type: "" },
      ];

  const actionButtons = [
    
    {
      columnName: "",
      type: "actionButton",
      btnTitle: "View",
      btnType: "primary",
      btnIcon: viewIcn,
      toolTip: `Company History`,
      btn: "CompanyHistory",
      btnTooltip: "Company History",
      actionFunction: viewCompanyHandler,
    },
    {
      columnName: "",
      type: "actionButton",
      btnTitle: "Admin",
      btnType: "primary",
      btnIcon: viewIcn,
      toolTip: "Admin Details",
      btn: "AdminDetails",
      btnTooltip: "Admin Details",
      actionFunction: viewAdminHandler,
    },
    {
      columnName: "",
      btnTitle: "Delete",
      type: "actionButton",
      btn: "CompanyDelete",
      btnIcon: deleteIcn,
      toolTip: "Delete",
      btnTooltip: "Delete",
      btnType: "danger",
      actionFunction: deleteCompany,
    },
  ];
  console.log(
    "data-----------",
    fetchApprovedCompaniesList?.companies,
    "modallll",
    adminObj
  );
  return (
    <div>
      <Tables
        actions={null}
        coloums={colums}
        data={fetchApprovedCompaniesList?.companies}
        actionButtons={actionButtons}
        loader={loader}
      />

      <Modal toggle={close} size="sm" centered isOpen={modal}>
        <Row>
          <div className="col">
            <ModalHeader className="text-uppercase font-weight-bolder">
              Delete company
            </ModalHeader>
          </div>
          <Row>
            <Col>
              <ModalHeader className="text-uppercase">
                <Button onClick={close} className="mx-1" close />
              </ModalHeader>
            </Col>
          </Row>
        </Row>
        <ModalBody>
          <label className="font-weight-normal mb-0">
            Do you want to delete the company?
          </label>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" onClick={() => submit()} color="danger">
            Yes
          </Button>{" "}
          {/* <Button size="sm" onClick={close}>
                      Cancel
                    </Button> */}
        </ModalFooter>
      </Modal>

      {/* ADMIN VIEW/EDIT MODAL */}
      <Modal toggle={close} centered isOpen={adminModal}>
        <Row>
          <div className="col">
            <ModalHeader className="text-uppercase font-weight-bolder">
              {" "}
              Admin details
            </ModalHeader>
          </div>

          <Row>
            <Col>
              <ModalHeader className="text-uppercase d-flex">
                <Button
                  color="primary"
                  onClick={updatePasswordHandler}
                  size="sm"
                >
                  {updatePassword ? "Update Password" : "Close"}
                </Button>
                <Button onClick={close} className="mx-1" close />
              </ModalHeader>
            </Col>
          </Row>
        </Row>
        <ModalBody>
          <FormGroup row>
            <Label for="name" sm={12}>
              First Name
            </Label>
            <Col sm={12}>
              <Input
                id="name"
                name="firstName"
                type="text"
                value={adminObj.firstName}
                onChange={(e) => handleOnChange(e)}
                invalid={!!errors && !!errors.firstName}
              />
              <FormFeedback className={errors?.firstName && "invalid-field"}>
                {errors?.firstName}
              </FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="name" sm={12}>
              Last Name
            </Label>
            <Col sm={12}>
              <Input
                id="name"
                name="lastName"
                type="text"
                value={adminObj.lastName}
                onChange={(e) => handleOnChange(e)}
                invalid={!!errors && !!errors.lastName}
              />
              <FormFeedback className={errors?.lastName && "invalid-field"}>
                {errors?.lastName}
              </FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="email" sm={12}>
              Email
            </Label>
            <Col sm={12}>
              <Input
                id="email"
                name="email"
                type="email"
                value={adminObj.email}
                onChange={(e) => handleOnChange(e)}
                invalid={!!errors && !!errors.email}
              />
              <FormFeedback className={errors?.email && "invalid-field"}>
                {errors?.email}
              </FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="companyName" sm={12}>
              Company Name
            </Label>
            <Col sm={12}>
              <Input
                id="companyName"
                name="companyName"
                type="text"
                value={adminObj.companyName}
                onChange={(e) => handleOnChange(e)}
                invalid={!!errors && !!errors.companyName}
              />
              <FormFeedback className={errors?.companyName && "invalid-field"}>
                {errors?.companyName}
              </FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="password" sm={12}>
              Password
            </Label>
            <Col sm={12}>
              <Input
                id="password"
                name="password"
                type="password"
                disabled={updatePassword}
                value={adminObj.password}
                onChange={(e) => handleOnChange(e)}
                invalid={!!errors && !!errors.password}
              />
              <FormFeedback className={errors?.password && "invalid-field"}>
                {errors?.password}
              </FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="password" sm={12}>
              Confirm Password
            </Label>
            <Col sm={12}>
              <Input
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                disabled={updatePassword}
                value={adminObj.confirmPassword}
                onChange={(e) => handleOnChange(e)}
                invalid={!!errors && !!errors.confirmPassword}
              />
              <FormFeedback
                className={errors?.confirmPassword && "invalid-field"}
              >
                {errors?.confirmPassword}
              </FormFeedback>
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="pt-0">
          <Button
            className="mx-auto"
            size="sm"
            onClick={onAdminUpdate}
            color="primary"
          >
            Update
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default CompanyApprovedListCard;
// export  {setCompanyName}