import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button, Spinner } from "reactstrap";
import history from "helpers/History";
import HistoryCard from "./HistoryCard";
import { useDispatch, useSelector } from "react-redux";
import { setEmployeeBookingHistoryListInfo } from "redux/actions/super-admin/EmployeeListAction";
import PaginationComponent from "components/pagination/Pagination";
import DashboardHeader from "components/Headers/DashboardHeader";
import { useLocation } from "react-router-dom";

function BookingHistoryList({ match }) {
  const { employeeId } = match.params;
  const location=useLocation()
  
  const empName= location?.state?.name
  const dispatch = useDispatch();
  const fetchBookingHistoryListData = useSelector(
    (state) => state.EmployeeBookingHistoryListReducer.result
  );
  const fetchBookingHistoryListDataLoader = useSelector(
    (state) => state.EmployeeBookingHistoryListReducer.loader
  );
  // const [searchFormData, setSearchFormData] = useState({
  //   experience: null,
  //   page: null,
  //   fromDate: null,
  //   toDate: null,
  //   search: null,
  //   limit: null,
  // });

  const [bookingHistoryListData, setBookingHistoryListData] = useState([]);

  // const handleEmployeeSearchOnChange = (e) => {
  //   setSearchFormData({ ...searchFormData, [e.target.name]: e.target.value });
  // };
  // const handleSearch = (e) => {
  //   e.preventDefault();
  //   dispatch(setEmployeeBookingHistoryListInfo(employeeId, searchFormData));
  // };

  const handlePagination = (tab) => {
    // setSearchFormData({
    //   experience: null,
    //   // page: tab,
    //   fromDate: null,
    //   toDate: null,
    //   search: null,
    //   limit: null,
    // });
    const finalData = {
      // ...searchFormData,
      page: tab,
    };

    dispatch(setEmployeeBookingHistoryListInfo(employeeId, finalData));
  };

  useEffect(() => {
    dispatch(setEmployeeBookingHistoryListInfo(employeeId));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fetchBookingHistoryListData) {
      // console.log(
      //   fetchBookingHistoryListData?.bookingHistory?.length  >0 && 
      //   fetchBookingHistoryListData?.bookingHistory[0]
      //   )


      setBookingHistoryListData(fetchBookingHistoryListData.bookingHistory);
    }
    return () => {
      setBookingHistoryListData([]);
    };
  }, [fetchBookingHistoryListData]);

  const goBack = () => {
    history.push("/admin/list-employees")
  }

  return (
    <>
      <DashboardHeader />
      <Container className="mt-4 main-contentarea container-fluid" fluid>
        <Row className="search-total mt-2 mb-2">
          <Col lg="7" md="12" className="text-capitalize font-weight-bold ">
            Employee: {empName ? empName : <Spinner size={"sm"} />}
          </Col>
          <Col lg="5" md="12" className="common-outer-filter">
            <Button
              color="primary"
              onClick={goBack}
              size="sm"
            >
              <i class="fa fa-times"></i>
            </Button>
          </Col>

          {/* <Col lg="5" md="12" className="common-outer-filter">
            <SearchDate
              handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
              handleSearch={handleSearch}
            />
          </Col> */}
        </Row>

        <HistoryCard bookingHistoryListData={bookingHistoryListData} />
        <div
          style={{
            display: fetchBookingHistoryListDataLoader ? "none" : "contents",
          }}
        >
          <PaginationComponent
            totalCount={fetchBookingHistoryListData?.itemCount}
            handlePagination={handlePagination}
            activePage={fetchBookingHistoryListData?.activePage}
          />
        </div>
        {/* {!fetchBookingHistoryListDataLoader &&   <PaginationComponent
          totalCount={fetchBookingHistoryListData?.itemCount}
          handlePagination={handlePagination}
        />} */}
      </Container>
    </>
  );
}

export default BookingHistoryList;
