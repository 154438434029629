import { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { authRequest } from "api/Request";
import signupImg from "../../assets/img/new/signUp.jpeg";

import logobox from "../../assets/img/icons/customIcons/logo.svg";
import adminDetailImg from "../../assets/img/new/adminDetail.jpg";
import finalImg from "../../assets/img/new/emailVerified.jpg";
import verifyEmailImg from "../../assets/img/new/verifyEmail.jpg";
// Third Party Imports

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  FormFeedback,
  Label,
  Spinner,
} from "reactstrap";

// Component Imports
// import EmployeeDetail from "./users/EmployeeDetail";

import countryList from "react-select-country-list";
import Select from "react-select";

import PhoneNumberComponent from "components/phoneNumberInput/PhoneNumberComponent";
import { MARKETING_WEBSITE } from "helpers/utils";
// import AutoSearch from "components/auto-search/AutoSearch";

const Register = () => {
  const [address, setAddress] = useState("");
  // const [country, setCountry] = useState("");
  const [value, setValue] = useState("");
  const options = useMemo(() => countryList().getData(), []);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  // const [companyId, setCompanyId] = useState("");
  const [registerNumber, setRegisterNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  // const [gstNumber, setGstNumber] = useState("");

  const [errors, setErrors] = useState(null);
  const [countryValidation, setcountryValidation] = useState("");
  const [companyNameValidation, setcompanyNameValidation] = useState("");
  const [companyDomain, setCompanyDomain] = useState("");

  const [disable, setDisable] = useState(false);
  const [isCompanyNameVerified, setIsCompanyNameVerified] = useState(false);
  const [isCompanyDomainVerified, setIsCompanyDomainVerified] = useState(false);
  const [registrationSteps, setRegistrationSteps] = useState(1);
  // const data = {
  //   companyName: companyName,
  //   country: value,
  //   address: address,
  //   // companyId: companyId,
  //   registerNumber,
  //   // gstNumber
  //   domain: companyDomain,
  // };
  const [companyNameError, setCompanyNameError] = useState("");

  const [domainError, setDomainError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [designation, setDesignation] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isInvalidPhone, setIsInvalidPhone] = useState(null);
  const [showSpinnerCompanyName, setShowSpinnerCompanyName] = useState(false);
  const [showSpinnerDomain, setShowSpinnerDomain] = useState(false);

  // const [errors, setErrors] = useState(null);

  const phonenumberInput = (e) => {
    setMobileNumber(e);
    setErrors({ ...errors, mobileNumber: null });
  };
  const validation = () => {
    const errorsFound = {};
    // if (!fullName || fullName === "")
    //   errorsFound["fullName"] = "Name is required";

    // if (fullName.length < 3) {
    //   errorsFound["fullName"] = "Name should be atleast 3 characters ";
    // }
    if (!firstName || firstName === "")
    errorsFound["firstName"] = "First Name is required";
    const nameRegex = /^[A-Za-z ]{1,50}$/;
    if (!nameRegex.test(firstName)) {
      errorsFound["firstName"] =
        "Allows only alphabetical characters (1-50 characters).";
    }
    if (!nameRegex.test(lastName)) {
      errorsFound["lastName"] =
        "Allows only alphabetical characters (1-50 characters).";
    }

  if (firstName.length < 3) {
    errorsFound["firstName"] = "First Name should be atleast 3 characters ";
  }

    if (!lastName || lastName === "")
    errorsFound["lastName"] = "Last Name is required";

  //  if (lastName.length < 3) {
  //   errorsFound["lastName"] = "Last Name should be atleast 3 characters ";
  // }

    if (mobileNumber && isInvalidPhone)
      errorsFound["mobileNumberVal"] = "Mobile number is invalid";

    if (mobileNumber.length < 7) {
      errorsFound["mobileNumberVal"] =
        "Mobile number should be atleast 7 digits";
    }

    if (!designation || designation === "")
      errorsFound["designation"] = "Designation is required";
    if (!mobileNumber || mobileNumber === "")
      errorsFound["mobileNumber"] = "Mobile number is required";
    if (mobileNumber && isInvalidPhone)
      errorsFound["mobileNumberVal"] = "Mobile number is invalid";

    if (!email || email === "")
      errorsFound["email"] = "Email address is required";
    if (
      email.length > 0 &&
      emailValidation(email + "@" + companyDomain) === false
    ) {
      errorsFound["email"] = "Please enter a valid email address";
    }

    if (email.length < 3) {
      errorsFound["email"] = "Email address should be atleast 3 characters";
    }

    if (!password || password === "")
      errorsFound["password"] = "Password is required";

    if (password.length < 3) {
      errorsFound["password"] = "Password should be atleast 3 characters ";
    }

    if (
      !confirmPassword ||
      confirmPassword === "" ||
      confirmPassword !== password
    )
      errorsFound["confirmPassword"] = "Confirm password is required";

    if (Object.keys(errorsFound).length !== 0) {
      setErrors(errorsFound);
      return false;
    }
    return true;
  };

  const emailValidation = (data) => {
    const regex =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    if (!data || regex.test(data) === false) {
      setErrors({ ...errors, email: "Please enter a valid email address" });
      return false;
    } else {
      setErrors({ ...errors, email: "" });
      return true;
    }
  };

  const handleMobileValidation = (values, countrys) => {
    if (values.length > 2) {
      if (countrys.format.match(/\./g).length === values.length) {
        setIsInvalidPhone(false);
        return true;
      } else {
        setIsInvalidPhone(true);
        return false;
      }
    }
  };
  const passwordValidation = (data) => {
    if (!data || data === "" || data !== password) {
      setErrors({
        ...errors,
        confirmPassword: "Confirm password doesn't match",
      });
    }
  };

  const registerHandler = async (e) => {
    e.preventDefault();

    const isValid = validation();

    if (isValid) {
      setDisable(true);
      await authRequest({
        method: "POST",
        url: "/companycreate",
        data: {
          companyName: companyName,
          countryCode: value,
          address: address,
          firstName:firstName,
          lastName:lastName,
          // name: fullName,
          email: email + "@" + companyDomain,
          designation: designation,
          password: password,
          companyId: null,
          contactNumber: mobileNumber,
          passwordConfirmation: confirmPassword,
          registerNumber: registerNumber,
          gstNumber: null,
          domain: companyDomain,
        },
      })
        .then(function (result) {
          setDisable(false);
          // toast.success(result.body.message);
          setRegistrationSteps(registrationSteps + 1);
          // history.push("/auth/login");
        })
        .catch((err) => {
          setDisable(false);
          // toast.error(err.data?.body?.message); // Error: "Promise rejected"
        });
    }
  };

  const changeHandler = (value) => {
    setValue(value.value);
    setcountryValidation(false);
  };
  const companyNameHanlder = (value) => {
    setCompanyName(value);

    setcompanyNameValidation(false);
    console.log(companyNameValidation);
  };

  const validationRegister = () => {
    // const [address, setAddress] = useState("");
    // const [country, setCountry] = useState("");
    // const [companyId, setCompanyId] = useState("");
    // const [registerNumber, setRegisterNumber] = useState("");
    // const [companyName, setCompanyName] = useState("");
    // const [gstNumber, setGstNumber] = useState("");

    const errorsFound = {};
    // if (!employeeId) errorsFound["employeeId"] = "Oops! please add employeeId";

    if (!address || address === "")
      errorsFound["address"] = "Address is required";
    if (!companyDomain || companyDomain === "")
      errorsFound["companyDomain"] = "Domain is required";
    if (!value || value === "") {
      setcountryValidation("Country is required");
      errorsFound["value"] = "Oops! please add country";
    }

    // if (!companyId || companyId === "") errorsFound["companyId"] = "Oops! please add companyId";
    if (!registerNumber || registerNumber === "") {
      errorsFound["registerNumber"] = "Registration number is required";
    }

    if (registerNumber.length < 3) {
      errorsFound["registerNumber"] =
        "Registration number should be atleast 3 characters";
    }
    if (!companyName || companyName === "") {
      setcompanyNameValidation("Company name is required");
      errorsFound["companyName"] = "Oops! please add companyName";
    }
    if (companyName.length < 3) {
      setcompanyNameValidation("Company name should be atleast 3 characters");
      errorsFound["companyName"] =
        "Company name should be atleast 3 characters";
    }

    // if (!gstNumber || gstNumber === "")
    //   errorsFound["gstNumber"] = "Oops! please add gstNumber";
    // console.log(errorsFound);
    if (Object.keys(errorsFound).length !== 0) {
      setErrors(errorsFound);
      return false;
    }
    return true;
  };
  const employeeRegistrationSubmitHandler = (e) => {
    e.preventDefault();

    const isValid = validationRegister();
    if (isValid) {
      // setShowEmployeeForm(true);
      setRegistrationSteps(registrationSteps + 1);
    }
  };

  // const renderCompanyList = companyListData.map((item, key) => {
  //   const { companyName, companyId } = item;
  //   console.log(companyName);
  // })

  const validateCompanyName = async () => {
    if (companyName.length < 3) {
      setCompanyNameError("Company name should be atleast 3 characters");
      return;
    }
    setShowSpinnerCompanyName(true);
    await authRequest({
      method: "POST",
      url: "/company-duplication-check",
      data: { companyName },
    })
      .then(function (result) {
        setIsCompanyNameVerified(true);
        setShowSpinnerCompanyName(false);
        // toast.success(result.body.message);
      })
      .catch((err) => {
        setShowSpinnerCompanyName(false);
        setIsCompanyNameVerified(false);
        setCompanyNameError(err.data?.body?.message);
        // toast.error(err.data?.body?.message); // Error: "Promise rejected"
      });
  };
  const validateCompanyDomain = async () => {
    setShowSpinnerDomain(true);
    await authRequest({
      method: "POST",
      url: "/domain-duplication-check",
      data: { domain: companyDomain },
    })
      .then(function (result) {
        setIsCompanyDomainVerified(true);
        setShowSpinnerDomain(false);
        // toast.success(result.body.message);
      })
      .catch((err) => {
        setIsCompanyDomainVerified(false);
        setShowSpinnerDomain(false);
        setDomainError(err.data?.body?.message);
        // toast.error(err.data?.body?.message); // Error: "Promise rejected"
      });
  };
  const goBack = () => {
    setRegistrationSteps(registrationSteps - 1);
  };
  const navigateToExternalLink = () => {
    window.location.href = MARKETING_WEBSITE;
  };
  return (
    <>
      <Col md="5" className="bg-leftside">
        <div className="login-left">
         
          <img
            alt=""
            src={
              registrationSteps === 1
                ? signupImg
                : registrationSteps === 2
                ? adminDetailImg
                : registrationSteps === 3
                ? verifyEmailImg
                : registrationSteps === 4
                ? finalImg
                : "no img"
            }
          />
        </div>
      </Col>

      {registrationSteps === 1 && (
        <Col md="7" className="bg-rightside">
          <div className="form-wizard">
            {registrationSteps === 1 && (
              <>
                <div className="steps working">
                  <h6>1</h6>
                </div>
                <div className="steps">
                  <h6>2</h6>
                </div>
                <div className="steps">
                  <h6>3</h6>
                </div>
              </>
            )}
          </div>
          <div className="account-switch d-md-block d-none">
            <Link to="/auth/login">
              {/* <small>Back To Login</small> */}
              <Button className="switchbtns ml-3" color="outline-primary">
                Login
              </Button>
            </Link>
          </div>

          <Card className="border-0 formboxes ">
            <CardHeader className="bg-transparent border-0">
              <div className="text-muted  mt-2">
                <p className="title-signup mb-0">Company Registration</p>
                <small className="titile-subtag">
                  Please fill the company details
                </small>
              </div>
            </CardHeader>

            <CardBody className="pt-3">
              <Form role="form">
                <FormGroup>
                  <Label
                    style={{ fontSize: "10px" }}
                    for="employeeName"
                    className="text-uppercase"
                  >
                    Company Name<span style={{ color: "red" }}>*</span>
                  </Label>

                  <InputGroup className="input-group-alternative shadow-none border input-group">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa fa-building"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      style={{ paddingRight: "80px" }}
                      placeholder="Company Name"
                      type="text"
                      value={companyName}
                      onChange={(e) => {
                        companyNameHanlder(e.target.value);
                        setIsCompanyNameVerified(false);
                        setErrors({ ...errors, companyName: null });
                        setCompanyNameError("");
                      }}
                      invalid={!!errors && !!errors.companyName}
                    />

                    {companyName.length > 0 &&
                      isCompanyNameVerified &&
                      !showSpinnerCompanyName && (
                        <span className="check-icons">
                          <i
                            className="fa fa-check"
                            style={{ color: "green" }}
                          ></i>
                        </span>
                      )}

                    {showSpinnerCompanyName && (
                      <span className="check-verify">
                        <Spinner color="primary" size="sm" />
                      </span>
                    )}

                    {companyName.length > 0 &&
                      !isCompanyNameVerified &&
                      !showSpinnerCompanyName && (
                        <span
                          className="check-verify"
                          onClick={validateCompanyName}
                        >
                          Verify
                        </span>
                      )}
                  </InputGroup>
                  {companyNameError && (
                    <span style={{ fontSize: "80%", color: "red" }}>
                      {companyNameError}
                    </span>
                  )}
                  <FormFeedback
                    className={errors?.companyName && "invalid-field"}
                  >
                    {errors?.companyName}
                  </FormFeedback>
                </FormGroup>

                <FormGroup>
                  <Label
                    style={{ fontSize: "10px" }}
                    for="employeeName"
                    className="text-uppercase"
                  >
                    Company Domain
                    <span style={{ color: "red" }}>*</span>
                  </Label>
                  <InputGroup className="input-group-alternative shadow-none border input-group">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText
                        style={{
                          backgroundColor: isCompanyNameVerified
                            ? "transparent"
                            : "#e9ecef",
                        }}
                      >
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      style={{ paddingRight: "80px" }}
                      disabled={!isCompanyNameVerified}
                      placeholder="E.g. google.com"
                      type="text"
                      value={companyDomain}
                      onChange={(e) => {
                        setCompanyDomain(e.target.value);
                        setIsCompanyDomainVerified(false);
                        setErrors({ ...errors, companyDomain: null });
                        setDomainError("");
                      }}
                      invalid={!!errors && !!errors.companyDomain}
                    />
                    {companyDomain.length > 0 &&
                      isCompanyDomainVerified &&
                      !showSpinnerDomain && (
                        <span className="check-icons">
                          <i
                            className="fa fa-check"
                            style={{ color: "green" }}
                          ></i>
                        </span>
                      )}

                    {showSpinnerDomain && (
                      <span className="check-verify">
                        <Spinner color="primary" size="sm" />
                      </span>
                    )}

                    {companyDomain.length > 0 &&
                      !isCompanyDomainVerified &&
                      !showSpinnerDomain && (
                        <span
                          className="check-verify"
                          onClick={validateCompanyDomain}
                        >
                          Verify
                        </span>
                      )}
                  </InputGroup>
                  <FormFeedback
                    className={errors?.companyDomain && "invalid-field"}
                  >
                    {errors?.companyDomain}
                  </FormFeedback>
                  {domainError && (
                    <span style={{ fontSize: "80%", color: "red" }}>
                      {domainError}
                    </span>
                  )}
                </FormGroup>

                <Row>
                  <Col sm="6">
                    <FormGroup>
                      <Label
                        style={{ fontSize: "10px" }}
                        for="employeeName"
                        className="text-uppercase"
                      >
                        Company Registration number
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <InputGroup className="input-group-alternative shadow-none border input-group">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText
                            style={{
                              backgroundColor: isCompanyDomainVerified
                                ? "transparent"
                                : "#e9ecef",
                            }}
                          >
                            <i className="ni ni-hat-3" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          disabled={!isCompanyDomainVerified}
                          placeholder="Registration Number"
                          type="text"
                          value={registerNumber}
                          // onChange={(e) => {
                          //   setRegisterNumber(e.target.value);
                          //   setErrors({ ...errors, registerNumber: null });
                          // }}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Check if the value is alphanumeric
                            if (/^[a-zA-Z0-9]*$/.test(value)) {
                              setRegisterNumber(value);
                              setErrors({
                                ...errors,
                                registerNumber: null,
                              });
                            }
                          }}
                          invalid={!!errors && !!errors.registerNumber}
                        />
                      </InputGroup>
                      <FormFeedback
                        className={errors?.registerNumber && "invalid-field"}
                      >
                        {errors?.registerNumber}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label
                        style={{ fontSize: "10px" }}
                        for="employeeName"
                        className="text-uppercase"
                      >
                        Country<span style={{ color: "red" }}>*</span>
                      </Label>

                      <Select
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            fontSize: "0.875rem",
                            color: "#8898aa",
                            border: "0.5px solid #8898aa",
                            boxShadow: "0px",
                          }),
                        }}
                        isDisabled={!isCompanyDomainVerified}
                        value={options.filter(
                          (option) => option.value === value
                        )}
                        onChange={changeHandler}
                        options={options}
                      />

                      {countryValidation !== "" && (
                        <span style={{ fontSize: "80%", color: "red" }}>
                          {countryValidation}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                </Row>

                <FormGroup>
                  <Label
                    style={{ fontSize: "10px" }}
                    for="employeeName"
                    className="text-uppercase"
                  >
                    Company Address<span style={{ color: "red" }}>*</span>
                  </Label>
                  <InputGroup className="input-group-alternative shadow-none border input-group">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText
                        style={{
                          backgroundColor: isCompanyDomainVerified
                            ? "transparent"
                            : "#e9ecef",

                          paddingTop: "14px",
                          alignItems: "baseline",
                        }}
                      >
                        <i className="fa fa-address-card"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      disabled={!isCompanyDomainVerified}
                      placeholder="Address"
                      type="textarea"
                      value={address}
                      onChange={(e) => {
                        setAddress(e.target.value);
                        setErrors({ ...errors, address: null });
                      }}
                      invalid={!!errors && !!errors.address}
                    />
                  </InputGroup>
                  <FormFeedback className={errors?.address && "invalid-field"}>
                    {errors?.address}
                  </FormFeedback>
                </FormGroup>

                <div className="d-flex align-items-center justify-content-between">
                  <Button
                    disabled={
                      !isCompanyDomainVerified || !isCompanyNameVerified
                    }
                    className="mb-2 border-radius-100"
                    color="primary"
                    onClick={employeeRegistrationSubmitHandler}
                    type="button"
                  >
                    Continue
                  </Button>
                </div>
              </Form>
              <p className="d-md-none d-block already-account">
                Already have an account?{" "}
                <Link className="" to="/auth/login">
                  Signin
                </Link>
              </p>
              {/* <Link className="d-md-none d-block" to="/auth/login">
                Login
              </Link> */}
            </CardBody>
          </Card>
        </Col>
      )}

      {registrationSteps === 2 && (
        <>
          <Col md="7" className="bg-rightside">
            <div className="form-wizard">
              {registrationSteps === 2 && (
                <>
                  <div className="steps completed">
                    <h6>1</h6>
                  </div>
                  <div className="steps working">
                    <h6>2</h6>
                  </div>
                  <div className="steps">
                    <h6>3</h6>
                  </div>
                </>
              )}
            </div>
            <div className="account-switch">
              <Link>
                <Button
                  onClick={goBack}
                  className="switchbtns ml-3"
                  color="outline-primary"
                >
                  Go Back
                </Button>
              </Link>
            </div>
            <Card className="formboxes border-0">
              <CardHeader className="bg-transparent border-0 ">
                <div className="mt-2">
                  <p className="title-signup mb-0">Company Registration</p>
                  <small className="titile-subtag">
                    Please fill the admin details
                  </small>
                </div>
              </CardHeader>
              <CardBody className="pt-3">
                <Form role="form">
                  <FormGroup>
                    <Label
                      style={{ fontSize: "10px" }}
                      for="employeeName"
                      className="text-uppercase"
                    >
                      Email Address<span style={{ color: "red" }}>*</span>
                    </Label>
                    <InputGroup className="input-group-alternative shadow-none border">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        autoComplete="new-email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setErrors({ ...errors, email: null });
                          // emailValidation(e.target.value);
                        }}
                        invalid={!!errors && !!errors.email}
                      />
                      <InputGroupText>@{companyDomain}</InputGroupText>
                    </InputGroup>
                    <FormFeedback className={errors?.email && "invalid-field"}>
                      {errors?.email}
                    </FormFeedback>
                  </FormGroup>
                  <FormGroup className="mb-1">
                    <Row>
                      <Col sm="6">
                        <FormGroup>
                          <Label
                            style={{ fontSize: "10px" }}
                            for="employeeName"
                            className="text-uppercase"
                          >
                            first Name<span style={{ color: "red" }}>*</span>
                          </Label>
                          <InputGroup className="input-group-alternative shadow-none border">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fa fa-user" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="First Name"
                              type="text"
                              value={firstName}
                              onChange={(e) => {
                                setFirstName(e.target.value);
                                setErrors({ ...errors, firstName: null });
                              }}
                              invalid={!!errors && !!errors.firstName}
                            />
                          </InputGroup>
                          <FormFeedback
                            className={errors?.fullName && "invalid-field"}
                          >
                            {errors?.fullName}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label
                            style={{ fontSize: "10px" }}
                            for="employeeName"
                            className="text-uppercase"
                          >
                            last Name<span style={{ color: "red" }}>*</span>
                          </Label>
                          <InputGroup className="input-group-alternative shadow-none border">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fa fa-user" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Last Name"
                              type="text"
                              value={lastName}
                              onChange={(e) => {
                                setLastName(e.target.value);
                                setErrors({ ...errors, lastName: null });
                              }}
                              invalid={!!errors && !!errors.lastName}
                            />
                          </InputGroup>
                          <FormFeedback
                            className={errors?.lastName && "invalid-field"}
                          >
                            {errors?.lastName}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6">
                        <FormGroup>
                          <Label
                            style={{ fontSize: "10px" }}
                            for="employeeName"
                            className="text-uppercase"
                          >
                            designation<span style={{ color: "red" }}>*</span>
                          </Label>
                          <InputGroup className="input-group-alternative shadow-none border">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-hat-3" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Designation"
                              type="text"
                              value={designation}
                              onChange={(e) => {
                                setDesignation(e.target.value);
                                setErrors({ ...errors, designation: null });
                              }}
                              invalid={!!errors && !!errors.designation}
                            />
                          </InputGroup>
                          <FormFeedback
                            className={errors?.designation && "invalid-field"}
                          >
                            {errors?.designation}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label
                            style={{ fontSize: "10px" }}
                            for="employeeName"
                            className="text-uppercase"
                          >
                            mobile number<span style={{ color: "red" }}>*</span>
                          </Label>

                          <PhoneNumberComponent
                            name="mobile"
                            id="mobile"
                            country={"in"}
                            value={mobileNumber}
                            handleChange={phonenumberInput}
                            handleMobileValidation={handleMobileValidation}
                          />
                          <FormFeedback
                            className={
                              (errors?.mobileNumber ||
                                errors?.mobileNumberVal) &&
                              "invalid-field"
                            }
                          >
                            {errors?.mobileNumber || errors?.mobileNumberVal}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="6">
                        <FormGroup>
                          <Label
                            style={{ fontSize: "10px" }}
                            for="employeeName"
                            className="text-uppercase"
                          >
                            password<span style={{ color: "red" }}>*</span>
                          </Label>
                          <InputGroup className="input-group-alternative shadow-none border">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Password"
                              type={isPasswordVisible ? "text" : "password"}
                              autoComplete="new-password"
                              value={password}
                              onChange={(e) => {
                                setPassword(e.target.value);
                                setErrors({ ...errors, password: null });
                              }}
                              invalid={!!errors && !!errors.password}
                            />
                            {password.length > 0 && (
                              <InputGroupAddon>
                                <InputGroupText
                                  onClick={() =>
                                    setIsPasswordVisible(!isPasswordVisible)
                                  }
                                >
                                  <i
                                    className="fa fa-eye"
                                    style={{
                                      paddingTop: "4px",
                                      cursor: "pointer",
                                    }}
                                    aria-hidden="true"
                                  ></i>
                                </InputGroupText>
                              </InputGroupAddon>
                            )}
                          </InputGroup>
                          <FormFeedback
                            className={errors?.password && "invalid-field"}
                          >
                            {errors?.password}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label
                            style={{ fontSize: "10px" }}
                            for="employeeName"
                            className="text-uppercase"
                          >
                            Confirm password
                            <span style={{ color: "red" }}>*</span>
                          </Label>
                          <InputGroup className="input-group-alternative shadow-none border">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Confirm Password"
                              type={
                                isConfirmPasswordVisible ? "text" : "password"
                              }
                              autoComplete="new-password"
                              value={confirmPassword}
                              onChange={(e) => {
                                setConfirmPassword(e.target.value);
                                setErrors({ ...errors, confirmPassword: null });
                                passwordValidation(e.target.value);
                              }}
                              invalid={!!errors && !!errors.confirmPassword}
                            />
                            {confirmPassword.length > 0 && (
                              <InputGroupAddon>
                                <InputGroupText
                                  onClick={() =>
                                    setIsConfirmPasswordVisible(
                                      !isConfirmPasswordVisible
                                    )
                                  }
                                >
                                  <i
                                    className="fa fa-eye"
                                    style={{
                                      paddingTop: "4px",
                                      cursor: "pointer",
                                    }}
                                    aria-hidden="true"
                                  ></i>
                                </InputGroupText>
                              </InputGroupAddon>
                            )}
                          </InputGroup>
                          <FormFeedback
                            className={
                              errors?.confirmPassword && "invalid-field"
                            }
                          >
                            {errors?.confirmPassword}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                  </FormGroup>
                  <div className="">
                    <Button
                      disabled={disable}
                      className="mb-2 border-radius-100"
                      color="primary"
                      onClick={registerHandler}
                      type="button"
                    >
                      {disable ? "Please wait" : "Register"}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </>
      )}

      {registrationSteps === 3 && (
        <>
          <Col md="7" className="bg-rightside">
            <div className="form-wizard">
              {registrationSteps === 3 && (
                <>
                  <div className="steps completed">
                    <h6>1</h6>
                  </div>
                  <div className="steps completed">
                    <h6>2</h6>
                  </div>
                  <div className="steps working">
                    <h6>3</h6>
                  </div>
                </>
              )}
            </div>

            <Card className="formboxes border-0">
              <CardHeader className="bg-transparent border-0 ">
                <div className="mt-2">
                  <p className="title-signup mb-0">Verify Your Email Address</p>
                  <p className="mb-4">
                    Thank you for signing up with us! To complete your
                    registration process, you need to confirm your email
                    address.
                  </p>

                  {/* <small>Back To Login</small> */}
                  <a className="d-block mb-3" href="/auth/login">
                    Back to login
                  </a>

                  {/* <Button
                    className="mb-2 border-radius-100"
                    color="primary"
                    onClick={employeeRegistrationSubmitHandler}
                    type="button"
                  >
                    verify mail
                  </Button> */}
                </div>
              </CardHeader>
            </Card>
          </Col>
        </>
      )}
    </>
  );
};

export default Register;
