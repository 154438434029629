import axios from "axios";
import { getTokenValidity } from "helpers/getTokenValidity";

import { fetchRefreshToken } from "helpers/storageData";
// import { fetchToken } from "helpers/storageData";

export const baseURL = process.env.REACT_APP_API_URL;
// export const baseURL = "http://localhost:3000";
// export const baseURL = process.env.REACT_APP_API_URL;

 const client = axios.create( {
  baseURL,
  
});

export const axiosAccountInstance = axios.create({
  baseURL
})

// intercepting the request
axiosAccountInstance.interceptors.request.use(
 async (request) => {

    const token = await getTokenValidity()
    if(token){
    request.headers = {
      'Content-Type': 'application/json',
      "x-access-token": token ? token : ''
    };
    console.log('env......',process.env)
    return request;
  }
  },
    (error) => Promise.reject(error)
  
);

// failure handling
axiosAccountInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

// api for refresh token
export const axiosBaseInstance = axios.create({
  baseURL
})

// intercepting the request
axiosBaseInstance.interceptors.request.use(
  (request) => {
    request.headers = {
      "Authorization":`Basic ${fetchRefreshToken()}`,
      "Content-Type":"application/json"
    };
    return request;
  },
  (error) => Promise.reject(error)
);

// failure handling
axiosBaseInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

const onSuccess = function (response) {
  // console.debug("Request Successful!", response);
  return response.data;
};

const onError = function (error) {
  // console.error("Request Failed:", error.config);
  // if (error.response) {
  //   console.error("Status:", error.response.status);
  //   console.error("Data:", error.response.data);
  //   console.error("Headers:", error.response.headers);
  // } else {
  //   console.error("Error Message:", error.message);
  // }
  return Promise.reject(error.response || error.message);
};

export const request = function (options) {
  return client(options).then(onSuccess).catch(onError);
};

export const authRequest = function (options) {
  const key = localStorage.getItem("Token");
  options.headers = { "x-access-token": `${key}`, "method":'POST' };
  return client(options).then(onSuccess).catch(onError);
};