import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { baseURL } from "api/Request";
import moment from "moment";
import StyledDropzone from "components/drag-and-drop/DragDropFile";
import downloadImg from "../../assets/img/icons/customIcons/download.gif";
export default function SOWModal({
  isOpen,
  closeModal,
  handleOnSOWRejectChange,
  onApproveSOW,
  onReject,
  sowHistory,
  sowData,
  rejectRequestReason,
  isApproveButtonDisabled,
  errors,
  sowErrors,
  imageSelect,
  companyId,
}) {
  return (
    <>
      {(sowData?.status === "SOW Pending" ||
        sowData?.status === "SOW Uploaded") &&
        sowData?.sowStatus !== "pending" && (
          <Modal toggle={closeModal} centered isOpen={isOpen}>
            <Row>
              <div className="col">
                <ModalHeader className="text-uppercase font-weight-bolder">
                  Approve SOW request
                </ModalHeader>
              </div>
              <Row>
                <Col>
                  <ModalHeader className="text-uppercase">
                    <Button onClick={closeModal} className="mx-1" close />
                  </ModalHeader>
                </Col>
              </Row>
            </Row>

            <ModalBody>
              <FormGroup row style={{ justifyContent: "center" }}>
                <div
                  className="d-flex p-2"
                  style={{ border: "1px solid #cecece", borderRadius: "10px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingRight: "8px",
                    }}
                  >
                    <a
                      style={{ fontSize: "14px" }}
                      href={baseURL + "/" + sowData?.sowDocument}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt=""
                        src={downloadImg}
                        style={{
                          width: "40px",
                          height: "40px",
                          marginRight: "10px",
                        }}
                      />
                      Download SOW
                    </a>
                  </div>
                </div>
              </FormGroup>

              {!companyId && (
                <>
                  <FormGroup row>
                    <Label for="exampleText" sm={12}>
                      Upload signed SOW document
                      <span className="text-danger">*</span>
                    </Label>
                    <Col sm={12}>
                      <StyledDropzone
                        type="doc"
                        onChangeImage={(e) => {
                          imageSelect(e);
                        }}
                      />
                      <FormFeedback
                        className={errors?.sowDocument && "resume-invalid"}
                      >
                        {errors?.sowDocument}
                      </FormFeedback>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for="exampleText" sm={12}>
                      Remarks (required only if rejecting)
                    </Label>
                    <Col sm={12}>
                      <Input
                        id="exampleText"
                        name="text"
                        type="textarea"
                        onChange={handleOnSOWRejectChange}
                        value={rejectRequestReason}
                        invalid={!!errors && !!errors.rejectRequestReason}
                      />
                      <FormFeedback
                        className={
                          sowErrors?.rejectRequestReason && "invalid-field"
                        }
                      >
                        {sowErrors?.rejectRequestReason}
                      </FormFeedback>
                    </Col>
                  </FormGroup>
                </>
              )}
            </ModalBody>
            {!companyId && (
              <ModalFooter>
                <Button
                  size="sm"
                  onClick={() => onApproveSOW()}
                  color="primary"
                  disabled={isApproveButtonDisabled}
                >
                  Approve
                </Button>{" "}
                <Button
                  size="sm"
                  onClick={() => onReject()}
                  color="primary"
                  // disabled={isRejectButtonDisabled}
                >
                  Reject
                </Button>
                {/* <Button size="sm" onClick={close}>
       Cancel
     </Button> */}
              </ModalFooter>
            )}
            <div
              className="p-2"
              style={{ maxHeight: "400px", overflowY: "scroll" }}
            >
              <div className="activity-timeline">
                {sowHistory?.length > 0 ? (
                  sowHistory?.map((act, key) => {
                    return (
                      <>
                        <div className="activityboxes">
                          <p className="action-title">{act?.action}</p>
                          <label key={key}>
                            {" "}
                            {act?.action}
                            {act?.fromDate && act?.toDate
                              ? " from " +
                                moment(act.fromDate).format("MMMM D, YYYY") +
                                " to " +
                                moment(act.toDate).format("MMMM D, YYYY")
                              : " "}
                            on {moment(act?.time).format("LLL")}
                            <br></br>
                            {act?.rejectReason?.length > 0 && (
                              <span>Remarks: {act?.rejectReason}</span>
                            )}
                          </label>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <p></p>
                )}
              </div>
            </div>
          </Modal>
        )}

      {(sowData?.status === "approved" ||
        sowData?.status === "completed" ||
        sowData?.status === "SOW Rejected") && (
        <Modal toggle={closeModal} centered isOpen={isOpen}>
          <Row>
            <div className="col">
              <ModalHeader className="text-uppercase font-weight-bolder">
                SOW STATUS
              </ModalHeader>
            </div>
            <Row>
              <Col>
                <ModalHeader className="text-uppercase">
                  <Button onClick={closeModal} className="mx-1" close />
                </ModalHeader>
              </Col>
            </Row>
          </Row>

          <ModalBody>
            <FormGroup row style={{ justifyContent: "center" }}>
              <div
                className="d-flex p-3"
                style={{ border: "1px solid #cecece", borderRadius: "10px" }}
              >
                <img
                  alt=""
                  src={downloadImg}
                  style={{ width: "40px", height: "40px", marginRight: "10px" }}
                />
                <div style={{ display: "grid" }}>
                  {sowData?.sowSignedDocument && (
                    <a
                      href={baseURL + "/" + sowData?.sowSignedDocument}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download SOW
                    </a>
                  )}
                  {sowData?.sowSignedDocument && (
                    <span
                      style={{
                        fontSize: sowData?.sowSignedDocument ? "12px" : "",
                      }}
                    >
                      Previous version
                      <a
                        style={{ marginLeft: "6px" }}
                        href={baseURL + "/" + sowData?.sowDocument}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        click here
                      </a>
                    </span>
                  )}
                  {!sowData?.sowSignedDocument && (
                    <a
                      style={{ display: "grid", alignItems: "center" }}
                      href={baseURL + "/" + sowData?.sowSignedDocument}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download SOW
                    </a>
                  )}
                </div>
              </div>
            </FormGroup>
          </ModalBody>

          <div
            className="p-2"
            style={{ maxHeight: "400px", overflowY: "scroll" }}
          >
            <div className="activity-timeline">
              {sowHistory?.length > 0 ? (
                sowHistory?.map((act, key) => {
                  return (
                    <>
                      <div className="activityboxes">
                        <p className="action-title">{act?.action}</p>
                        <label key={key}>
                          {" "}
                          {act?.action}
                          {act?.fromDate && act?.toDate
                            ? " from " +
                              moment(act.fromDate).format("MMMM D, YYYY") +
                              " to " +
                              moment(act.toDate).format("MMMM D, YYYY")
                            : " "}
                          on {moment(act?.time).format("LLL")}
                          <br></br>
                          {act?.rejectReason?.length > 0 && (
                            <span>Remarks: {act?.rejectReason}</span>
                          )}
                        </label>
                      </div>
                    </>
                  );
                })
              ) : (
                <p></p>
              )}
            </div>
          </div>
        </Modal>
      )}

      {sowData?.status === "SOW Pending" &&
        sowData?.sowStatus === "pending" && (
          <Modal toggle={closeModal} centered isOpen={isOpen}>
            <Row>
              <div className="col">
                <ModalHeader className="text-uppercase font-weight-bolder">
                  SOW Document Status
                </ModalHeader>
              </div>
              <Row>
                <Col>
                  <ModalHeader className="text-uppercase">
                    <Button onClick={closeModal} className="mx-1" close />
                  </ModalHeader>
                </Col>
              </Row>
            </Row>
            <ModalBody>
              <FormGroup row>
                <Label for="exampleText" sm={12}>
                  No SOW document uploaded
                </Label>
              </FormGroup>
            </ModalBody>
          </Modal>
        )}
    </>
  );
}
