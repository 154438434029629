import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPendingRequestInfo } from "redux/actions/admin/PendingRequestAction";
import DashboardHeader from "components/Headers/DashboardHeader";
import pendingIcon from "../../../assets/img/icons/customIcons/pendingIcon.svg";
import preclosureIcon from "../../../assets/img/icons/customIcons/extend-booking.svg";
import extensionIcon from "../../../assets/img/icons/customIcons/pendingExtn.svg";
import { setCompanyBookingExtentionInfo } from "redux/actions/admin/BookingExtentionRequestAction";
import BookingDetails from "./BookingDetailsContainer";
import BookingExtentionDetails from "./BookingExtentionDetails";
import { getPendingPreClosureRequestsInfo } from "redux/actions/admin/PendingPreClosureAction";
import PreClosureDetails from "./PreClosureDetails";

function AdminTabMainCotainer(state) {
  const notification = useSelector((state) => state.NotificationReducer.result);
  console.log("my tab and tile", state);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(
    state?.location?.state?.tile
      ? state?.location?.state?.tile
      : notification?.tile
      ? notification?.tile
      : "1"
  );
  const getPendingListRequests = (createdBy, page, searchData) => {
    dispatch(setPendingRequestInfo(createdBy, page, searchData));
  };

  const getBookingExtentionList = (
    createdBy,
    page,
    extentionStatus,
    searchData
  ) => {
    dispatch(
      setCompanyBookingExtentionInfo(
        createdBy,
        page,
        extentionStatus,
        searchData
      )
    );
  };

  const getPendingPreClosureListRequests = (
    createdBy,
    page,

    searchData,
    companyId,
    status
  ) => {
    dispatch(
      getPendingPreClosureRequestsInfo(
        createdBy,
        page,

        searchData,
        companyId,
        status
      )
    );
  };

  //All requests

  const toggle = (tab) => {
    if (activeTab !== tab) {
      switch (tab) {
        case "1":
          getPendingListRequests("1", "1");

          break;
        case "2":
          getBookingExtentionList("1", "1", "", null);

          break;
        case "3":
          getPendingPreClosureListRequests("1", "1", null, "", "");
          break;
        default:
          break;
      }

      setActiveTab(tab);
    }
  };
  const data = [                                          //Outbound
    {
      title: "Booking Details",
      tabId: "1",
      count: null,
      icon: pendingIcon,
      color: "blue",
    },
    {
      title: "Extension Requests",
      tabId: "2",
      count: null,
      icon: extensionIcon,
      color: "pink",
    },
    {
      title: "Pre-Closure Requests",
      tabId: "3",
      count: null,
      color: "pink",
      icon: preclosureIcon,
    },
  ];

  useEffect(() => {
    console.log("out If ", notification);
    if (notification?.tile) {
      console.log("in If");
      setActiveTab(notification?.tile);
    }
  }, [notification]);
  return (
    <div>
      <DashboardHeader
        tabCards={data}
        selectedCard={(tabId) => toggle(tabId)}
        activeTab={activeTab}
      />

      {activeTab === "1" && (
        <BookingDetails
          empId={
            state?.location?.state?.empId ? state?.location?.state?.empId : null
          }
        />
      )}

      {activeTab === "2" && (
        <BookingExtentionDetails
          empId={
            state?.location?.state?.empId ? state?.location?.state?.empId : null
          }
        />
      )}

      {activeTab === "3" && (
        <PreClosureDetails
          empId={
            state?.location?.state?.empId ? state?.location?.state?.empId : null
          }
        />
      )}
    </div>
  );
}

export default AdminTabMainCotainer;
