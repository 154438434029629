import { axiosAccountInstance } from "api/Request";
import { getSearchUrl } from "helpers/utils";
// import { getTokenValidity } from "helpers/getTokenValidity";

export const setManagerListInfo = (searchData, companyId) => (dispatch) => {
  let url = `/managerlist?`;
if(companyId){
    url = `/managerlist?companyId=${companyId}`
}
  url = getSearchUrl(url, searchData);
  dispatch({
    type: "MANAGER_LIST_BEGINS",
  });

  axiosAccountInstance
    .get(url)
    .then((result) => {
      // dispatching success
      
      if (result.data.statusCode === 200) {
        return dispatch({
          type: "MANAGER_LIST_SUCCESS",
          payload: result.data.body.data,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: "MANAGER_LIST_FAILURE",
        payload: "FALIURE",
      });
    });
};

