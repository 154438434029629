/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// import { useState } from "react";
// node.js library that concatenates classes (strings)
// import classnames from "classnames";
// javascipt plugin for creating charts

// react plugin used to create charts
// import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Container } from "reactstrap";

import PendingList from "../views/examples/admin/company/superadmin-pending-requests/PendingList";
import ApprovedList from "../views/examples/admin/company/superadmin-approved-booking/ApprovedList";
import CompletedList from "../views/examples/admin/company/superadmin-completed-bookin/CompletedList";
import RejectedList from "../views/examples/admin/company/superadmin-rejected-requests/RejectedList";
import { setCompanyBookingApprovalInfo } from "redux/actions/admin/BookingApprovalRequestAction";
import { setPreClosureRequestInfo } from "redux/actions/admin/PreClosureRequestAction";
import { useSelector } from "react-redux";
import { setSowInfo } from "redux/actions/admin/SowActions";
import DashboardHeader from "components/Headers/DashboardHeader";
// import Tables from "./examples/Tables";
import completedIcon from "../assets/img/icons/customIcons/like.svg";
import pendingIcon from "../assets/img/icons/customIcons/pendingIcon.svg";
import approvedIcon from "../assets/img/icons/customIcons/maps-and-flags.svg";
import rejectedIcon from "../assets/img/icons/customIcons/cross-button.svg";
import { setPendingRecentBooking } from "redux/actions/super-admin/PendingRecentBookingsAction";
import { setApprovedRecentBooking } from "redux/actions/super-admin/ApprovedRecentBookingsAction";
import { setCompletedRecentBooking } from "redux/actions/super-admin/CompletedRecentBookingsAction";
import { setRejectedRecentBooking } from "redux/actions/super-admin/RejectedRecentBookingsAction";
import { setCompanyApprovedRequestsInfo } from "redux/actions/super-admin/ComapnyApprovedAction";
import { setCompanyRejectedInfo } from "redux/actions/super-admin/CompanyRejectedAction";
import CompanyApprovedList from "./examples/admin/company/company-approved-requests/CompanyApprovedList";
import CompanyRejectedList from "./examples/admin/company/company-rejected-requests/CompanyRejectedList";

const Index = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("1");
  const [mainActiveTab, setMainActiveTab] = useState("5");
  const [searchFormData, setSearchFormData] = useState({
    experience: null,
    skillSet: null,
    fromDate: null,
    toDate: null,
    search: null,
  });

  const handleClearSearch = () => {
    setSearchFormData({
      experience: null,
      skillSet: null,
      fromDate: null,
      toDate: null,
      search: null,
    });
  };

  const pendingResquestResult = useSelector(
    (state) => state.PendingRecentBookingReducer.result
  );
  const { itemCount } = pendingResquestResult;

  const approvedBookingResult = useSelector(
    (state) => state.ApprovedRecentBookingReducer.result
  );
  const { itemCount: approvedBookingCount } = approvedBookingResult;

  const rejectedRequestsResult = useSelector(
    (state) => state.RejectedRecentBookingReducer.result
  );
  const { itemCount: rejectedRequestsCount } = rejectedRequestsResult;

  const completedRequestsResult = useSelector(
    (state) => state.CompletedRecentBookingReducer.result
  );
  const { itemCount: completedRequestsCount } = completedRequestsResult;

  const getPendingListRequests = (createdBy, page, searchData) => {
    dispatch(setPendingRecentBooking(createdBy, page, "pending", searchData));
  };

  const getApprovedListRequests = (createdBy, page, status, searchData) => {
    dispatch(setApprovedRecentBooking(createdBy, page, status, searchData));
  };

  const getRejectedListRequests = (createdBy, page, status, searchData) => {
    dispatch(setRejectedRecentBooking(createdBy, page, status, searchData));
  };
  const getCompletedListRequests = (createdBy, page, status, searchData) => {
    dispatch(setCompletedRecentBooking(createdBy, page, status, searchData));
  };

  const getCompanyBookingApprovalInfo = (createdBy, page, type, searchData) => {
    dispatch(setCompanyBookingApprovalInfo(createdBy, page, type, searchData));
  };

  const getSowInfo = (createdBy, page, type, searchData, sow_status) => {
    dispatch(setSowInfo(createdBy, page, type, searchData, sow_status));
  };
  const getPreClosureRequestInfo = (searchData) => {
    dispatch(setPreClosureRequestInfo(searchData));
  };

  // COMPANY
  const approvedCompanyResult = useSelector(
    (state) => state.CompanyApprovedReducer.result
  );
  const { itemCount: approvedCompanyCount } = approvedCompanyResult;

  const rejectedCompanyResult = useSelector(
    (state) => state.CompanyRejectedReducer.result
  );
  const { itemCount: rejectedCompanyCount } = rejectedCompanyResult;

  const getApprovedCompanyListInfo = (type, searchData) => {
    dispatch(setCompanyApprovedRequestsInfo(type, searchData));
  };
  const getRejectedCompanyListInfo = (type, searchData) => {
    dispatch(setCompanyRejectedInfo(type, searchData));
  };

  useEffect(() => {
    getPendingListRequests("1", "1");
    getApprovedListRequests("1", "1", "approved");
    getRejectedListRequests("1", "1", "rejected");
    getCompletedListRequests("1", "1", "completed");
    getCompanyBookingApprovalInfo("0", "1", "pending");
    getSowInfo("0", "1", "approved", null, "pending");
    getPreClosureRequestInfo();
    getApprovedCompanyListInfo("approved");
    getRejectedCompanyListInfo("deleted");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      switch (tab) {
        case "1":
          getPendingListRequests("1", "1");
          handleClearSearch();
          break;
        case "2":
          getApprovedListRequests("1", "1", "approved");
          handleClearSearch();
          break;
        case "3":
          getRejectedListRequests("1", "1", "rejected");
          handleClearSearch();
          break;
        case "4":
          getCompletedListRequests("1", "1", "completed");
          handleClearSearch();
          break;
        default:
          break;
      }

      setActiveTab(tab);
    }
  };

  const MianTabtoggle = (tab) => {
    if (mainActiveTab !== tab) {
      switch (tab) {
        case "5":
          getApprovedCompanyListInfo("approved");
          handleClearSearch();
          break;
        case "6":
          getRejectedCompanyListInfo("deleted");
          handleClearSearch();
          break;
        default:
          break;
      }

      setMainActiveTab(tab);
    }
  };

  const handleEmployeeSearchOnChange = (e) => {
    setSearchFormData({ ...searchFormData, [e.target.name]: e.target.value });
  };

  // UPPER_SECTION
  const handleClearDate = () => {
    const data = { ...searchFormData, fromDate: null, toDate: null };
    setSearchFormData(data);
    switch (activeTab) {
      case "1":
        getPendingListRequests("1", "1", data);
        break;
      case "2":
        getApprovedListRequests("1", "1", "approved", data);
        break;
      case "3":
        getRejectedListRequests("1", "1", "rejected", data);
        break;
      case "4":
        getCompletedListRequests("1", "1", "completed", data);
        break;
      default:
        break;
    }
  };

  // LOWER_SECTION
  const handleClearDateMain = () => {
    const data = { ...searchFormData, fromDate: null, toDate: null };
    setSearchFormData(data);
    switch (mainActiveTab) {
      case "5":
        getApprovedCompanyListInfo("approved", data);
        break;
      case "6":
        getRejectedCompanyListInfo("deleted", data);
        break;

      default:
        break;
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    switch (mainActiveTab) {
      case "5":
        getApprovedCompanyListInfo("approved", searchFormData);
        break;
      case "6":
        getRejectedCompanyListInfo("deleted", searchFormData);
        break;

      default:
        break;
    }
  };

  const handleSearchBD = (e) => {
    e.preventDefault();
    switch (activeTab) {
      case "1":
        getPendingListRequests("1", "1", searchFormData);
        break;
      case "2":
        getApprovedListRequests("1", "1", "approved", searchFormData);
        break;
      case "3":
        getRejectedListRequests("1", "1", "rejected", searchFormData);
        break;
      case "4":
        getCompletedListRequests("1", "1", "completed", searchFormData);
        break;
      default:
        break;
    }
  };

  const data1 = [
    {
      title: " Pending Requests",
      tabId: "1",
      count: itemCount,
      icon: pendingIcon,
      color: "blue",
    },
    {
      title: "Approved Bookings",
      tabId: "2",
      count: approvedBookingCount,
      icon: approvedIcon,
      color: "green",
    },
    {
      title: "Rejected Requests",
      tabId: "3",
      count: rejectedRequestsCount,
      color: "pink",
      icon: rejectedIcon,
    },
    {
      title: "Completed Bookings",
      tabId: "4",
      count: completedRequestsCount,
      color: "purple",
      icon: completedIcon,
    },
  ];

  const data2 = [
    {
      title: "Company Details",
      tabId: "5",
      count: approvedCompanyCount,
      icon: approvedIcon,
      color: "green",
    },
    {
      title: "Company Deleted",
      tabId: "6",
      count: rejectedCompanyCount,
      icon: rejectedIcon,
      color: "pink",
    },
  ];
  return (
    <>
      <DashboardHeader
        tabCards={data1}
        selectedCard={(tabId) => toggle(tabId)}
        activeTab={activeTab}
      />
      {/* Page content */}
      <Container className="mt-4 mb-4 container-fluid" fluid>
        <div className="custom-tabs">
          {/* <TabContent activeTab={activeTab}>
            <TabPane tabId="1"> */}
          {activeTab === "1" && (
            <PendingList
              handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
              handleSearch={handleSearchBD}
              searchFormData={searchFormData}
              setSearchFormData={setSearchFormData}
              data={"Pending Requests"}
              handleClearDate={handleClearDate}
            />
          )}

          {/* </TabPane>
            <TabPane tabId="2"> */}
          {activeTab === "2" && (
            <ApprovedList
              handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
              handleSearch={handleSearchBD}
              searchFormData={searchFormData}
              setSearchFormData={setSearchFormData}
              data={"Approved Bookings"}
              handleClearDate={handleClearDate}
            />
          )}
          {/* </TabPane>
            <TabPane tabId="3"> */}
          {activeTab === "3" && (
            <RejectedList
              handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
              handleSearch={handleSearchBD}
              searchFormData={searchFormData}
              setSearchFormData={setSearchFormData}
              data={["Rejected Requests"]}
              handleClearDate={handleClearDate}
            />
          )}
          {/* </TabPane>
            <TabPane tabId="4"> */}
          {activeTab === "4" && (
            <CompletedList
              handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
              handleSearch={handleSearchBD}
              searchFormData={searchFormData}
              setSearchFormData={setSearchFormData}
              data={"Completed Bookings"}
              handleClearDate={handleClearDate}
            />
          )}
          {/* </TabPane>
          </TabContent> */}
        </div>
      </Container>
      <DashboardHeader
        tabCards={data2}
        selectedCard={(tabId) => MianTabtoggle(tabId)}
        type="second-card"
        activeTab={mainActiveTab}
      />
      <Container className="mt-4 mb-4 container-fluid" fluid>
        <div className="custom-tabs">
          {mainActiveTab === "5" && (
            <CompanyApprovedList
              handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
              handleSearch={handleSearch}
              searchFormData={searchFormData}
              setSearchFormData={setSearchFormData}
              data={"Company Details"}
              handleClearDate={handleClearDateMain}
              fromSuperAdmin={true}
            />
          )}

          {mainActiveTab === "6" && (
            <CompanyRejectedList
              handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
              handleSearch={handleSearch}
              searchFormData={searchFormData}
              setSearchFormData={setSearchFormData}
              data={"Company Deleted"}
              handleClearDate={handleClearDateMain}
            />
          )}
        </div>
      </Container>
    </>
  );
};

export default Index;
