import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const PhoneNumberComponent = (props) => {
  const {
    name,
    country,
    value,
     handleChange,
     disabled,
     handleMobileValidation
    //  onBlurChange
  } = props;

  return (
    
      <PhoneInput
        inputProps={{
          name: [name],
          id: [name],
        }}
        placeholder='Phone'
        country={country}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        // onBlur={onBlurChange}    
        isValid={(value, country) => handleMobileValidation(value,country)}   
      />
    
  );
};

export default PhoneNumberComponent;
