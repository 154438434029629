import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import viewIcn from "../../../../assets/img/icons/customIcons/view.svg";
import { axiosAccountInstance } from "api/Request";
import { getApprovedRequestsInfo } from "redux/actions/admin/ApprovedRequestAction";
import toast from "../../../../components/Toastify";
import { fetchUserData } from "helpers/storageData";
import Tables from "views/examples/Tables";
import SOWModal from "components/Modals/SOWModal";
import BookingModal from "components/Modals/BookingModal";
function PreClosureRejectedCard({ loader }) {
  const [, setData] = useState([]);
  const [bookingData, setBookingData] = useState({});
  const [bookingModal, setBookingModal] = useState(false);
  const [signedSOWdoc, setSignedSOWdoc] = useState();
  const [errors, setErrors] = useState(null);
  const [sowModal, setSOWModal] = useState(false);
  const [sowData, setSOWData] = useState({});
  const [rejectRequestReason, setRejectRequestReason] = useState("");
  const [sowErrors, setSOWErrors] = useState(null);

  const [isApproveButtonDisabled, setIsApproveButtonDisabled] = useState(true);
  const [employeeID, setEmployeeID] = useState("");
  const [sowHistory, setSOWHistory] = useState([]);
  const socketInstance = useSelector((state) => state.socketReducer.result);
  const dispatch = useDispatch();
  const responseData = useSelector(
    (state) => state.PreClosureRequestReducer.result
  );

  useEffect(() => {
    setData(responseData?.booking || []);
  }, [responseData]);

  const viewHandler = (dataObj) => {
    setBookingData(dataObj);
    setBookingModal(true);
  };
  const handleOnSOWRejectChange = (e) => {
    setRejectRequestReason(e.target.value);
    setSOWErrors({ ...sowErrors, rejectRequestReason: null });
  };
  const imageSelect = (image) => {
    // if (image) {
    //   setIsRejectButtonDisabled(false);
    // }

    const MAX_IMAGE_SIZE = 5 * 1024 * 1024; // 5MB maximum size limit
    if (image.size > MAX_IMAGE_SIZE) {
      setErrors({
        ...errors,
        sowDocument: `File size exceeds the maximum limit of ${
          MAX_IMAGE_SIZE / (1024 * 1024)
        }MB`,
      });
      setSignedSOWdoc(null);
      setIsApproveButtonDisabled(true);
    } else {
      setErrors({ ...errors, sowDocument: null });

      setSignedSOWdoc(image);
      setIsApproveButtonDisabled(false);
    }
    // setErrors({ ...errors, sowDocument: null });
    // setApproveFormData({
    //   ...approveFormData,
    //   sowDocument: image,
    // });
    // setIsApproveButtonDisabled(false);
  };
  const onApproveSOW = async () => {
    setIsApproveButtonDisabled(true);

    const data = new FormData();
    data.append("_id", employeeID);
    data.append("sow_status", "approved");
    data.append("sowSignedDocument", signedSOWdoc);

    submitRequest(data, "approved");
  };
  const onReject = () => {
    // setIsRejectButtonDisabled(true);
    const finalData = {
      _id: employeeID,
      sow_status: "rejected",
      rejectReason: rejectRequestReason,
    };
    submitRequest(finalData, "rejected");
  };
  const validationSOW = () => {
    const errorsFound = {};

    if (!rejectRequestReason || rejectRequestReason === "") {
      errorsFound["rejectRequestReason"] = "Reject Request Reason is required";
    }

    if (Object.keys(errorsFound).length !== 0) {
      setSOWErrors(errorsFound);
      return false;
    }
    return true;
  };
  const submitRequest = (data, type) => {
    if (data.sow_status === "rejected") {
      const isValid = validationSOW(data);
      setCompanyApproval(isValid, data, type);
    } else {
      setCompanyApproval(true, data, type);
    }
  };
  const setCompanyApproval = (isValid = true, data, requesttype) => {
    if (isValid) {
      axiosAccountInstance
        .post("/booking/final/sow_request/approve", data)
        .then((res) => {
          if (res.data.statusCode === 200) {
            if (requesttype === "approved") {
              toast.success(res.data.body.message);
            } else {
              socketInstance.emit("message", {
                type: "UPDATE_FINAL_SOW_REQUEST",
                subType: "UPDATE_FINAL_SOW_REJECTED_REQUEST_LIST",
                key: sowData?.bookingId,
                extraInfo: fetchUserData()?.companyName,
              });
              toast.success(res.data.body.message);
            }

            dispatch(getApprovedRequestsInfo("1", "1", "approved"));
            setSOWModal(false);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.body.message);
        });
      setRejectRequestReason("");
    }
  };
  const close = () => {
    setBookingModal(false);
    setSOWModal(false);
    setSOWData({});
  };
  const sowRequestHandler = (id, data) => {
    console.log("sow data  ", data);
    setSOWModal(!sowModal);
    setSOWData(data);
    setEmployeeID(id);
    setSOWHistory(data?.activity?.filter((obj) => obj.action.includes("SOW")));
  };
  const colums = [
    { columnName: "Employee ID", columnTag: "empId", type: "data" },

    { columnName: "Company", columnTag: "parentCompany", type: "data" },
    { columnName: "Designation", columnTag: "designation", type: "data" },
    { columnName: "Date Range", columnTag: "duration", type: "objectData" },
    {
      columnName: "Hourly Rate",
      columnTag: "hourlyPayment",
      type: "paymentMapping",
    },
    // { columnName: "Currency", columnTag: "currencyCode", type: "data" },
    {
      columnName: "SOW",
      columnTag: "sow_final_status",
      type: "data",
      actionSOWApprove: sowRequestHandler,
    },
    { columnName: "Status", columnTag: "preclosureStatus", type: "data" },
    { columnName: "", columnTag: "", type: " " },
  ];
  const actionbuttons = [
    {
      columnName: "",
      btnTitle: "View",
      btnTooltip: "View",
      type: "actionButton",
      btn: "ViewBooking",
      btnType: "primary",
      btnIcon: viewIcn,
      actionFunction: viewHandler,
    },
  ];
  return (
    <div>
      <Tables
        coloums={colums}
        data={responseData?.closureRequests}
        actionButtons={actionbuttons}
        loader={loader}
      />

      <SOWModal
        isOpen={sowModal}
        closeModal={close}
        sowData={sowData}
        onApproveSOW={onApproveSOW}
        onReject={onReject}
        sowHistory={sowHistory}
        handleOnSOWRejectChange={handleOnSOWRejectChange}
        rejectRequestReason={rejectRequestReason}
        isApproveButtonDisabled={isApproveButtonDisabled}
        sowErrors={sowErrors}
        imageSelect={imageSelect}
      />
      <BookingModal modal={bookingModal} close={close} data={bookingData} />
    </div>
  );
}

export default PreClosureRejectedCard;
