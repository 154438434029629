import axios from "axios";
import history from "helpers/History";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import loginimg from "../../assets/img/new/login.jpg";
import logobox from "../../assets/img/icons/customIcons/logo.svg";
// Third Party Imports
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  FormFeedback,
} from "reactstrap";
// import bcrypt from "bcryptjs";
import toast from "../../components/Toastify";
import { MARKETING_WEBSITE } from "helpers/utils";

function UpdatePassword({ match }) {
  const token = match.params.token;
  const [password, setpassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [errors, setErrors] = useState(null);
  const [disabled, setDisabled] = useState(false);

  // const hashedPassword = bcrypt.hashSync(
  //   password,
  //   "$2a$10$CwTycUXWue0Thq9StjUM0u"
  // );
  // const hashedConfirmPassword = bcrypt.hashSync(
  //   confirmPassword,
  //   "$2a$10$CwTycUXWue0Thq9StjUM0u"
  // );

  const validation = () => {
    const errorsFound = {};
    if (
      !confirmPassword ||
      confirmPassword === "" ||
      confirmPassword !== password
    )
      errorsFound["confirmPassword"] = "Confirm password is required";
    if (!password || password === "")
      errorsFound["password"] = "Password is required";

    if (Object.keys(errorsFound).length !== 0) {
      setErrors(errorsFound);
      return false;
    }
    return true;
  };
  const passwordValidation = (data) => {
    if (!data || data === "" || data !== password) {
      setErrors({
        ...errors,
        confirmPassword: "Confirm password doesn't match",
      });
    }
  };

  const updateHandle = (e) => {
    e.preventDefault();
    const isValid = validation();

    if (isValid) {
      setDisabled(true);
      if (password === confirmPassword && token) {
        const finalBody = {
          password: password,
          confirmPassword: confirmPassword,
          token,
        };
        axios
          .post(`${process.env.REACT_APP_API_URL}update/password`, finalBody)
          .then((res) => {
            toast.success(res.data.body.message);
            setDisabled(false);

            history.push("/auth/login");
          })
          .catch((err) => {
            setDisabled(false);
            toast.error(err.response.data.body.message);
          });
      }
    }
  };
  const navigateToExternalLink = () => {
    window.location.href = MARKETING_WEBSITE;
  };
  return (
    <>
      <Col md="5" className="bg-leftside">
        <div className="login-left">
          {/* <div className="logo-brand">
            <img alt="" src={logobox} onClick={navigateToExternalLink} />
          </div> */}
          <img alt="" src={loginimg} />
        </div>
      </Col>
      <Col md="7" className="bg-rightside">
        <div className="account-switch">
          <Link to="/auth/login">
            {/* <small>Back to login</small> */}
            <Button className="switchbtns ml-3" color="outline-primary">
              Login
            </Button>
          </Link>
        </div>
        <Card className="border-0 formboxes">
          <CardHeader className="bg-transparent border-0">
            <div className="mt-2">
              <p className="title-signup">Update Password</p>
              <p style={{ fontSize: "13px" }}>
                No worries!! Enter your new password
              </p>
            </div>
          </CardHeader>

          <CardBody className="pt-0">
            <Form role="form">
              <FormGroup>
                <InputGroup className="input-group-alternative shadow-none border">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Enter your new password"
                    type="password"
                    autoComplete="new-password"
                    value={password}
                    onChange={(e) => {
                      setpassword(e.target.value);
                      setErrors({ ...errors, password: null });
                    }}
                    invalid={!!errors && !!errors.password}
                  />
                </InputGroup>
                {/* {emailvalidate && (
                  <span style={{ font: "10px", color: "red" }}>
                    Invalid Email
                  </span>
                )} */}
                <FormFeedback className={errors?.password && "invalid-field"}>
                  {errors?.password}
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative shadow-none border">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Enter your confirm password"
                    type="password"
                    autoComplete="new-password"
                    value={confirmPassword}
                    onChange={(e) => {
                      setconfirmPassword(e.target.value);
                      setErrors({ ...errors, confirmPassword: null });
                      passwordValidation(e.target.value);
                    }}
                    invalid={!!errors && !!errors.confirmPassword}
                  />
                </InputGroup>
                <FormFeedback
                  className={errors?.confirmPassword && "invalid-field"}
                >
                  {errors?.confirmPassword}
                </FormFeedback>
              </FormGroup>

              <div className="">
                <Button
                  onClick={updateHandle}
                  disabled={disabled}
                  className="my-2 border-radius-100"
                  color="primary"
                  type="submit"
                >
                  Update
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}

export default UpdatePassword;
