const loginLogoutReducer = (state = { isLogin: false }, action) => {
  switch (action.type) {
    case "STORE_IS_LOGIN":
      return {
        isLogin: action.payload,
      };
    case "STORE_IS_LOGOUT":
      return {
        isLogin: action.payload,
      };
    default:
      return state;
  }
};

export default loginLogoutReducer;
