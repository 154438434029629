import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BookingModal from "components/Modals/BookingModal";
import viewIcn from "assets/img/icons/customIcons/view.svg";
import ExpiringBookingsTable from "./ExpiringBookingsTable";

function ExpiringBookingsCard({ loader }) {
  const [data, setData] = useState([]);
  const [bookingData, setBookingData] = useState({});
  const [bookingModal, setBookingModal] = useState(false);

  const expiringBookings = useSelector(
    (state) => state.ExpiringRequestReducer.result
  );

  useEffect(() => {
    setData(expiringBookings?.booking || []);
  }, [expiringBookings]);

  const close = () => {
    setBookingModal(false);
  };
  const viewHandler = (dataObj) => {
    setBookingData(dataObj);
    setBookingModal(true);
  };
  const columns = [
    { columnName: "Company", columnTag: "bookedCompany", type: "capitalize" },
    { columnName: "Designation", columnTag: "designation", type: "text" },
    { columnName: "Date Range", columnTag: "duration", type: "date" },
    // {
    //   columnName: "Hourly Payment",
    //   columnTag: "hourlyPayment",
    //   type: "paymentMapping",
    // },
    { columnName: "Working Days", columnTag: "workingDays", type: "text" },
    // { columnName: "Status", columnTag: "status", type: "capitalize" },
    {
      columnName: "Days Till Expiry",
      columnTag: "daysTillExpiry",
      type: "calculated",
    },
    // { columnName: "", columnTag: "actions", type: "actions" },
  ];
  const viewButton = {
    columnName: "Actions",
    btnTitle: "View",
    btnTooltip: "View",
    type: "actionButton",
    btn: "ViewBooking",
    btnType: "primary",
    btnIcon: viewIcn,
    viewHandler: viewHandler,
  };

  return (
    <div>
      {data && (
        <ExpiringBookingsTable
          columns={columns}
          data={expiringBookings?.booking}
          viewButton={viewButton}
          loader={loader}
        />
      )}

      <BookingModal modal={bookingModal} close={close} data={bookingData} />
    </div>
  );
}

export default ExpiringBookingsCard;
