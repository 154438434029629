 const submitRequestReducer = (state = { loader: false, result: [], error: null }, action) => {
    switch (action.type) {
      case 'REQUEST_BEGINS':
        return {
          loader: true,
          result: null,
          error: null,
        };
      case 'REQUEST_SUCCESS':
        return {
          loader: false,
          result: action.payload,
          error: null,
        };
      case 'REQUEST_FAILURE':
        return {
          loader: false,
          result: null,
          error: action.payload,
        };
      case 'REQUEST_RESET':
        return {
          loader: false,
          result: null,
          error: null,
        };
      default:
        return state;
    }
  };
  
  

  export default submitRequestReducer;