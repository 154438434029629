const BookingExtentionApprovalRequestReducer  = (state = { loader: false, result: [], error: null }, action) => {
    switch (action.type) {
      case 'BOOKING_EXTENTION_REQUEST_LIST_BEGINS':
        return {
          loader: true,
          result: {},
          error: null,
        };
      case 'BOOKING_EXTENTION_REQUEST_LIST_SUCCESS':
        return {
          loader: false,
          result: action.payload,
          error: null,
        };
      case 'BOOKING_EXTENTION_REQUEST_LIST_FAILURE':
        return {
          loader: false,
          result: {},
          error: action.payload,
        };
      case 'BOOKING_EXTENTION_REQUEST_LIST_RESET':
        return {
          loader: false,
          result: {},
          error: null,
        };
      default:
        return state;
    }
  };


  export default BookingExtentionApprovalRequestReducer;