/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import PaginationComponent from "components/pagination/Pagination";

import { setCompanyBookingExtentionInfo } from "redux/actions/admin/BookingExtentionRequestAction";
import ExtentionPendingCard from "./ExtentionPendingCard";
import pendingReq from "../../../../assets/img/icons/customIcons/filter.svg";
import SearchIcon from "../../../../assets/img/icons/customIcons/searchicondark.svg";
import CustomFilter from "components/customFilter";

function ExtentionPendingList({
  handleEmployeeSearchOnChange,
  handleSearch,
  searchFormData,
  setSearchFormData,
  data,
  toggle,
  handleClearDate,
  list,
  isDashboard,
  handleSearchOnSelect,
}) {
  const dispatch = useDispatch();
  const [showToggle, setShowToggle] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [tableHeight, setTableHeigth] = useState(300);

  const bookingExtentionResult = useSelector(
    (state) => state.BookingExtentionPendingReducer.result
  );
  const bookingExtentionLoader = useSelector(
    (state) => state.BookingExtentionPendingReducer.loader
  );

  const [title, setTitle] = useState("Booking extention pending");

  useEffect(() => {
    setTitle(data);

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handlePagination = (tab) => {
    const finalData = {
      ...searchFormData,
      page: tab,
    };
    dispatch(setCompanyBookingExtentionInfo("1", "1", "pending", finalData));
  };
  // eslint-disable-next-line
  useEffect(() => {
    const divElement = document.getElementById("tableDiv");
    if (bookingExtentionResult?.pages?.length > 1) {
      setTableHeigth(divElement.scrollHeight - 72 + "px");
    } else {
      setTableHeigth(divElement.scrollHeight - 12 + "px");
    }
  });
  return (
    <div className="d-flex">
      <Col id="tableDiv" md={isFilter ? "9" : "12"}>
        <Row className="mb-2">
          <Col md="10">
            <h3 className="tab-title">
              {`${title} (${
                bookingExtentionResult?.itemCount
                  ? bookingExtentionResult?.itemCount
                  : "0"
              })`}{" "}
            </h3>
          </Col>
          {!isDashboard && (
            <>
              <Col
                md="2"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <div className="common-outer-filter">
                  {list && (
                    <div className="btn-group new-cutom-filter ">
                      <button
                        type="button"
                        onClick={() => setShowToggle(!showToggle)}
                        className={"btn dropdown-toggle mr-3"}
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img alt="" src={pendingReq} width={"18px"} />
                      </button>
                      <div className="dropdown-menu dropdown-menu-right py-0">
                        {list?.map((item, key) => (
                          <button
                            style={{
                              backgroundColor:
                                item?.tabId === "1" ? "#5e72e4" : "none",
                              color: item?.tabId === "1" ? "white" : "black",
                            }}
                            key={key}
                            className="dropdown-item"
                            onClick={() =>
                              toggle({ target: { value: item?.tabId } })
                            }
                            type="button"
                          >
                            {item?.title}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                  {/* <SearchDate
                    handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
                    handleSearch={handleSearch}
                    clearDate={handleClearDate}
                  /> */}
                </div>
                <div className="common-outer-filter">
                  <div className="btn-group new-cutom-filter ">
                    <button
                      type="button"
                      onClick={() => setIsFilter(!isFilter)}
                      className={"btn dropdown-toggle mr-3"}
                    >
                      <img alt="" src={SearchIcon} width={"18px"} />
                    </button>
                  </div>
                </div>
              </Col>
            </>
          )}
        </Row>

        <ExtentionPendingCard loader={bookingExtentionLoader} />
        <div
          style={{
            display: bookingExtentionLoader ? "none" : "contents",
          }}
        >
          <PaginationComponent
            totalCount={bookingExtentionResult?.itemCount}
            handlePagination={handlePagination}
            activePage={bookingExtentionResult?.activePage}
          />
        </div>
      </Col>
      {/* {isFilter && ( */}
        <Col
          md="3"
          style={{
            height: tableHeight,
            minHeight: "670px",
            display: isFilter ? "block" : "none",
          }}
        >
          <CustomFilter
            tableHeight={tableHeight}
            minHeight={"670px"}
            handleSearchOnSelect={handleSearchOnSelect}
            handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
            handleSearch={handleEmployeeSearchOnChange}
            type="outbound"
            list={list}
            isFilter={isFilter}
            setIsFilter={setIsFilter}
            handleClearDate={handleClearDate}
          />
        </Col>
      {/* )} */}
    </div>
  );
}

export default ExtentionPendingList;
