import PaginationComponent from "components/pagination/Pagination";
import AnimatedSearchBar from "components/SearchBars/AnimatedSearchBar";
import SearchDate from "components/SearchBars/SearchDate";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import pendingReq from "../../../../../assets/img/icons/customIcons/pending-req.svg";
import { Row, Col } from "reactstrap";
import PendingSOWInboundRequestcard from "./PendingSOWInboundRequestcard";
import { getPendingSOWInboundInfo } from "redux/actions/admin/PendingSOWInboundAction";

function PendingSOWInboundList({
  handleEmployeeSearchOnChange,
  handleSearch,
  searchFormData,
  setSearchFormData,
  data,
  handleClearDate,
  list,
  toggle,
  isDashboard,
}) {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [showToggle, setShowToggle] = useState(false);

  const pendingSOWInboundList = useSelector(
    (state) => state.PendingSOWInboundReducer.result
  );
  const pendingSOWInboundListLoader = useSelector(
    (state) => state.PendingSOWInboundReducer.loader
  );

  useEffect(() => {
    setTitle(data);

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handlePagination = (tab) => {
    dispatch(getPendingSOWInboundInfo("0", "1", "", null));
  };

  return (
    <>
      <Row className="mb-2 second-search">
        <Col md="12">
          <h3 className="tab-title">
            {`${title} (${pendingSOWInboundList?.itemCount
                ? pendingSOWInboundList?.itemCount
                : "0"
              })`}{" "}
          </h3>
        </Col>
        {!isDashboard && (
          <>
            <Col lg="7" md="12">
              <AnimatedSearchBar
                handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
                handleSearch={handleSearch}
              />
            </Col>
            <Col lg="5" md="12">
              <div className="common-outer-filter">
                {list && (
                  <div className="btn-group new-cutom-filter  ">
                    <button
                      type="button"
                      onClick={() => {
                        setShowToggle(!showToggle);
                      }}
                      className={"btn dropdown-toggle mr-3"}
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img alt="" src={pendingReq} width={"18px"} />
                    </button>
                    <div className="dropdown-menu dropdown-menu-right py-0">
                      {list?.map((item, key) => (
                        <button
                          key={key}
                          className="dropdown-item"
                          onClick={() => toggle(item?.tabId)}
                          type="button"
                        >
                          {item?.title}
                        </button>
                      ))}
                    </div>
                  </div>
                )}

                <SearchDate
                  handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
                  handleSearch={handleSearch}
                  clearDate={handleClearDate}
                />
              </div>
            </Col>
          </>
        )}
      </Row>

      <PendingSOWInboundRequestcard loader={pendingSOWInboundListLoader} />

      <div
        style={{
          display: pendingSOWInboundListLoader ? "none" : "contents",
        }}
      >
        <PaginationComponent
          totalCount={pendingSOWInboundList?.itemCount}
          handlePagination={handlePagination}
          activePage={pendingSOWInboundList?.activePage}
        />
      </div>
    </>
  );
}

export default PendingSOWInboundList;
