import { axiosAccountInstance } from "api/Request";
import { getSearchUrl } from "helpers/utils";
import toast from "../../../components/Toastify";
// import { getTokenValidity } from "helpers/getTokenValidity";

export const setEmployeeListInfo =
  (type, searchData, companyId) => (dispatch) => {
    let url = `/employees?type=${type}`;
    if (companyId) {
      url = url + `&companyId=${companyId}`;
    }

    url = getSearchUrl(url, searchData);
    dispatch({
      type: "EMPLOYEE_LIST_BEGINS",
    });

    axiosAccountInstance
      .get(url)
      .then((result) => {
        // dispatching success

        if (result.data.statusCode === 200) {
          return dispatch({
            type: "EMPLOYEE_LIST_SUCCESS",
            payload: result.data.body.data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "EMPLOYEE_LIST_FAILURE",
          payload: "FALIURE",
        });
      });
  };

export const setEmployeeBookingHistoryListInfo =
  (employeeId, searchData) => (dispatch) => {
    let url = `/employee_booking_history?employeeId=${employeeId}`;

    url = getSearchUrl(url, searchData);
    dispatch({
      type: "EMPLOYEE_BOOKING_HISTORY_LIST_BEGINS",
    });

    axiosAccountInstance
      .get(url)
      .then((result) => {
        // dispatching success

        dispatch({
          type: "EMPLOYEE_BOOKING_HISTORY_LIST_SUCCESS",
          payload: result.data.body.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: "EMPLOYEE_BOOKING_HISTORY_LIST_FAILURE",
          payload: "FALIURE",
        });
        // const error = err.response
        // if(error.status === 401 && error.data.body.message === 'Unauthorized/Token_expired '){
        //   getTokenValidity(error.config, 'EMPLOYEE_BOOKING_HISTORY_LIST_BEGINS', dispatch )
        // }else{
        //   console.log("error couldnot be solved")
        // }
      });
  };

export const setEmployeeDeleteInfo = (id) => (dispatch) => {
  dispatch({
    type: "EMPLOYEE_LIST_BEGINS",
  });

  axiosAccountInstance
    .delete(`/employeedelete?id=${id}`)
    .then((result) => {
      // dispatching success
      if (result.data.statusCode === 200) {
        toast.success(result.data.body.message);
        dispatch({
          type: "EMPLOYEE_DELETE_SUCCESS",
          payload: id,
          api: "delete",
        });
      }
    })
    .catch((err) => {
      toast.error(err.response.data.body.message);
      dispatch({
        type: "REQUEST_FAILURE",
        payload: "FALIURE",
      });
    });
};
