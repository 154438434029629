import { axiosAccountInstance } from "api/Request";
import { getSearchUrl } from "helpers/utils";

export const setCompanyApprovedRequestsInfo = (type, searchData) => (dispatch) => {
  // url = getSearchUrl(url, searchData)

  let url = `/superadmin/company/approval_requests?type=${type}`
  url = getSearchUrl(url, searchData)

  dispatch({
    type: "APPROVED_COMPANY_REQUEST_LIST_BEGINS",
  });
  axiosAccountInstance
    .get(url)
    .then((result) => {
      // dispatching success
      
      dispatch({
        type: "APPROVED_COMPANY_REQUEST_LIST_SUCCESS",
        payload: result.data.body.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: "APPROVED_COMPANY_REQUEST_LIST_FAILURE",
        payload: "FALIURE",
      });
      // const error = err.response
      // if(error.status === 401 && error.data.body.message === 'Unauthorized/Token_expired '){
      //   getTokenValidity(error.config)
      // }else{
      //   console.log("error couldnot be solved")
      // }
    });
};
