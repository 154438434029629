import React, { useEffect, useState } from "react";
import CompanyApprovedListCard from "./CompanyApprovedListCard";
import { Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import PaginationComponent from "components/pagination/Pagination";
import { setCompanyApprovedRequestsInfo } from "redux/actions/super-admin/ComapnyApprovedAction";

function CompanyApprovedList({
  handleEmployeeSearchOnChange,
  handleSearch,
  searchFormData,
  setSearchFormData,
  data,
  fromSuperAdmin,
}) {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const fetchApprovedCompaniesList = useSelector(
    (state) => state?.CompanyApprovedReducer?.result
  );
  const fetchApprovedCompaniesListLoader = useSelector(
    (state) => state?.CompanyApprovedReducer?.loader
  );

  useEffect(() => {
    setTitle(data);

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const handlePagination = (tab) => {
    // setSearchFormData({
    //   ...searchFormData,
    //   page: tab,
    // });
    const finalData = {
      ...searchFormData,
      page: tab,
    };

    dispatch(setCompanyApprovedRequestsInfo("approved", finalData));
  };

  // const clearDate = () => {
  //   const data = { ...searchFormData, fromDate: null, toDate: null };
  //   setSearchFormData(data);
  //   dispatch(setCompanyApprovedRequestsInfo("approved", data));
  // };
  return (
    <>
      <Row className="mb-2">
        <Col md="12">
          <h3 className="tab-title">{title}</h3>
        </Col>
        {/* <Col lg="7" md="12">
          <CompanySearchBar
            handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
            handleSearch={handleSearch}
          />
        </Col>
        <Col lg="5" md="12" className="common-outer-filter">
          <SearchDate
            handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
            handleSearch={handleSearch}
            clearDate={clearDate}
          />
        </Col> */}
      </Row>

      <CompanyApprovedListCard
        loader={fetchApprovedCompaniesListLoader}
        fromSuperAdmin={fromSuperAdmin}
      />

      <div
        style={{
          display: fetchApprovedCompaniesListLoader ? "none" : "contents",
        }}
      >
        <PaginationComponent
          totalCount={fetchApprovedCompaniesList?.itemCount}
          handlePagination={handlePagination}
          activePage={fetchApprovedCompaniesList?.activePage}
        />
      </div>
    </>
  );
}

export default CompanyApprovedList;
