const SowReducer = (state = { loader: false, result: [], error: null }, action) => {
   
    switch (action.type) {
        case "SOW_LIST_BEGINS":
        return {
            loader: true,
            result: {},
            error: null,
        };
        case "SOW_LIST_SUCCESS":
        return {
            loader: false,
            result: action.payload,
            error: null,
        };
        case "SOW_LIST_FAILURE":
        return {
            loader: false,
            result: {},
            error: action.payload,
        };
        case "SOW_LIST_RESET":
        return {
            loader: false,
            result: {},
            error: null,
        };
        default:
        return state;
    }
    } 

    export default SowReducer;