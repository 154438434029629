import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Container } from "reactstrap";
import { useSelector } from "react-redux";

import PendingList from "../views/examples/users/admin-pending-requests/PendingList";

import { setPendingRequestInfo } from "../redux/actions/admin/PendingRequestAction";
import { getRejectedRequestsInfo } from "../redux/actions/admin/RejectedRequestAction";
import { getCompletedRequestsInfo } from "../redux/actions/admin/CompletedRequestActions";

import CompanyPendingRequestList from "./examples/admin/company/company-pending-request/CompanyPendingRequestList";
import CompanyPreClosureRequestsList from "./examples/admin/company/company-Pre-closure-requests/CompanyPreClosureRequestsList";

import CompanyExpiringRequestList from "./examples/admin/company/company-expiring-requests/CompanyExpiringRequestList";

import { setPreClosureRequestInfo } from "redux/actions/admin/PreClosureRequestAction";
import { setSowInfo } from "redux/actions/admin/SowActions";
import { fetchExpiringRequests } from "../redux/actions/admin/ExpiringRequestAction";
import { fetchExpiringBookings } from "../redux/actions/admin/ExpiringBookingsAction";
import DashboardHeader from "components/Headers/DashboardHeader";
// import Tables from "./examples/Tables";
import pendingSowIcon from "../assets/img/icons/customIcons/pendingSow1.svg";
import extensionIcon from "../assets/img/icons/customIcons/pendingExtn.svg";
import pendingIcon from "../assets/img/icons/customIcons/pendingIcon.svg";
import preclosureIcon from "../assets/img/icons/customIcons/extend-booking.svg";

import { getPendingCountInfo } from "redux/actions/admin/PendingCountRequestAction";
import { getPendingSOWOutboundInfo } from "redux/actions/admin/PendingSOWOutboundAction";
import { getPendingSOWInboundInfo } from "redux/actions/admin/PendingSOWInboundAction";
import { setCompanyBookingExtentionInfo } from "redux/actions/admin/BookingExtentionRequestAction";
import { getPendingPreClosureRequestsInfo } from "redux/actions/admin/PendingPreClosureAction";
import ExtentionPendingList from "./examples/users/admin-pending-extention-request/ExtentionPendingList";
import PendingSOWOutboundList from "./examples/admin/company/company-pendingSOWOutbound-request/PendingSOWOutboundList";
import PendingPreClosureList from "./examples/users/admin-pending-preclosure/PendingPreClosureList";
import PendingSOWInboundList from "./examples/admin/company/company-pendingSOWInbound-request/PendingSOWInboundList";
import CompanyBookingExtentionPendingList from "./examples/admin/company/company-bookingextentionpending-request/CompanyBookingExtentionPendingList";
import { getCompanyBookingPendingInfo } from "redux/actions/admin/BookingPendingRequestAction";

const Index = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("1");
  const [mainActiveTab, setMainActiveTab] = useState("5");
  const [expiryActiveTab, setExpiryActiveTab] = useState("8");
  const [searchFormData, setSearchFormData] = useState({
    experience: null,
    skillSet: null,
    fromDate: null,
    toDate: null,
    search: null,
  });
  const handleClearSearch = () => {
    setSearchFormData({
      experience: null,
      skillSet: null,
      fromDate: null,
      toDate: null,
      search: null,
    });
  };
  const handleSearch = (e) => {
    e.preventDefault();
    switch (mainActiveTab) {
      case "5":
        getCompanyBookingApprovalInfo("1", "1", "pending", searchFormData);
        break;
      case "6":
        getCompanyBookingApprovalInfo(
          "0",
          "1",
          "approved",
          searchFormData,
          "pending"
        );
        break;
      case "7":
        getPendingPreClosureListRequestsInbound(searchFormData);
        break;
      default:
        break;
    }
  };

  const handleSearchBD = (e) => {
    e.preventDefault();
    switch (activeTab) {
      case "1":
        // getPendingListRequestsOu("1", "1", searchFormData);
        break;
      case "2":
        getPendingSOWOutboundRequests("", "", "", null);
        break;
      case "3":
        getRejectedListRequests("1", "1", "rejected", searchFormData);
        break;
      case "4":
        getCompletedListRequests("1", "1", "completed", searchFormData);
        break;
      default:
        break;
    }
  };
  const approvedBookingResult = useSelector(
    (state) => state.ApprovedRequestReducer.result
  );
  const { itemCount: approvedBookingCount } = approvedBookingResult;

  const rejectedRequestsResult = useSelector(
    (state) => state.RejectedRequestReducer.result
  );
  const { itemCount: rejectedRequestsCount } = rejectedRequestsResult;

  const completedRequestsResult = useSelector(
    (state) => state.CompletedRequestReducer.result
  );
  const { itemCount: completedRequestsCount } = completedRequestsResult;
  const handleEmployeeSearchOnChange = (e, v) => {
    setSearchFormData({ ...searchFormData, [e]: v });
  };
  // UPPER_SECTION
  const handleClearDate = () => {
    const data = { ...searchFormData, fromDate: null, toDate: null };
    setSearchFormData(data);
    switch (activeTab) {
      case "1":
        // getPendingListRequests("1", "1", data);
        break;
      case "2":
        getPendingSOWOutboundRequests("", "", "", data);
        break;
      case "3":
        getRejectedListRequests("1", "1", "rejected", data);
        break;
      case "4":
        getCompletedListRequests("1", "1", "completed", data);
        break;
      default:
        break;
    }
  };
  // LOWER_SECTION
  const handleClearDateMain = () => {
    const data = { ...searchFormData, fromDate: null, toDate: null };
    setSearchFormData(data);
    switch (mainActiveTab) {
      case "5":
        getCompanyBookingApprovalInfo("1", "1", "pending", data);
        break;
      case "6":
        getCompanyBookingApprovalInfo("0", "1", "approved", data, "pending");
        break;
      case "7":
        getPendingPreClosureListRequestsInbound(searchFormData);
        break;
      default:
        break;
    }
  };
  const res = useSelector(
    (state) => state.BookingApprovalRequestReducer.result
  );
  const sowRqeuestsResults = useSelector((state) => state.SowReducer.result);
  const { itemCount: sowCount } = sowRqeuestsResults;
  const preClosureRequestsResults = useSelector(
    (state) => state.PreClosureRequestReducer.result
  );
  const { itemCount: preClosureCount } = preClosureRequestsResults;
  const getRejectedListRequests = (createdBy, page, status, searchData) => {
    dispatch(getRejectedRequestsInfo(createdBy, page, status, searchData));
  };
  const getCompletedListRequests = (createdBy, page, status, searchData) => {
    dispatch(getCompletedRequestsInfo(createdBy, page, status, searchData));
  };
  const expiryTabtoggle = (tab) => {
    pendingCounts();
    if (expiryActiveTab !== tab) {
      switch (tab) {
        case "8":
          getExpiringRequests();
          // handleClearSearch();
          break;
        case "9":
          getExpiringBookings();
          // handleClearSearch();
          break;
        default:
          break;
      }

      setExpiryActiveTab(tab);
    }
  };
  const getCompanyBookingApprovalInfo = (createdBy, page, type, searchData) => {
    dispatch(getCompanyBookingPendingInfo(createdBy, page, type, searchData));
  };

  const getSowInfo = (createdBy, page, type, searchData, sow_status) => {
    dispatch(setSowInfo(createdBy, page, type, searchData, sow_status));
  };
  const getPendingPreClosureListRequestsInbound = (searchData) => {
    dispatch(setPreClosureRequestInfo(searchData));
  };

  const getExpiringRequests = () => {
    dispatch(fetchExpiringRequests());
  };

  const getExpiringBookings = () => {
    dispatch(fetchExpiringBookings());
  };

  // const handleClearDateExpiry = () => {
  // const handleSearchExpiry = (e) => {
  //   e.preventDefault();

  //------------------------------------------------------------------------------------------------------------------------
  useEffect(() => {
    pendingCounts();
    getPendingListRequestsOutbound("1", "1");
    getPendingSOWOutboundRequests("", "", "", null);
    getPendingSOWInboundRequests("", "", "", null);
    getCompanyBookingApprovalInfo("0", "1", null, null);
    getSowInfo("0", "1", "pending", null, "pending");
    getPendingPreClosureListRequestsInbound();

    dispatch(fetchExpiringRequests());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggle = (tab) => {
    pendingCounts();
    if (activeTab !== tab) {
      switch (tab) {
        case "1":
          getPendingListRequestsOutbound("1", "1");
          handleClearSearch();
          break;
        case "2":
          getPendingSOWOutboundRequests("", "", "", null);
          handleClearSearch();
          break;
        case "3":
          getBookingExtentionListOutbound("1", "1", "pending", null);
          handleClearSearch();
          break;
        case "4":
          getPendingPreClosureListRequestsOutbound(
            "1",
            "1",
            null,
            "",
            "pending"
          );
          handleClearSearch();
          break;
        default:
          break;
      }

      setActiveTab(tab);
    }
  };

  const MianTabtoggle = (tab) => {
    pendingCounts();
    if (mainActiveTab !== tab) {
      switch (tab) {
        case "5":
          getCompanyBookingPendingInfo("0", "1", null, null);
          handleClearSearch();
          break;
        case "6":
          getPendingSOWInboundRequests("", "", "", null);
          handleClearSearch();
          break;
        case "7":
          getBookingExtentionListInbound("0", "1", "pending", null);
          handleClearSearch();
          break;
        case "8":
          getPendingPreClosureListRequestsInbound();
          handleClearSearch();
          break;
        default:
          break;
      }

      setMainActiveTab(tab);
    }
  };

  const pendingCounts = () => {
    dispatch(getPendingCountInfo());
  };
  const { itemCount: pendingBookingRequestCount } = res;

  const getPendingListRequestsOutbound = (createdBy, page, searchData) => {
    dispatch(setPendingRequestInfo(createdBy, page, searchData));
  };

  //   setSearchFormData(data);
  //   switch (expiryActiveTab) {
  //     case "8":
  //       getExpiringRequests(searchFormData);
  //       break;
  //     case "9":
  //       break;
  //     default:
  //       break;
  //   }
  // };
  const totalPendingCount = useSelector(
    (state) => state.PendingCountRequestReducer.result
  );
  console.log(totalPendingCount, "totalPendingCount");
  const pendingResquestResult = useSelector(
    (state) => state.PendingRequestReducer.result
  );
  const { itemCount } = pendingResquestResult;

  const getPendingSOWOutboundRequests = (
    createdBy,
    page,
    status,
    searchData
  ) => {
    dispatch(getPendingSOWOutboundInfo("", "", "", searchData));
  };
  const getPendingSOWInboundRequests = (
    createdBy,
    page,
    status,
    searchData
  ) => {
    dispatch(getPendingSOWInboundInfo(createdBy, page, status, searchData));
  };
  const getBookingExtentionListOutbound = (
    createdBy,
    page,
    extentionStatus,
    searchData
  ) => {
    dispatch(
      setCompanyBookingExtentionInfo(
        createdBy,
        page,
        extentionStatus,
        searchData
      )
    );
  };
  const getPendingPreClosureListRequestsOutbound = (
    createdBy,
    page,
    searchData,
    companyID,
    status
  ) => {
    dispatch(
      getPendingPreClosureRequestsInfo(
        createdBy,
        page,

        searchData,
        companyID,
        status
      )
    );
  };
  const getBookingExtentionListInbound = (
    createdBy,
    page,
    extentionStatus,
    searchData
  ) => {
    dispatch(
      setCompanyBookingExtentionInfo(
        createdBy,
        page,
        extentionStatus,
        searchData
      )
    );
  };

  const data1 = [
    {
      title:
        " Pending Bookings " +
        (
          totalPendingCount?.outbound_bookings?.length > 0
          ? "(" +
            totalPendingCount?.outbound_bookings[0]?.pending_requests +
            ")"
          : "  "
          
          ),

      tabId: "1",
      count: itemCount,
      icon: pendingIcon,
      color: "blue",
    },
    {
      title:
        "Pending SOWs " +
        (totalPendingCount?.outbound_bookings?.length > 0
          ? "(" +
            totalPendingCount?.outbound_bookings[1]?.pending_sow_approval +
            ")"
          : "  "),
      tabId: "2",
      count: approvedBookingCount,
      icon: pendingSowIcon,
      color: "green",
    },
    {
      title:
        "Pending Extension Requests " +
        (totalPendingCount?.outbound_bookings?.length > 0
          ? "(" +
            totalPendingCount?.outbound_bookings[2]?.pending_extension_request +
            ")"
          : "  "),
      tabId: "3",
      count: rejectedRequestsCount,
      color: "pink",
      icon: extensionIcon,
    },
    {
      title:
        "Pending Pre-Closure Requests " +
        (totalPendingCount?.outbound_bookings?.length > 0
          ? "(" +
            totalPendingCount?.outbound_bookings[3]
              ?.pending_pre_closure_request +
            ")"
          : "  "),
      tabId: "4",
      count: completedRequestsCount,
      color: "pink",
      icon: preclosureIcon,
    },
  ];

  const data2 = [
    {
      title:
        "Pending Bookings " +
        (totalPendingCount?.inbound_bookings?.length > 0
          ? "(" + totalPendingCount?.inbound_bookings[0]?.pending_requests + ")"
          : "  "),
      tabId: "5",
      count: pendingBookingRequestCount,
      icon: pendingIcon,
      color: "blue",
    },
    {
      title:
        "Pending SOWs  " +
        (totalPendingCount?.inbound_bookings?.length > 0
          ? "(" +
            totalPendingCount?.inbound_bookings[1]?.pending_sow_approval +
            ")"
          : "  "),
      tabId: "6",
      count: sowCount,
      icon: pendingSowIcon,
      color: "green",
    },
    {
      title:
        "Pending Extension Requests " +
        (totalPendingCount?.inbound_bookings?.length > 0
          ? "(" +
            totalPendingCount?.inbound_bookings[2]?.pending_extension_request +
            ")"
          : "  "),
      tabId: "7",
      count: preClosureCount,
      icon: extensionIcon,
      color: "pink",
    },
    {
      title:
        "Pending Pre-Closure Requests " +
        (totalPendingCount?.inbound_bookings?.length > 0
          ? "(" +
            totalPendingCount?.inbound_bookings[3]
              ?.pending_pre_closure_request +
            ")"
          : "  "),
      tabId: "8",
      count: preClosureCount,
      icon: preclosureIcon,
      color: "pink",
    },
  ];

  const data3 = [
    {
      title: "Inbound Expiring Bookings",
      tabId: "8",
      count: 0,
      icon: pendingIcon,
      color: "pink",
    },
    {
      title: "Outbound Expiring Bookings",
      tabId: "9",
      count: 0,
      icon: pendingIcon,
      color: "pink",
    },
  ];
  return (
    <>
      <div className="dashboard-outer-box">
        <div className="bg-white">
          <h2 style={{ marginLeft: "40px", fontSize: "23px" }}>
            Outbound Bookings
          </h2>
          <DashboardHeader
            tabCards={data1}
            selectedCard={(tabId) => toggle(tabId)}
            activeTab={activeTab}
          />
          {/* Page content */}
          <Container className="mt-4 mb-4 container-fluid" fluid>
            <div className="custom-tabs">
              {/* <TabContent activeTab={activeTab}>
            <TabPane tabId="1"> */}
              {activeTab === "1" && (
                <PendingList
                  isDashboard={true}
                  handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
                  handleSearch={handleSearchBD}
                  searchFormData={searchFormData}
                  setSearchFormData={setSearchFormData}
                  data={"Pending Bookings"}
                  handleClearDate={handleClearDate}
                />
              )}

              {/* </TabPane>
            <TabPane tabId="2"> */}
              {activeTab === "2" && (
                <PendingSOWOutboundList
                  isDashboard={true}
                  handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
                  handleSearch={handleSearchBD}
                  searchFormData={searchFormData}
                  setSearchFormData={setSearchFormData}
                  data={"Pending SOWs"}
                  handleClearDate={handleClearDate}
                />
              )}
              {/* </TabPane>
            <TabPane tabId="3"> */}
              {activeTab === "3" && (
                <ExtentionPendingList
                  isDashboard={true}
                  handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
                  handleSearch={handleSearchBD}
                  searchFormData={searchFormData}
                  setSearchFormData={setSearchFormData}
                  data={["Pending Extension Requests"]}
                  handleClearDate={handleClearDate}
                />
              )}
              {/* </TabPane>
            <TabPane tabId="4"> */}
              {activeTab === "4" && (
                <PendingPreClosureList
                  isDashboard={true}
                  handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
                  handleSearch={handleSearchBD}
                  searchFormData={searchFormData}
                  setSearchFormData={setSearchFormData}
                  data={"Pending Pre-Closure Requests"}
                  handleClearDate={handleClearDate}
                />
              )}
              {/* </TabPane>
          </TabContent> */}
            </div>
          </Container>
        </div>
      </div>

      <div className="dashboard-outer-box">
        <div className="bg-white">
          <h2 style={{ marginLeft: "40px", fontSize: "23px" }}>
            Inbound Bookings
          </h2>
          <DashboardHeader
            tabCards={data2}
            selectedCard={(tabId) => MianTabtoggle(tabId)}
            type="second-card"
            activeTab={mainActiveTab}
          />
          <Container className="mt-4 mb-4 container-fluid" fluid>
            <div className="custom-tabs">
              {mainActiveTab === "5" && (
                <CompanyPendingRequestList
                  isDashboard={true}
                  handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
                  handleSearch={handleSearch}
                  searchFormData={searchFormData}
                  setSearchFormData={setSearchFormData}
                  data={"Pending Bookings"}
                  handleClearDate={handleClearDateMain}
                />
              )}

              {mainActiveTab === "6" && (
                <PendingSOWInboundList
                  isDashboard={true}
                  handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
                  handleSearch={handleSearch}
                  searchFormData={searchFormData}
                  setSearchFormData={setSearchFormData}
                  data={"Pending SOWs"}
                  handleClearDate={handleClearDateMain}
                />
              )}

              {mainActiveTab === "7" && (
                <CompanyBookingExtentionPendingList
                  isDashboard={true}
                  handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
                  handleSearch={handleSearch}
                  searchFormData={searchFormData}
                  setSearchFormData={setSearchFormData}
                  data={"Pending Extension Requests"}
                  handleClearDate={handleClearDateMain}
                />
              )}
              {mainActiveTab === "8" && (
                <CompanyPreClosureRequestsList
                  isDashboard={true}
                  handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
                  handleSearch={handleSearch}
                  searchFormData={searchFormData}
                  setSearchFormData={setSearchFormData}
                  data={"Pending Pre-Closure Requests"}
                  handleClearDate={handleClearDateMain}
                />
              )}
            </div>
          </Container>
        </div>
      </div>

      <div className="dashboard-outer-box">
        <div className="bg-white">
          <h2 style={{ marginLeft: "40px", fontSize: "23px" }}>
            Expiring Bookings
          </h2>
          <DashboardHeader
            tabCards={data3}
            selectedCard={(tabId) => expiryTabtoggle(tabId)}
            type="second-card"
            activeTab={expiryActiveTab}
          />
          <Container className="mt-4 mb-4 container-fluid" fluid>
            <div className="custom-tabs">
              {expiryActiveTab === "8" && (
                <CompanyExpiringRequestList
                  type="requests"
                  data={"Inbound Expiring Bookings"}
                />
              )}

              {expiryActiveTab === "9" && (
                <CompanyExpiringRequestList
                  type="Bookings"
                  data={"Outbound Expiring Bookings"}
                />
              )}
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Index;
