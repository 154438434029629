import React from "react";
import {
  Button,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import moment from "moment";
import { Rating } from "react-simple-star-rating";
import { baseURL } from "api/Request";
import downloadIcn from "../../assets/img/icons/customIcons/download-svg.svg";

const BookingExtentionModal = ({ modal, close, data }) => {
  const onClose = () => {
    close();
  };

  return (
    <div>
      <Modal
        toggle={close}
        scrollable
        isOpen={modal}
        className="bookingmodal rightside-modal"
      >
        <Row>
          <div className="col">
            <ModalHeader className="text-uppercase font-weight-bolder pb-2">
              <h2>Employee Details</h2>
            </ModalHeader>
          </div>
          <Row>
            <Col>
              <ModalHeader className="text-uppercase pt-3 pb-2">
                <Button onClick={onClose} className="mx-1" close />
              </ModalHeader>
            </Col>
          </Row>
        </Row>
        <ModalBody>
          <FormGroup row className="employee-det-row mb-0 px-2">
            <Col sm={6} className="mb-2 px-1">
              <div className="first-row">
                <div className="labels">
                  <h4>Employee ID </h4>
                </div>
                <div className="values">
                  {/* <p>{data?.empId}</p> */}
                  <p>
                    {data?.parentBookingDetails?.length > 0 &&
                      data?.parentBookingDetails[0]?.empId}
                  </p>
                </div>
              </div>
            </Col>
            {data?.sowStatus === "approved" && (
              <Col sm={6} className="mb-2 px-1">
                <div className="first-row">
                  <div className="labels">
                    <h4>Employee Name </h4>
                  </div>
                  <div className="values text-capitalize">
                    <p>{data?.name}</p>
                  </div>
                </div>
              </Col>
            )}

            <Col sm={6} className="mb-2 px-1">
              <div className="first-row">
                <div className="labels">
                  <h4>Designation </h4>
                </div>
                <div className="values text-capitalize">
                  <p>{data?.designation}</p>
                </div>
              </div>
            </Col>

            
            <Col sm={6} className="mb-2 px-1">
              <div className="first-row">
                <div className="labels">
                  <h4>Hourly Payment </h4>
                </div>
                <div className="values">
                  <p>
                    {data?.currencyCode === "INR" && "₹"}
                    {data?.currencyCode === "EUR" && "€"}
                    {data?.currencyCode === "USD" && "$"}
                    {data?.hourlyPayment}
                  </p>
                </div>
              </div>
            </Col>
            <Col sm={12} className="mb-2 px-1">
              <div className="first-row">
                <div className="labels">
                  <h4>Skills </h4>
                </div>
                <div className="values">
                  {data?.skillSets?.map((skill, key) => {
                  
                    return (
                      // <p>{`${skill?.domain} : ${skill?.numberOfYears} years ${skill.numberOfMonths} months`}</p>
                <div className="skill-values justify-content-between">
               <div className="d-flex">
               <span className="skillname">{skill?.domain}</span>
                <span className="expname">{skill?.numberOfYears+"."+skill?.numberOfMonths} years</span> 
                </div>
               
                <Rating className="rating-area"
                      initialValue={skill?.rating}
                      name="rating"
                      readonly="true"
                      
                    />
                </div>
                    );
                  })}
                </div>
              </div>
            </Col>
            {/* <Col sm={6} className="mb-2 px-1">
              <div className="first-row">
                <div className="labels">
                  <h4>Currency </h4>
                </div>
                <div className="values">
                  <p>{data?.currencyCode}</p>
                </div>
              </div>
            </Col> */}
            <Col sm={12}>
              <h2 className="mt-4">Booking Details</h2>
              <hr className="mt-1 mb-3" />
            </Col>
            <Col sm={6} className="mb-2 px-1">
              <div className="first-row">
                <div className="labels">
                  <h4>Booking Requested By </h4>
                </div>
                <div className="values text-capitalize">
                  <p>{data?.bookedCompany}</p>
                </div>
              </div>
            </Col>
            <Col sm={6} className="mb-2 px-1">
              <div className="first-row">
                <div className="labels">
                  <h4>Booking Requested To </h4>
                </div>
                <div className="values text-capitalize">
                  <p>{data?.parentCompany}</p>
                </div>
              </div>
            </Col>
            
            <Col sm={6} className="mb-2 px-1">
              <div className="first-row">
                <div className="labels">
                  <h4>From </h4>
                </div>
                <div className="values">
                  <p>
                    {data?.parentBookingDetails &&
                      data?.parentBookingDetails?.length > 0 &&
                      moment(
                        data?.parentBookingDetails[0]?.duration?.fromDate
                      ).format("MMMM DD, YYYY")}
                  </p>
                </div>
              </div>
            </Col>
            <Col sm={6} className="mb-2 px-1">
              <div className="first-row">
                <div className="labels">
                  <h4>To </h4>
                </div>
                <div className="values">
                  <p>
                    {data?.parentBookingDetails &&
                      data?.parentBookingDetails?.length > 0 &&
                      moment(
                        data?.parentBookingDetails[0]?.duration?.toDate
                      ).format("MMMM DD, YYYY")}
                  </p>
                </div>
              </div>
            </Col>
            <Col sm={6} className="mb-2 px-1">
              <div className="first-row">
                <div className="labels">
                  <h4>Extention Requested From </h4>
                </div>
                <div className="values">
                  <p>
                    {moment(data?.duration?.fromDate).format("MMMM DD, YYYY")}
                  </p>
                </div>
              </div>
            </Col>
            <Col sm={6} className="mb-2 px-1">
              <div className="first-row">
                <div className="labels">
                  <h4>Extention Requested To </h4>
                </div>
                <div className="values">
                  <p>
                    {moment(data?.duration?.toDate).format("MMMM DD, YYYY")}
                  </p>
                </div>
              </div>
            </Col>
            {/* {data?.bookingExtentionsDetails && (
              <Col sm={6}>
                <div className="first-row">
                  <div className="labels">
                    <h4>Extensions requested </h4>
                  </div>
                  <div className="values">
                    {data?.bookingExtentionsDetails?.length > 0 ? (
                      data?.bookingExtentionsDetails?.map((extention, key) => {
                        return (
                          <p key={key}>{`${key + 1} : ${moment(
                            extention?.duration?.fromDate
                          ).format("MMMM Do, YYYY")} - ${moment(
                            extention?.duration?.fromDate
                          ).format("MMMM Do, YYYY")}`}</p>
                        );
                      })
                    ) : (
                      <p> No extensions requested</p>
                    )}
                  </div>
                </div>
              </Col>
            )} */}

            <Col sm={6} className="mb-2 px-1">
              <div className="first-row">
                <div className="labels">
                  <h4>Working Days </h4>
                </div>
                <div className="values">
                  <p>{data?.workingDays}</p>
                </div>
              </div>
            </Col>
            <Col sm={6} className="mb-2 px-1">
              <div className="first-row">
                <div className="labels">
                  <h4>Working Hours Per Day</h4>
                </div>
                <div className="values">
                  <p>{data?.workingHoursPerDay}</p>
                </div>
              </div>
            </Col>
            {/* <Col sm={6} className="mb-2 px-1">
              <div className="first-row">
                <div className="labels">
                  <h4>Status </h4>
                </div>
                <div className="values text-capitalize">
                  <p>{data?.status}</p>
                </div>
              </div>
            </Col> */}

            {/* <Col sm={6} className="mb-2 px-1">
              <div className="first-row">
                <div className="labels">
                  <h4>SOW </h4>
                </div>
                <div className="values text-capitalize">
                  <p>
                    {data?.parentBookingDetails &&
                    data?.parentBookingDetails?.length > 0 &&
                    data?.parentBookingDetails[0]?.sow_final_status
                      ? data?.parentBookingDetails[0]?.sow_final_status
                      : "Pending"}
                    <span>
                      {data?.parentBookingDetails &&
                        data?.parentBookingDetails?.length > 0 &&
                        data?.parentBookingDetails[0]?.sowStatus ===
                          "approved" && (
                          // eslint-disable-next-line react/jsx-no-target-blank
                          <a
                            href={
                              baseURL +
                              "/" +
                              data?.parentBookingDetails[0]?.sowDocument
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                          >
                            <img src={downloadIcn} width={"21"} alt=""></img>
                          </a>
                        )}
                    </span>
                  </p>
                </div>
              </div>
            </Col> */}


            {/* <Col sm={6} className="mb-2 px-1">
          <div className="first-row">
          <div className="labels">
          <h4>SOW</h4>
          </div>
          <div className="values text-capitalize">
            {data?.parentBookingDetails &&
            data?.parentBookingDetails?.length > 0 &&
            data?.parentBookingDetails[0]?.sowStatus === "approved" ? (
          <a
          href={
            baseURL +
            "/" +
            data?.parentBookingDetails[0]?.sowDocument
          }
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          <img src={downloadIcn} width={21} alt="Download Icon" />
        </a>
      ) : null}
    </div>
  </div>
</Col> */}

<Col className={`mb-2 px-1${data?.parentBookingDetails &&
    data?.parentBookingDetails?.length > 0 &&
    data?.parentBookingDetails[0]?.sowStatus === "approved" ? " col-sm-6" : " col-sm-12"}`}>
  <div className="first-row">
    <div className="labels">
      <h4>Status</h4>
    </div>
    <div className="values text-capitalize">
      <p>{data?.status}</p>
    </div>
  </div>
</Col>

{data?.parentBookingDetails &&
  data?.parentBookingDetails?.length > 0 &&
  data?.parentBookingDetails[0]?.sowStatus === "approved" ? (
  <Col sm={6} className="mb-2 px-1">
    <div className="first-row">
      <div className="labels">
        <h4>SOW</h4>
      </div>
      <div className="values text-capitalize">
        <a
          href={
            baseURL +
            "/" +
            data?.parentBookingDetails[0]?.sowDocument
          }
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          <img src={downloadIcn} width={21} alt="Download Icon" />
        </a>
      </div>
    </div>
  </Col>
): null}


            <Col sm={12} className="mb-2 px-1">
              <div className="first-row">
                <div className="labels">
                  <h4>Remarks </h4>
                </div>
                <div className="values text-capitalize">
                  <p>{data?.remarks}</p>
                </div>
              </div>
            </Col>
            <Col sm={12} className="pt-2 px-1">
              <h2 className="mt-4">Activity</h2>
              <hr className="mt-1 mb-3" />
            </Col>
            {data?.activity && (
              <Col sm={12} className="mb-2 px-1">
                <div className="first-row">
                  <div className="labels">
                    <h4>Action </h4>
                  </div>
                  <div className="values">
                    <div className="activity-timeline">
                      {data?.parentBookingDetails &&
                      data?.parentBookingDetails?.length > 0 ? (
                        data?.parentBookingDetails[0]?.activity?.map(
                          (act, key) => {
                            return (
                              <div className="activityboxes">
                                <p className="action-title">{act?.action}</p>
                                {/* <p key={key}>
                                  {" "}
                                  {act?.action} on{" "}
                                  {moment(act?.time).format("LLL")}
                                </p> */}
                                <label key={key}>
                                  {" "}
                                  {act?.action}
                                  {act?.fromDate && act?.toDate
                                    ? " from " +
                                      moment(act.fromDate).format(
                                        "MMMM D, YYYY"
                                      ) +
                                      " to " +
                                      moment(act.toDate).format("MMMM D, YYYY")
                                    : ""}
                                  {act?.rejectReason
                                    ? ` with reason "${act.rejectReason}"`
                                    : ""}{" "}
                                  on {moment(act?.time).format("LLL")}
                                </label>
                              </div>
                            );
                          }
                        )
                      ) : (
                        <p></p>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            )}
          </FormGroup>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default BookingExtentionModal;
