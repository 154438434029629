const PendingRequestReducer = (
  state = { loader: false, result: [], error: null },
  action
) => {
  switch (action.type) {
    case "PENDING_REQUEST_LIST_BEGINS":
      return {
        loader: true,
        result: state.result,
        error: null,
      };
    case "PENDING_REQUEST_LIST_SUCCESS":
      return {
        loader: false,
        result: action.payload,
        error: null,
      };
    case "PENDING_REQUEST_LIST_FAILURE":
      return {
        loader: false,
        result: {},
        error: action.payload,
      };

    case "PENDING_REQUEST_DELETE_SUCCESS": {
      let currentPendingRequestsList = { ...state.result };
      let pendingRequestsList = currentPendingRequestsList && currentPendingRequestsList.booking;
      pendingRequestsList =
        pendingRequestsList &&
        pendingRequestsList.filter((item) => item._id !== action.payload);
      currentPendingRequestsList = { ...currentPendingRequestsList, booking: pendingRequestsList };
      return {
        loader: false,
        result: currentPendingRequestsList,
        error: null,
      };
    }
    case "PENDING_REQUEST_LIST_RESET":
      return {
        loader: false,
        result: {},
        error: null,
      };
    default:
      return state;
  }
};

export default PendingRequestReducer;
