const EmployeeListReducer = (state = { loader: false, result: [], error: null }, action) => {
    switch (action.type) {
      case 'EMPLOYEE_LIST_BEGINS':
        return {
          loader: true,
          result: state.result,
          error: null,
        };
      case 'EMPLOYEE_LIST_SUCCESS':
        
        return {
          loader: false,
          result: action.payload,
          error: null,
        };
      case 'EMPLOYEE_LIST_FAILURE':
        return {
          loader: false,
          result: {},
          error: action.payload,
        };
      case 'EMPLOYEE_DELETE_SUCCESS':{
        let currentEmployeeList = {...state.result}  
        let employeeList = currentEmployeeList && currentEmployeeList.employees
        employeeList = employeeList && employeeList.filter(item => item._id !== action.payload)
        currentEmployeeList = {...currentEmployeeList, employees:employeeList}
        return {
            loader: false,
            result: currentEmployeeList,
            error: null,
          }
        }
      case 'EMPLOYEE_LIST_RESET':
        return {
          loader: false,
          result: {},
          error: null,
        };
      default:
        return state;
    }
  };
  
  export default EmployeeListReducer;