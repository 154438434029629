import React, { useEffect, useMemo} from "react";
import { useDropzone } from "react-dropzone";
import { AiOutlineClose } from "react-icons/ai";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "40px",
  borderWidth: 2,
  borderRadius: 12,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  maxWidth: "1100px",
  textAlign: "center",
  margin: "auto",
  maxFiles: 1, // add maxFiles property
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function StyledDropzone({
  onChangeImage,
  type,
  resumeFileName,
  onClose,
  isEdit,
  // isFalse
  // isImport,
}) {
  

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({
    // accept: type === "doc" ? ".pdf,.doc" : ".csv",
    accept:
    type === "doc"
    ? { "application/msword": [], "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [], "application/pdf": [] }
    : { "text/csv": [] },
  
        maxFiles: 1,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  
  useEffect(() => {
    if (!resumeFileName) {
      
      acceptedFiles?.splice(acceptedFiles[0], 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resumeFileName]);

  useEffect(() => {
    
    if (acceptedFiles?.length > 0) {
    
      onChangeImage(acceptedFiles[0]);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

   
  return (
    <div className="mb-4 upload-img" {...getRootProps({ style })}>
      <input {...getInputProps()} />
      <label className="my-1">Drag a file to upload or browse a file</label>
      <aside>
        {/* <ul className="mb-0 list-unstyled">
           {resumeFileName &&
            acceptedFiles.map((file) => <li key={file.path}>{file.path}</li>)}
        </ul> */}

        {/* resume in addEmployee */}
        <ul className="mb-0 list-unstyled">
          {resumeFileName &&
            acceptedFiles.map((file) => (
              <div class="row justify-content-center">
                <div class="col">
                  <span className="text-primary">{file?.path}</span>
                </div>
                <div class="col">
                  <AiOutlineClose onClick={onClose} />
                </div>
              </div>
            ))}
        </ul>

        {/* normal file uploads */}
        <ul className="mb-0 list-unstyled">
          { acceptedFiles.length > 0 && !resumeFileName && (
            <li>{acceptedFiles[0]?.path}</li>
          )}

         
        </ul>

        {/* for resumefile in edit screen */}
        <ul className="mb-0 list-unstyled">
          {isEdit && acceptedFiles?.length === 0 && resumeFileName && (
            // <div className="d-flex justify-content-between  w-100">
            <div class="row justify-content-center">
              <div class="col">
                <span className="text-primary">
                  {resumeFileName?.split("/").pop()}
                </span>
              </div>
              <div class="col">
                <AiOutlineClose onClick={onClose} />
              </div>
            </div>
          )}
        </ul>
        {/* <ul className="mb-0 list-unstyled">
       {acceptedFiles.length === 0 && resumeFileName && 
         <li key={resumeFileName}>
          {resumeFileName.split('/').pop()}
         </li>}
       </ul> */}
      </aside>
    </div>
  );
}

export default StyledDropzone;
