import { axiosAccountInstance } from "api/Request";
import { getSearchUrl } from "helpers/utils";

export const setCompanyBookingExtentionInfo =
  (createdBy, page, extentionStatus, searchData) => (dispatch) => {
    let url = `/booking/extention_list?createdBy=${createdBy}&extentionStatus=${extentionStatus}`;
    url = getSearchUrl(url, searchData);
    let list_begins, list_success, list_faliure;

    // eslint-disable-next-line eqeqeq
    console.log("createdBYYYYY")
    if (createdBy == 1) {
      console.log("in.................e")
      switch (extentionStatus) {
        case "":
          list_begins = "ALL_BOOKING_EXTENTION_LIST_BEGINS";
          list_success = "ALL_BOOKING_EXTENTION_LIST_SUCCESS";
          list_faliure = "ALL_BOOKING_EXTENTION_LIST_FAILURE";
          break;
        case "pending":
          list_begins = "BOOKING_EXTENTION_PENDING_LIST_BEGINS";
          list_success = "BOOKING_EXTENTION_PENDING_LIST_SUCCESS";
          list_faliure = "BOOKING_EXTENTION_PENDING_LIST_FAILURE";
          break;
        case "approved":
          list_begins = "BOOKING_EXTENTION_REQUEST_APPROVED_LIST_BEGINS";
          list_success = "BOOKING_EXTENTION_REQUEST_APPROVED_LIST_SUCCESS";
          list_faliure = "BOOKING_EXTENTION_REQUEST_APPROVED_LIST_FAILURE";
          break;
        case "rejected":
          list_begins = "BOOKING_EXTENTION_REQUEST_REJECTED_LIST_BEGINS";
          list_success = "BOOKING_EXTENTION_REQUEST_REJECTED_LIST_SUCCESS";
          list_faliure = "BOOKING_EXTENTION_REQUEST_REJECTED_LIST_FAILURE";
          break;

        default:
          break;
      }
    } else {
      list_begins = "BOOKING_EXTENTION_REQUEST_LIST_BEGINS";
      list_success = "BOOKING_EXTENTION_REQUEST_LIST_SUCCESS";
      list_faliure = "BOOKING_EXTENTION_REQUEST_LIST_FAILURE";
    }
    dispatch({
      type: list_begins,
    });

    axiosAccountInstance
      .get(url)
      .then((result) => {
        dispatch({
          type: list_success,
          payload: result.data.body.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: list_faliure,
          payload: "FALIURE",
        });
      });
  };
