import { Card, CardBody, Container, Row, Col } from "reactstrap";

const DashboardHeader = ({ tabCards, selectedCard, type, activeTab }) => {
  const tabHanlder = (tabContent) => {
    selectedCard(tabContent?.tabId);
    // setTitle(tabContent?.title);
  };

  return (
    <>
      {/* <div className="p-3">
        <div className="bg-white"> */}
      <div
        className={`${type === "second-card"
            ? "Dashboardheader pb-2 pt-2"
            : "Dashboardheader pb-2 pt-2"
          }`}
      >
        <Container fluid>
          <div className="Dashboardheader-body">
            <Row>
              {tabCards?.map((item, key) => {
                return (
                  <Col key={key} lg="6" xl="3" className="my-1 ">
                    <Card
                      className={`${item?.tabId === activeTab && "active"
                        } card-stats header-cardstats  mb-4 mb-xl-0`}
                      onClick={() => tabHanlder(item)}
                    >
                      <CardBody className="px-3">
                        <div className="headercard">
                          <div className="title-sect">
                            <div className={`${item?.color} icons  my-1`}>
                              <img src={item?.icon} alt="icon" />
                            </div>
                            <div className="title-text">
                              <p className="my-1">{item?.title}</p>
                            </div>
                          </div>
                          {/* <div className="title-value">
                          <h2 className="my-1">{item?.count}</h2>
                        </div> */}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </div>
        </Container>
      </div>
      {/* </div>
      </div> */}
    </>
  );
};

export default DashboardHeader;
