import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Tables from "views/examples/Tables";

function CompanyRejectedListCard({ loader }) {
  const fetchRejectedCompaniesList = useSelector(
    (state) => state?.CompanyRejectedReducer?.result
  );
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(fetchRejectedCompaniesList?.companies || []);
  }, [fetchRejectedCompaniesList]);

  const colums = [
    { columnName: "Company", columnTag: "companyName", type: "data" },
    { columnName: "Designation", columnTag: "designation", type: "data" },
    { columnName: "Contact Number", columnTag: "contactNumber", type: "data" },

    { columnName: "", columnTag: "", type: "" },
  ];
  return (
    <div>
      {data && (
        <Tables
          actions={null}
          coloums={colums}
          data={fetchRejectedCompaniesList?.companies}
          loader={loader}
        />
      )}
      {/* <Row className="justify-content-center mt-1">
            <Col md="12">
              <Card className="card-stats  my-1">
                <CardBody>
                  <Row>
                   
                    <div className="col-sm-4">
                      <div className="card-box">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase font-weight-bolder text-darker mb-1"
                        >
                          Company
                        </CardTitle>
                        <p className="h5 font-weight-normal mb-0">
                          {companyName}
                        </p>
                        <p className="h5 font-weight-normal mb-0">{address}</p>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="card-box">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase font-weight-bolder text-darker mb-1"
                        >
                          Designation
                        </CardTitle>
                        <p className="h5 font-weight-normal mb-0">
                          {item.designation}
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="card-box">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase font-weight-bolder text-darker mb-1"
                        >
                          Contact
                        </CardTitle>
                        <p className="h5 font-weight-normal mb-0">
                          {contactNumber}
                        </p>
                        <p className="h5 font-weight-normal mb-0">{email}</p>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
    </div>
  );
}

export default CompanyRejectedListCard;
