import jwt_decode from "jwt-decode";

export const fetchUserData = () => JSON.parse(localStorage.getItem('user'));
export const fetchToken = () => localStorage.getItem('Token');
export const fetchRefreshToken = () => localStorage.getItem('Refresh_Token');
export const fetchRole = () => {
    const token = localStorage.getItem('Token')
    var decoded = jwt_decode(token);

    return decoded.role
}
