import { axiosAccountInstance } from "api/Request";
import { getSearchUrl } from "helpers/utils";
import toast from "../../../components/Toastify";
// import { getTokenValidity } from "helpers/getTokenValidity";

export const getAllRequestInfo =
  (createdBy, page, searchData, companyId) => (dispatch) => {
    let url = `/booking/approval_requests?createdBy=${createdBy}&status=`;
    if (companyId) {
      url = url + `&companyId=${companyId}`;
    }
    url = getSearchUrl(url, searchData);
    dispatch({
      type: "ALL_REQUEST_LIST_BEGINS",
    });

    axiosAccountInstance
      .get(url)
      .then(
        (result) => {
          dispatch({
            type: "ALL_REQUEST_LIST_SUCCESS",
            payload: result.data.body.data,
          });
        }
        // (err) => {
        //   dispatch({
        //     type: "ALL_REQUEST_LIST_FAILURE",
        //     payload: "FALIURE",
        //   });
        //   // const error = err.response
        //   // if(error.status === 401 && error.data.body.message === 'Unauthorized/Token_expired '){
        //   //   getTokenValidity(error.config)
        //   // }else{
        //   //   console.log("error couldnot be solved")
        //   // }
        // }
      )
      .catch((err) => {
        dispatch({
          type: "ALL_REQUEST_LIST_FAILURE",
          payload: "FALIURE",
        });
      });
  };

export const setAllRequestDeleteInfo = (id) => (dispatch) => {
  dispatch({
    type: "ALL_REQUEST_LIST_BEGINS",
  });

  axiosAccountInstance
    .delete(`/deletebooking?id=${id}`)
    .then((result) => {
      // dispatching success
      if (result.data.statusCode === 200) {
        toast.success(result.data.body.message);
        dispatch({
          type: "ALL_REQUEST_DELETE_SUCCESS",
          payload: id,
          api: "delete",
        });
      }
    })
    .catch((err) => {
      toast.error(err.response.data.body.message);
      dispatch({
        type: "ALL_REQUEST_LIST_FAILURE",
        payload: "FALIURE",
      });

      // const error = err.response
      // if(error.status === 401 && error.data.body.message === 'Unauthorized/Token_expired '){
      //   getTokenValidity(error.config, 'ALL_REQUEST_DELETE_SUCCESS', dispatch, id )
      // }else{
      //   console.log("error couldnot be solved")
      // }
    });
};
