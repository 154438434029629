import { axiosAccountInstance } from "api/Request";
import { getSearchUrl } from "helpers/utils";
// import { getTokenValidity } from "helpers/getTokenValidity";

// INBOUND
export const setPreClosureRequestInfo = (searchData) => (dispatch) => {
  let url = `/booking/preclosure_list?createdBy=0&preclosureStatus=pending`;
  url = getSearchUrl(url, searchData);
  dispatch({
    type: "PRE_CLOSURE_LIST_BEGINS",
  });

  // http://localhost:3000/booking/preclosure_list?createdBy=0&preclosureStatus=pending

  axiosAccountInstance
    .get(url)
    .then(
      function (result) {
        dispatch({
          type: "PRE_CLOSURE_LIST_SUCCESS",
          payload: result.data.body.data,
        });
      }
      // (err) => {
      //   dispatch({
      //     type: "PRE_CLOSURE_REQUEST_LIST_FAILURE",
      //     payload: "FALIURE",
      //   });
      //   // const error = err.response
      //   // if(error.status === 401 && error.data.body.message === 'Unauthorized/Token_expired '){
      //   //   getTokenValidity(error.config)
      //   // }else{
      //   //   console.log("error couldnot be solved")
      //   // }
      // }
    )
    .catch((err) => {
      dispatch({
        type: "PRE_CLOSURET_LIST_FAILURE",
        payload: "FALIURE",
      });
    });
};
