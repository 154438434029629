const EmployeeBookingHistoryListReducer =  (state = { loader: false, result: [], error: null }, action) => {
    switch (action.type) {
      case 'EMPLOYEE_BOOKING_HISTORY_LIST_BEGINS':
        return {
          loader: true,
          result: {},
          error: null,
        };
      case 'EMPLOYEE_BOOKING_HISTORY_LIST_SUCCESS':
        return {
          loader: false,
          result: action.payload,
          error: null,
        };
      case 'EMPLOYEE_BOOKING_HISTORY_LIST_FAILURE':
        return {
          loader: false,
          result: {},
          error: action.payload,
        };
      case 'EMPLOYEE_BOOKING_HISTORY_LIST_RESET':
        return {
          loader: false,
          result: {},
          error: null,
        };
      default:
        return state;
    }
  };

  export default EmployeeBookingHistoryListReducer;