import React, { useEffect, useState } from "react";

import { Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import PaginationComponent from "components/pagination/Pagination";
import pendingReq from "../../../../assets/img/icons/customIcons/filter.svg";

// import filterIcon from "../../../../assets/img/icons/customIcons/searchicon.svg";
import SearchIcon from "../../../../assets/img/icons/customIcons/searchicondark.svg";
import AllRequestListCard from "./AllRequestListCard";
import { getAllRequestInfo } from "redux/actions/admin/AllRequestAction";
import history from "helpers/History";
import CustomFilter from "components/customFilter";
function AllRequestList({
  handleEmployeeSearchOnChange,
  handleSearch,
  searchFormData,
  setSearchFormData,
  data,
  companyId,
  toggle,
  list,
  handleClearDate,
  handleSearchOnSelect,
  empId,
  clearEmpId,
}) {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [empIdChip, setEmpIdChip] = useState(false);
  const [showToggle, setShowToggle] = useState(false);
  const [tableHeight, setTableHeigth] = useState(300);

  const fetchResultAllListRequests = useSelector(
    (state) => state.AllRequestReducer.result
  );

  const fetchResultAllListRequestsLoader = useSelector(
    (state) => state.AllRequestReducer.loader
  );
  const notification = useSelector((state) => state.NotificationReducer.result);

  const clearChip = () => {
    setEmpIdChip(false);
    dispatch(getAllRequestInfo("1", "1", null, ""));
    clearEmpId();
    history.push("/admin/booking-details");
    dispatch({
      type: "NOTIFICATION_RESET",
    });
  };

  useEffect(() => {
    console.log("diffpageOutbnd", empId);
    setTitle(data);
    if (empId) {
      setEmpIdChip(true);
    } else if (notification?.empId) {
      setEmpIdChip(true);
    } else {
      setEmpIdChip(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  const handlePagination = (tab) => {
    const finalData = {
      ...searchFormData,
      page: tab,
    };

    dispatch(getAllRequestInfo("1", "1", finalData, companyId));
  };
  // eslint-disable-next-line
  useEffect(() => {
    const divElement = document.getElementById("tableDiv");
    if (fetchResultAllListRequests?.pages?.length > 1) {
      setTableHeigth(divElement.scrollHeight - 72 + "px");
    } else {
      setTableHeigth(divElement.scrollHeight - 12 + "px");
    }
  });

  return (
    <>
      <div className="d-flex">
        <Col id="tableDiv" md={isFilter ? "9" : "12"}>
          <Row className="mb-2">
            <Col md={"10"}>
              <div className="d-flex" style={{ alignItems: "center" }}>
                <h3 className="tab-title">
                  {`${title} (${
                    fetchResultAllListRequests?.itemCount
                      ? fetchResultAllListRequests?.itemCount
                      : "0"
                  })`}
                </h3>
                {empIdChip && (
                  <div class="chip ml-3">
                    {empId || notification?.empId}
                    <span onClick={clearChip} class="closebtn">
                      &times;
                    </span>
                  </div>
                )}
              </div>
            </Col>
            <Col md="2" style={{ display: "flex", justifyContent: "flex-end" }}>
              <div className="common-outer-filter">
                {list && (
                  <div className="btn-group new-cutom-filter  ">
                    <button
                      type="button"
                      onClick={() => {
                        setShowToggle(!showToggle);
                        console.log("heyyyyy");
                      }}
                      className={"btn dropdown-toggle mr-3"}
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img alt="" src={pendingReq} width={"18px"} />
                    </button>
                    <div className="dropdown-menu dropdown-menu-right py-0">
                      {list?.map((item, key) => (
                        <button
                          style={{
                            backgroundColor:
                              item?.tabId === "0" ? "#5e72e4" : "none",
                            color: item?.tabId === "0" ? "white" : "none",
                          }}
                          key={key}
                          className="dropdown-item"
                          onClick={() =>
                            toggle({ target: { value: item?.tabId } })
                          }
                          type="button"
                        >
                          {item?.title}
                        </button>
                      ))}
                    </div>
                  </div>
                )}

                {/* {!isDashboard && (
              <SearchDate
                handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
                handleSearch={handleSearch}
                handleClearDate={handleClearDate}
              />
            )} */}
              </div>
              <div className="common-outer-filter">
                <div className="btn-group new-cutom-filter ">
                  <button
                    type="button"
                    onClick={() => setIsFilter(!isFilter)}
                    className={"btn dropdown-toggle mr-3"}
                  >
                    <img alt="" src={SearchIcon} width={"18px"} />
                  </button>
                </div>
              </div>
            </Col>
          </Row>
          <AllRequestListCard
            loader={fetchResultAllListRequestsLoader}
            companyId={companyId}
          />

          <div
            style={{
              display: fetchResultAllListRequestsLoader ? "none" : "contents",
            }}
          >
            <PaginationComponent
              totalCount={fetchResultAllListRequests?.itemCount}
              handlePagination={handlePagination}
              activePage={fetchResultAllListRequests?.activePage}
            />
          </div>
        </Col>
        {/* {isFilter && ( */}
          <Col
            md="3"
            style={{
              height: tableHeight,
              minHeight: "670px",
              display: isFilter ? "block" : "none",
            }}
          >
            <CustomFilter
              tableHeight={tableHeight}
              minHeight={"670px"}
              handleSearchOnSelect={handleSearchOnSelect}
              handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
              handleSearch={handleEmployeeSearchOnChange}
              type="outbound"
              list={list}
              isFilter={isFilter}
              setIsFilter={setIsFilter}
              handleClearDate={handleClearDate}
            />
          </Col>
        {/* )} */}
      </div>
    </>
  );
}

export default AllRequestList;
