import React, { useEffect, useState } from "react";

import { Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import PaginationComponent from "components/pagination/Pagination";

import pendingReq from "../../../../assets/img/icons/customIcons/filter.svg";
import SearchIcon from "../../../../assets/img/icons/customIcons/searchicondark.svg";

import { getPendingPreClosureRequestsInfo } from "redux/actions/admin/PendingPreClosureAction";
import PendingPreClosureListCard from "./PendingPreclosureListCard";
import CustomFilter from "components/customFilter";
function PendingPreClosureList({
  handleEmployeeSearchOnChange,
  handleSearch,
  searchFormData,

  data,
  companyId,
  toggle,
  list,
  handleClearDate,
  title,
  isDashboard,
  handleSearchOnSelect,
}) {
  const dispatch = useDispatch();

  const [showToggle, setShowToggle] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [tableHeight, setTableHeigth] = useState(300);

  const fetchResultPendingPreClosureListRequests = useSelector(
    (state) => state.PreClosureRequestReducer.result
  );

  const fetchResultPendingPreClosureListRequestsLoader = useSelector(
    (state) => state.PreClosureRequestReducer.loader
  );

  const handlePagination = (tab) => {
    const finalData = {
      ...searchFormData,
      page: tab,
    };

    dispatch(
      getPendingPreClosureRequestsInfo("1", "1", finalData, "", "pending")
    );
  };
  // eslint-disable-next-line
  useEffect(() => {
    const divElement = document.getElementById("tableDiv");
    if (fetchResultPendingPreClosureListRequests?.pages?.length > 1) {
      setTableHeigth(divElement.scrollHeight - 72 + "px");
    } else {
      setTableHeigth(divElement.scrollHeight - 12 + "px");
    }
  });
  return (
    <div className="d-flex">
      <Col id="tableDiv" md={isFilter ? "9" : "12"}>
        <Row className="mb-2 second-search">
          <Col md="10">
            <h3 className="tab-title">{`${"Pending Pre-Closure Requests"} (${
              fetchResultPendingPreClosureListRequests?.itemCount
                ? fetchResultPendingPreClosureListRequests?.itemCount
                : "0"
            })`}</h3>
          </Col>
          {!isDashboard && (
            <>
              <Col
                md="2"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <div className="common-outer-filter">
                  {list && (
                    <div className="btn-group new-cutom-filter  ">
                      <button
                        type="button"
                        onClick={() => {
                          setShowToggle(!showToggle);
                        }}
                        className={"btn dropdown-toggle mr-3"}
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img alt="" src={pendingReq} width={"18px"} />
                      </button>
                      <div className="dropdown-menu dropdown-menu-right py-0">
                        {list?.map((item, key) => (
                          <button
                            key={key}
                            className="dropdown-item"
                            onClick={() =>
                              toggle({ target: { value: item?.tabId } })
                            }
                            type="button"
                          >
                            {item?.title}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div className="common-outer-filter">
                  <div className="btn-group new-cutom-filter ">
                    <button
                      type="button"
                      onClick={() => setIsFilter(!isFilter)}
                      className={"btn dropdown-toggle mr-3"}
                    >
                      <img alt="" src={SearchIcon} width={"18px"} />
                    </button>
                  </div>
                </div>
              </Col>
            </>
          )}
        </Row>

        <PendingPreClosureListCard
          loader={fetchResultPendingPreClosureListRequestsLoader}
        />

        <div
          style={{
            display: fetchResultPendingPreClosureListRequestsLoader
              ? "none"
              : "contents",
          }}
        >
          <PaginationComponent
            totalCount={fetchResultPendingPreClosureListRequests?.itemCount}
            handlePagination={handlePagination}
            activePage={fetchResultPendingPreClosureListRequests?.activePage}
          />
        </div>
      </Col>
      {/* {isFilter && ( */}
        <Col
          md="3"
          style={{
            height: tableHeight,
            minHeight: "670px",
            display: isFilter ? "block" : "none",
          }}
        >
          <CustomFilter
            tableHeight={tableHeight}
            minHeight={"670px"}
            handleSearchOnSelect={handleSearchOnSelect}
            handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
            handleSearch={handleEmployeeSearchOnChange}
            type="outbound"
            list={list}
            isFilter={isFilter}
            setIsFilter={setIsFilter}
            handleClearDate={handleClearDate}
          />
        </Col>
      {/* )} */}
    </div>
  );
}

export default PendingPreClosureList;
