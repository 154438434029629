const  authReducer = (state = { loader: false, result: {}, error: null }, action) => {
    switch (action.type) {
      case 'STORE_SOCKET_INSTANCE_BEGINS':
        return {
          loader: true,
          result: null,
          error: null,
        };
      case 'STORE_SOCKET_INSTANCE_SUCCESS':
        return {
          loader: false,
          result: action.payload,
          error: null,
        };
    
      case 'STORE_SOCKET_INSTANCE_RESET':
        return {
          loader: false,
          result: null,
          error: null,
        };
      default:
        return state;
    }
  };
  
  export default authReducer;