// import StyledDropzone from "components/drag-and-drop/DragDropFile";

import PhoneNumberComponent from "components/phoneNumberInput/PhoneNumberComponent";
import history from "helpers/History";
import React, { useState } from "react";
// import Header from "components/Headers/Header.js";
import {
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Label,
  FormFeedback,
  Button,
} from "reactstrap";
// import AddResume from "./AddProjects";
// import AddSkills from "./AddSkills";
function AddBasicDetails(props) {
  const {
    handleOnChange,
    formData,
    errors,
    phoneNumberInputChnage,
    handleMobileValidation,
    // imageSelect,
    employeeId,
    isEdit,
    saveHandler
  } = props;
  const [updatePassword, setUpdatePassword] = useState(true);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);

  const updatePasswordHandler = () => {
    setUpdatePassword(!updatePassword);
  };

  const goBack = () => {
    history.push("/admin/list-managers")
  }
 
  return (
    <>
      {/* <Header /> */}

      <Row className="justify-content-center mb-3">
        <Col md="12">
          <Form role="form">
            <div className="mb-3 d-flex justify-content-between  align-items-center">
              <h3 className="add-titles-box">
              {isEdit ? "Edit Manager" : "Add Manager"}</h3>
              <div>
                {employeeId && (
                  <Button
                    color="primary"
                    onClick={updatePasswordHandler}
                    size="sm"
                  >
                    {updatePassword ? "Update Password" : "Close"}
                  </Button>
                )}
                <Button
                  color="primary"
                  onClick={goBack}
                  size="sm"
                >
                  <i class="fa fa-times"></i>
                </Button>
              </div>

            </div>

            {/* <StyledDropzone
              onChangeImage={(e) => {
                imageSelect(e);
              }}
            /> */}
            <Row>
              <Col sm={6}>
                <FormGroup>
                  <Label
                    style={{ fontSize: "10px" }}
                    for="employeeName"
                    className="text-uppercase"
                  >
                    First Name<span style={{ color: "red" }}>*</span>
                  </Label>
                  <InputGroup className="input-group-alternative shadow-none border">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa fa-user" />
                      </InputGroupText>
                    </InputGroupAddon>

                    <Input
                      required
                      placeholder="First Name"
                      type="text"
                      name="firstName"
                      onChange={(e) => handleOnChange(e)}
                      value={formData.firstName}
                      invalid={!!errors && !!errors.firstName}
                    />
                  </InputGroup>
                  <FormFeedback className={errors?.firstName && "invalid-field"}>
                    {errors?.firstName}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label
                    style={{ fontSize: "10px" }}
                    for="employeeName"
                    className="text-uppercase"
                  >
                    Last Name<span style={{ color: "red" }}>*</span>
                  </Label>
                  <InputGroup className="input-group-alternative shadow-none border">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa fa-user" />
                      </InputGroupText>
                    </InputGroupAddon>

                    <Input
                      required
                      placeholder="Last Name"
                      type="text"
                      name="lastName"
                      onChange={(e) => handleOnChange(e)}
                      value={formData.lastName}
                      invalid={!!errors && !!errors.lastName}
                    />
                  </InputGroup>
                  <FormFeedback className={errors?.lastName && "invalid-field"}>
                    {errors?.lastName}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FormGroup>
                  <Label
                    style={{ fontSize: "10px" }}
                    for="employeeName"
                    className="text-uppercase"
                  >
                    Designation<span style={{ color: "red" }}>*</span>
                  </Label>
                  <InputGroup className="input-group-alternative shadow-none border">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      required
                      placeholder="Designation"
                      type="text"
                      name="designation"
                      value={formData.designation}
                      onChange={(e) => handleOnChange(e)}
                      invalid={!!errors && !!errors.designation}
                    />
                  </InputGroup>
                  <FormFeedback
                    className={errors?.designation && "invalid-field"}
                  >
                    {errors?.designation}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <FormGroup>
                  <Label
                    style={{ fontSize: "10px" }}
                    for="employeeName"
                    className="text-uppercase"
                  >
                    Phone<span style={{ color: "red" }}>*</span>
                  </Label>

                  <PhoneNumberComponent
                    name="mobile"
                    id="mobile"
                    country={"in"}
                    value={formData.mobile}
                    handleChange={phoneNumberInputChnage}
                    handleMobileValidation={handleMobileValidation}
                  />

                  <FormFeedback
                    className={
                      (errors?.mobile || errors?.mobileNumberVal) &&
                      "invalid-field"
                    }
                  >
                    {errors?.mobile || errors?.mobileNumberVal}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label
                    style={{ fontSize: "10px" }}
                    for="employeeName"
                    className="text-uppercase"
                  >
                    Email Address<span style={{ color: "red" }}>*</span>
                  </Label>
                  <InputGroup className="input-group-alternative shadow-none border">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa fa-envelope" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      required
                      placeholder="Email"
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={(e) => handleOnChange(e)}
                      invalid={!!errors && !!errors.email}
                    />
                  </InputGroup>
                  <FormFeedback className={errors?.email && "invalid-field"}>
                    {errors?.email}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>

            {/* <Row>
              <Col>
                <FormGroup>
                  <Label
                    style={{ fontSize: "10px" }}
                    for="fromdate"
                    className="text-uppercase"
                  >
                    total experience in years
                    <span style={{ color: "red" }}>*</span>
                  </Label>
                  <InputGroup className="input-group-alternative shadow-none border">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      required
                      min={0}
                      placeholder="Total experience in years"
                      type="number"
                      name="experienceNumberOfYears"
                      onKeyPress={(e) => {
                        if (e.key === "e") {
                          e.preventDefault();
                        }
                      }}
                      value={formData.experienceNumberOfYears}
                      onChange={(e) => handleOnChange(e)}
                      invalid={!!errors && !!errors.experienceNumberOfYears}
                    />
                  </InputGroup>
                  <FormFeedback
                    className={
                      errors?.experienceNumberOfYears && "invalid-field"
                    }
                  >
                    {errors?.experienceNumberOfYears}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label
                    style={{ fontSize: "10px" }}
                    for="employeeName"
                    className="text-uppercase"
                  >
                    total experience in months
                    <span style={{ color: "red" }}>*</span>
                  </Label>
                  <InputGroup className="input-group-alternative shadow-none border">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      required
                      min={0}
                      pattern="[0-9\.]+"
                      placeholder="Total experience in months"
                      type="number"
                      name="experienceNumberOfMonths"
                      onKeyPress={(e) => {
                        if (e.key === "e") {
                          e.preventDefault();
                        }
                      }}
                      value={formData.experienceNumberOfMonths}
                      onChange={ handleOnChange}
                      invalid={!!errors && !!errors.experienceNumberOfMonths}
                    />
                  </InputGroup>
                  <FormFeedback
                    className={
                      errors?.experienceNumberOfMonths && "invalid-field"
                    }
                  >
                    {errors?.experienceNumberOfMonths}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row> */}
            <Row>
              <Col sm={6}>
                <FormGroup>
                <Label
                    style={{ fontSize: "10px" }}
                    for="employeeName"
                    className="text-uppercase"
                  >
                    Password<span style={{ color: "red" }}>*</span>
                  </Label>
                  <InputGroup className="input-group-alternative shadow-none border">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      // type="password"
                      type={isPasswordVisible ? "text" : "password"}
                      autoComplete="password"
                      disabled={employeeId ? updatePassword : false}
                      name="password"
                      value={formData.password}
                      onChange={(e) => handleOnChange(e)}
                      invalid={!!errors && !!errors.password}
                    />
                    {formData.password?.length > 0 && (
                    <InputGroupAddon>
                      <InputGroupText
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                      >
                        <i
                          class="fa fa-eye"
                          style={{ paddingTop: "4px", cursor: "pointer" }}
                          aria-hidden="true"
                        ></i>
                      </InputGroupText>
                    </InputGroupAddon>
                  )}
                  </InputGroup>
                  <FormFeedback className={errors?.password && "invalid-field"}>
                    {errors?.password}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                <Label
                    style={{ fontSize: "10px" }}
                    for="employeeName"
                    className="text-uppercase"
                  >
                    Confirm Password<span style={{ color: "red" }}>*</span>
                  </Label>
                  <InputGroup className="input-group-alternative shadow-none border">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Confirm Password"
                      type={isConfirmPasswordVisible ? "text" : "password"}
                      name="confirmPassword"
                      disabled={employeeId ? updatePassword : false}
                      autoComplete="new-password"
                      value={formData.confirmPassword}
                      onChange={(e) => handleOnChange(e)}
                      invalid={!!errors && !!errors.confirmPassword}
                      onKeyDown={(e) => {               //form submission on pressing Enter key
                        if (e.key === "Enter") {
                          saveHandler(e);
                        }
                      }}
                    />
                    {formData.confirmPassword?.length > 0 && (
                    <InputGroupAddon>
                      <InputGroupText
                        onClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}
                      >
                        <i
                          class="fa fa-eye"
                          style={{ paddingTop: "4px", cursor: "pointer" }}
                          aria-hidden="true"
                        ></i>
                         </InputGroupText>
                    </InputGroupAddon>
                  )}
                  </InputGroup>
                  <FormFeedback
                    className={errors?.confirmPassword && "invalid-field"}
                  >
                    {errors?.confirmPassword}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
          </Form>
          {/* </CardBody>
          </Card> */}
        </Col>
      </Row>
    </>
  );
}

export default AddBasicDetails;
