import React, { useState, useEffect } from 'react';
import logo from '../../assets/img/brand/logo.svg';
import { Link, useLocation } from "react-router-dom";
import {
  Button
} from "reactstrap";


const HeaderLogin = () => {
  const location = useLocation();
  const [isSticky, setIsSticky] = useState(false);
  const handleGetStartedButtonClick = () => {
    // Replace 'your-link-here' with the actual link you want to open
    window.location.href = process.env.REACT_APP_HOME_URL;
  };
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const header = document.getElementById('skill-header');

      if (header) {
        setIsSticky(scrollPosition > header.offsetTop);
      }
    };
    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Detach the event listener when the component is unmounted
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const headerClassName = `skill-header ${isSticky ? 'sticky' : ''}`;

  return (
    <header className={headerClassName} id="skill-header" >
      <div className="hdr-main">
          <a href="https://scaleuphq.io/">
            <img src={logo} alt="logo" width={200}/>
          </a>
          {/* <button onClick={handleGetStartedButtonClick} className="btn btn-primary text-white custom-btn">{"Let’s Get Started"}</button> */}
      </div>
    </header>
  );
};

export default HeaderLogin;
