import React, { useState } from "react";

function Toggle(props) {
  const {
    offstyle = "btn-danger",
    onstyle = "btn-success",
    editEmployeeStatus,
    id,
    checked

  } = props;


  const [toggleButton, setToggleButton] = useState({
    checked: checked,
  });


  const handleChange = (e) => {

    setToggleButton({ ...toggleButton, checked: e.target.checked ? 'active' : 'inactive' });
    let finalData = {}
    if (e.target.checked === true) {
      finalData = {
        _id: id,
        status: 'active'
      }
    } else {
      finalData = {
        _id: id,
        status: 'inactive'
      }
    }
    editEmployeeStatus(finalData)
  };

  let displayStyle = (toggleButton.checked === 'active' || toggleButton.checked === 'enabled') ? onstyle : offstyle;
  return (
    <>
      <label className="my-0">
        <span className={`${toggleButton.checked} switch-wrapper`}>
          {" "}
          <input
            type="checkbox"
            checked={(toggleButton.checked === 'active' || toggleButton.checked === 'enabled') ? true : false}
            disabled={toggleButton.disabled}
            onChange={(e) => handleChange(e)}
          />
          <span className={`${displayStyle} switch`}>
            <span className="switch-handle" />
          </span>
        </span>
        {/* <span className="switch-label">{text}</span> */}
      </label>
    </>
  );
}

export default Toggle;
