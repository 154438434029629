import React, { useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import toast from "../Toastify";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "helpers/storageData";
import { Rating } from "react-simple-star-rating";
import { axiosAccountInstance } from "api/Request";
import moment from "moment";
// import downloadIcn from "../../assets/img/icons/customIcons/download-svg.svg";
import downloadResumeIcon from "../../assets/img/icons/common/download.png";
import shareIcon from "../../assets/img/icons/common/share.png";
// import toast from '../Toastify'
// import Tables from "views/examples/Tables";
// import ResumeModal from './ResumeModal'

const EmployeeModal = ({ modal, close, data, currentData, loading }) => {
  const onClose = () => {
    setClickDisabled(false);
    close();
  };
  const dispatch = useDispatch();
  const [isClickDisabled, setClickDisabled] = useState(false);
  const socketInstance = useSelector((state) => state.socketReducer.result);
  const onClick = () => {
    console.log("onclick/....");
    if (isClickDisabled) {
      console.log("if...");
      return;
    }
    // Disable multiple resume request click
    setClickDisabled(true);

    axiosAccountInstance

      .get(
        `${process.env.REACT_APP_API_URL}request_resume?employeeId=${data?.employeeId}`
      )
      .then((result) => {
        socketInstance.emit("message", {
          type: "RESUME REQUEST",
          key: data?.employeeId,
          user: fetchUserData()?.companyId,
          extraInfo: fetchUserData()?.companyName,
        });
        toast.success(result.data.body.data);

        dispatch({
          payload: result.data.body.data,
        });
      });

    //   .catch((err) => {
    //     toast.error(result.data.body.data);
    //   });
  };
  // function handleViewResume() {
  //   if (!data.resume) {
  //     toast.error("Resume not found");
  //   }
  // }
  const toggle = () => {
    setClickDisabled(false);
    close();
  };
  const href = data?.resume
    ? `${process.env.REACT_APP_API_URL}${data?.resume}`
    : "#";

  return (
    <div>
      <Modal
        toggle={toggle}
        scrollable
        isOpen={modal}
        className="rightside-modal"
      >
        <Row>
          <div className="col">
            <ModalHeader className="text-uppercase font-weight-bolder pt-4">
              {" "}
              Employee Details
            </ModalHeader>
          </div>
          <Row>
            <Col>
              <ModalHeader className="text-uppercase pt-3 pb-2">
                <Button onClick={onClose} className="mx-1" close />
              </ModalHeader>
            </Col>
          </Row>
        </Row>
        <ModalBody>
          <FormGroup row className="employee-det-row mb-0">
            <Col sm={12}>
              <div className="first-row">
                <div className="labels">
                  <h4>Employee ID </h4>
                </div>
                <div className="values">
                  <p>{data?.empId}</p>
                </div>
              </div>
            </Col>
            {data?.sowStatus === "approved" && (
              <Col sm={12}>
                <div className="first-row">
                  <div className="labels">
                    <h4>Employee Name </h4>
                  </div>
                  <div className="values">
                    <p>{data?.name}</p>
                  </div>
                </div>
              </Col>
            )}
            {/* <Col sm={6}>
              <div className="first-row">
                <div className="labels">
                  <h4>Email </h4>
                </div>
                <div className="values">
                  <p>{data?.email}</p>
                </div>
              </div>
            </Col>
            <Col sm={6}>
              <div className="first-row">
                <div className="labels">
                  <h4>Mobile </h4>
                </div>
                <div className="values">
                  <p>{data?.mobile}</p>
                </div>
              </div>
            </Col> */}
            <Col sm={12}>
              <div className="first-row">
                <div className="labels">
                  <h4>Company Name </h4>
                </div>
                <div className="values text-capitalize">
                  <p>{data?.companyName}</p>
                </div>
              </div>
            </Col>
            <Col sm={12}>
              <div className="first-row">
                <div className="labels">
                  <h4>Designation </h4>
                </div>
                <div className="values text-capitalize">
                  <p>{data?.designation}</p>
                </div>
              </div>
            </Col>
            <Col sm={12}>
              <div className="first-row">
                <div className="labels">
                  <h4>Skills </h4>
                </div>
                <div className="values">
                  {data?.skillSets?.map((skill, key) => {
                    return (
                      <div className="skill-values justify-content-between">
                        <div className="d-flex">
                          <span className="skillname">{skill?.domain}</span>
                          <span className="expname">
                            {skill?.numberOfYears + "." + skill?.numberOfMonths}{" "}
                            years
                          </span>
                        </div>

                        <Rating
                          className="rating-area"
                          initialValue={skill?.rating}
                          name="rating"
                          readonly="true"
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </Col>
            {/* <Col sm={data?.resume ?6 :12}> */}
            <Col sm={6}>
              <div className="first-row">
                <div className="labels">
                  <h4>Hourly Payment </h4>
                </div>
                <div className="values">
                  <p>
                    {data?.currencyCode === "INR" && "₹"}
                    {data?.currencyCode === "EUR" && "€"}
                    {data?.currencyCode === "USD" && "$"}
                    {data?.hourlyPayment}
                  </p>
                </div>
              </div>
            </Col>

            <Col sm={6}>
              <div className="first-row">
                <div className="labels">
                  <h4>Resume</h4>
                </div>

                {data?.resume ? (
                  <div
                    className="values"
                    style={{ fontSize: "14px", marginTop: "-3px" }}
                  >
                    <a
                      href={href}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                      style={{ color: "#525F7F", fontSize: "14px" }}
                      //  onClick={(event) =>
                      //    !data.resume && event.preventDefault()
                      //  }
                    >
                      <img src={downloadResumeIcon} width={"21"} alt=""></img>
                      Download
                    </a>
                  </div>
                ) : (
                  <div className="values" style={{ fontSize: "14px" }}>
                    {/* <img alt="" src={shareIcon} style={{marginTop:-3, marginRight:4, width:16}} />
                     Request */}
                    <span style={{ cursor: "pointer" }} onClick={onClick}>
                      <img
                        alt=""
                        src={shareIcon}
                        style={{ marginTop: -3, marginRight: 4, width: 16 }}
                      />
                      Request
                    </span>
                  </div>
                )}
              </div>
            </Col>

            {/* <Col sm={12}>
              <div className="first-row">
                <div className="labels">
                  <h4>Currency </h4>
                </div>
                <div className="values">
                  <p>{data?.currencyCode}</p>
                </div>
              </div>
            </Col> */}

            <Col sm={12}>
              <div className="first-row">
                <div className="labels">
                  <h4>Current Booking</h4>
                  {currentData.length === 0 && !loading ? (
                    <span style={{ fontSize: "14px" }}>
                      No current bookings
                    </span>
                  ) : (
                    <>
                      <Table>
                        <thead>
                          <tr>
                            <th>From Date</th>
                            <th>To Date</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading && currentData?.length > 0
                            ? currentData?.map((data) => (
                                <tr key={data?._id}>
                                  <td>
                                    {moment(data?.duration?.fromDate).format(
                                      "MMMM DD, YYYY"
                                    )}
                                  </td>
                                  <td>
                                    {moment(data?.duration?.toDate).format(
                                      "MMMM DD, YYYY"
                                    )}
                                  </td>
                                  <td>
                                    {data?.status.charAt(0).toUpperCase() +
                                      data?.status.slice(1)}
                                  </td>
                                </tr>
                              ))
                            : loading && (
                                <tr>
                                  <td colSpan={3}>
                                    <Spinner size={"sm"} />
                                  </td>
                                </tr>
                              )}
                        </tbody>
                      </Table>
                    </>
                  )}
                </div>
              </div>
            </Col>

            <div></div>

            {/* <h4>Current Booking</h4>
          <Table>
          <thead>
          <tr>
            <th>From Date</th>
            <th>To Date</th>
            <th>Status</th>
          </tr>
          </thead>
          <tbody>
          {currentData?.map(data => (
            <tr key={data?._id}>
              
              <td>{moment(data?.duration?.fromDate).format("MMMM DD, YYYY")}</td>
              <td>{moment(data?.duration?.toDate).format("MMMM DD, YYYY")}</td>
              <td>{data?.status}</td>
            </tr>
          ))}
         </tbody>
          </Table> */}
          </FormGroup>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default EmployeeModal;
