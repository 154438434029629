import React, { useEffect, useState } from "react";
import AddBasicDetails from "./AddBasicDetails";
import { Button, Container, CardBody, Card } from "reactstrap";
import { axiosAccountInstance } from "api/Request";
import history from "helpers/History";
import toast from "../../../../../components/Toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import DashboardHeader from "components/Headers/DashboardHeader";

function Container1({ match }) {
  const dispatch = useDispatch();
  const employeeId = match.params.employeeId;
  const submitLoader = useSelector(
    (state) => state.submitRequestReducer.loader
  );

  const [isEdit, setEdit] = useState(false);
  const [formData, setFormData] = useState({
    companyName: null,
    firstName: null,
    lastName: null,
    profilePhoto: "",
    designation: null,
    // experienceNumberOfYears: null,
    // experienceNumberOfMonths: null,
    email: null,
    mobile: null,
    _id: "",
    password: null,
    confirmPassword: null,
  });
  const [isInvalidPhone, setIsInvalidPhone] = useState(null);

  // const getFN = (name) => {
  //   let fullName = name?.split(" ");
  //   return fullName[0];
  // };
  // const getLN = (name) => {
  //   let fullName = name?.split(" ");
  //   return fullName[1];
  // };

  // storing the data in case of edit-page
  useEffect(() => {
    if (employeeId) {
      setEdit(true);
      axiosAccountInstance.get(`/manager/${employeeId}`).then((res) => {
        const requestData = res.data.body.data;
        setFormData({
          // firstName: getFN(requestData?.name),
          // lastName: getLN(requestData?.name),
          // name: requestData?.name,
          firstName: requestData?.firstName,
          lastName: requestData?.lastName,
          email: requestData?.email,
          mobile: requestData?.mobile,
          designation: requestData?.designation,
          // experienceNumberOfYears: requestData?.experience?.numberOfYears,
          // experienceNumberOfMonths: requestData?.experience?.numberOfMonths,
          _id: requestData?._id,
        });
      });
    }
  }, [employeeId]);

  // storing errors
  const [errors, setErrors] = useState({
    projectError: [],
    skillSetsError: [],
  });
  // const [projErrors, setprojErrors] = useState(null);

  // const [inputList, setInputList] = useState([]);
  // const [showResumeForm, setShowResumeForm] = useState(false);

  const phoneNumberInputChnage = (e) => {
    setFormData({
      ...formData,
      mobile: e,
    });
    setErrors({ ...errors, mobile: null });
    mobileNumbervalidation(e);
  };

  const imageSelect = (image) => {
    setFormData({
      ...formData,
      profilePhoto: image,
    });
  }; // end of imageSelect

  const handleOnChange = (e) => {
    if (e.target.name === "profilePhoto") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.files[0],
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
      setErrors({ ...errors, [e.target.name]: null });
    }
  };

  const mobileNumbervalidation = (data) => {
    const regex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;

    if (!data || regex.test(data) === false || data.length < 6) {
      setErrors({
        ...errors,
        mobile: "Please enter a valid mobile number",
      });
      return false;
    } else {
      setErrors({ ...errors, mobile: "" });
      return true;
    }
  };

  const handleMobileValidation = (values, countrys) => {
    if (countrys.format.match(/\./g).length === values.length) {
      setIsInvalidPhone(false);
      return true;
    } else {
      setIsInvalidPhone(true);
      return false;
    }
  };

  const validation = async () => {
    const {
      firstName,
      lastName,
      designation,
      // experienceNumberOfYears,
      // experienceNumberOfMonths,
      email,
      mobile,
      password,
      confirmPassword,
    } = formData;

    const errorsFound = {};

    // if(!companyName)  errorsFound["companyName"]="Oops! please add company name"
    if (!firstName) errorsFound["firstName"] = "First Name is required";
    const nameRegex = /^[A-Za-z ]{1,50}$/;
    if (!nameRegex.test(firstName)) {
      errorsFound["firstName"] =
        "Allows only alphabetical characters (1-50 characters).";
    }
    if (!nameRegex.test(lastName)) {
      errorsFound["lastName"] =
        "Allows only alphabetical characters (1-50 characters).";
    }
    if (!lastName) errorsFound["lastName"] = "Last Name is required";
    if (!employeeId) {
      if (
        !confirmPassword ||
        confirmPassword === "" ||
        confirmPassword !== password
      )
        errorsFound["confirmPassword"] = "Confirm password is required";
      if (!password || password === "")
        errorsFound["password"] = "Password is required";

      if (confirmPassword && confirmPassword !== password)
        errorsFound["confirmPassword"] = "Confirm password doesn't match";
    }

    if (!designation) errorsFound["designation"] = "Designation is required";
    // if (!experienceNumberOfYears)
    //   errorsFound["experienceNumberOfYears"] =
    //     "Total number of experience in years is required";
    // if (experienceNumberOfMonths === null || experienceNumberOfMonths === undefined)
    //   errorsFound["experienceNumberOfMonths"] =
    //     "Total number of experience in months is required";

    // if (experienceNumberOfYears % 1 !== 0) {
    //   errorsFound["experienceNumberOfYears"] =
    //     "Total number of experience in years cannot be in decimal";
    // }
    // if (experienceNumberOfYears === '') {
    //   errorsFound["experienceNumberOfYears"] =
    //     "Invalid total number of experience in years ";
    // }
    // if (experienceNumberOfYears > 100){
    //   errorsFound["experienceNumberOfYears"] =
    //   "Total number of experience in years cannot exceed 100";
    // }

    // if (experienceNumberOfMonths < 0 || experienceNumberOfMonths > 12) {
    //   errorsFound["experienceNumberOfMonths"] =
    //     "Total number of experience in months should be between 0 and 12";
    // }
    // if (experienceNumberOfMonths % 1 !== 0) {
    //   errorsFound["experienceNumberOfMonths"] =
    //     "Total number of experience in months cannot be in decimal";
    // }
    // if (experienceNumberOfMonths === '') {
    //   errorsFound["experienceNumberOfMonths"] =
    //     "Invalid total number of experience in months ";
    // }
    if (!email) errorsFound["email"] = "Email is required";
    if (!mobile || mobile === "")
      errorsFound["mobile"] = "Mobile number is required";
    if (mobile && isInvalidPhone)
      errorsFound["mobileNumberVal"] = "Mobile number is invalid";

    if (Object.keys(errorsFound).length !== 0) {
      setErrors(errorsFound);
      return false;
    }
    return true;
  };

  const saveHandler = async (e) => {
    const isValid = await validation();

    e.preventDefault();

    if (isValid) {
      dispatch({
        type: "REQUEST_BEGINS",
        loader: true,
        result: null,
      });
      const data = new FormData();
      data.append("firstName", formData?.firstName);
      data.append("lastName", formData?.lastName);
      data.append("designation", formData.designation);
      // data.append("experienceNumberOfYears", formData.experienceNumberOfYears);
      // data.append(
      //   "experienceNumberOfMonths",
      //   formData.experienceNumberOfMonths
      // );
      data.append("email", formData.email);
      data.append("mobile", formData.mobile);
      data.append("profilePhoto", formData.profilePhoto);
      data.append("password", formData.password);
      data.append("confirmPassword", formData.confirmPassword);

      if (employeeId) data.append("_id", formData._id);

      if (employeeId) {
        axiosAccountInstance
          .post("/managerupdate", data)
          .then((res) => {
            toast.success(res.data.body.message);

            dispatch({
              type: "REQUEST_SUCCESS",
              loader: false,
              result: res,
            });
            history.push("/admin/list-managers");
          })
          .catch((err) => {
            toast.error(err.response.data.body.message);
            dispatch({
              type: "REQUEST_FAILURE",
              loader: false,
              result: null,
            });
          });
      } else {
        axiosAccountInstance
          .post("/managercreate", data)
          .then((res) => {
            if (res.status === 201) {
              toast.success(res.data.body.message);
              dispatch({
                type: "REQUEST_SUCCESS",
                loader: false,
                result: res,
              });
            }
            history.push("/admin/list-managers");
          })
          .catch((err) => {
            toast.error(err.response.data.body.message);
            dispatch({
              type: "REQUEST_FAILURE",
              loader: false,
              result: null,
            });
          });
      }
    } else {
      console.log("errors");
    }
  };

  return (
    <>
      <DashboardHeader />
      <Container className="mt-4 main-contentarea container-fluid" fluid>
        <Card className="card bg-white shadow border-0 my-4 mt-13">
          <CardBody>
            <AddBasicDetails
              isEdit={isEdit}
              formData={formData}
              handleOnChange={handleOnChange}
              errors={errors}
              phoneNumberInputChnage={phoneNumberInputChnage}
              handleMobileValidation={handleMobileValidation}
              imageSelect={(image) => imageSelect(image)}
              employeeId={employeeId}
              saveHandler={saveHandler}
            />

            <div className="text-center">
              <Button
                className="my-4"
                color="primary"
                type="button"
                disabled={submitLoader}
                onClick={saveHandler}
              >
                {submitLoader ? "Please wait" : "Submit"}
              </Button>
            </div>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default Container1;
