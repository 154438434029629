import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  // Tooltip,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import history from "helpers/History";
// import { setEmployeeDeleteInfo } from "redux/actions/super-admin/EmployeeListAction";

import "react-toastify/dist/ReactToastify.css";
// import Toggle from "components/switch/Toggle";
import Tables from "views/examples/Tables";
import { axiosAccountInstance } from "api/Request";
import toast from "../../../../components/Toastify";
import { setManagerListInfo } from "redux/actions/super-admin/ManagerListAction";
import editIcn from "../../../../assets/img/icons/customIcons/edit.svg";
import deleteIcn from "../../../../assets/img/icons/customIcons/delete.svg";

function ManagerCard({ searchFormData, companyId, loader }) {
  const dispatch = useDispatch();
  const [, setData] = useState([]);

  const [modal, setModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [actionButtons, setActionButtons] = useState([]);

  useEffect(() => {
    if (companyId) {
      setActionButtons([]);
    } else {
      setActionButtons([
        {
          columnName: "",
          type: "actionButton",
          btnIcon: editIcn,
          btnTitle: "Edit",
          icon: <i className="fa fa-eraser" aria-hidden="true"></i>,
          toolTip: "Edit",
          btnTooltip: "Edit",
          btnType: "primary",
          btn: "ManagerEdit",
          actionFunction: viewEditEmployeeHandler,
        },
        {
          columnName: "",
          btnTitle: "Delete",
          type: "actionButton",
          btnTooltip: "Delete",
          btnIcon: deleteIcn,
          btn: "ManagerDelete",
          icon: <i className="fa fa-trash" aria-hidden="true"></i>,
          toolTip: "Delete",
          btnType: "danger",
          actionFunction: deleteEmployee,
        },
      ]);
    }
    return () => {
      setActionButtons([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchEmployeeListResult = useSelector(
    (state) => state.ManagerListReducer.result
  );
  const close = () => {
    setModal(false);
  };

  useEffect(() => {
    if (fetchEmployeeListResult) {
      setData(fetchEmployeeListResult.managers);
    }
    return () => {
      setData([]);
    };
  }, [fetchEmployeeListResult]);

  const deleteEmployee = (id) => {
    setSelectedEmployee(id);
    setModal(!modal);
    // dispatch(setEmployeeDeleteInfo(id));
  };

  const submit = () => {
    axiosAccountInstance
      .delete(`/userdelete/${selectedEmployee}`)
      .then((res) => {
        toast.success(res.data.body.message);
        dispatch({
          type: "REQUEST_SUCCESS",
          loader: false,
          result: res,
        });
        setModal(false);
        dispatch(setManagerListInfo(searchFormData, companyId));
      })
      .catch((err) => {
        setModal(false);
        toast.error(err.response.data.body.data.details[0].message);
        dispatch({
          type: "REQUEST_FAILURE",
          loader: false,
          result: null,
        });
      });
  };

  const viewEditEmployeeHandler = (id) => {
    history.push(`/admin/edit-manager/${id}`);
  };
  const colums = [
    { columnName: "Manager Name", columnTag: "name", type: "data" },
    { columnName: "Email Address", columnTag: "email", type: "data" },
    { columnName: "Designation", columnTag: "designation", type: "data" },
    { columnName: "Created", columnTag: "createdAt", type: "data" },
    { columnName: "", columnTag: "", type: "" },
  ];

  return (
    <div>
      <Tables
        coloums={colums}
        data={fetchEmployeeListResult?.managers}
        actionButtons={actionButtons}
        loader={loader}
        isManager={true}
      />

      <Modal toggle={close} size="sm" centered isOpen={modal}>
        <Row>
          <div className="col">
            <ModalHeader className="text-uppercase font-weight-bolder">
              Delete manager
            </ModalHeader>
          </div>
          <Row>
            <Col>
              <ModalHeader className="text-uppercase">
                <Button onClick={close} className="mx-1" close />
              </ModalHeader>
            </Col>
          </Row>
        </Row>
        <ModalBody>
          <label className="font-weight-normal mb-0">
            Do you want to delete the manager?
          </label>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" onClick={() => submit()} color="danger">
            Yes
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ManagerCard;
