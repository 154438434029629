/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import HeaderLogin from "components/Headers/headerLogin";
import React from "react";
import { useLocation, Route, Switch } from "react-router-dom";
// reactstrap components
import { Container, Row } from "reactstrap";
// core components
// import AuthNavbar from "components/Navbars/AuthNavbar.js";
// import AuthFooter from "components/Footers/AuthFooter.js";

// import routes from "routes.js";
import { authRoutes as routes } from "routes";


const Auth = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const d = new Date();
  let year = d.getFullYear();
  React.useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="main-content logincontent" ref={mainContent} >
        <HeaderLogin />
        <Container>
          <Row className="justify-content-center startpage-outer">
            <Switch>
              {getRoutes(routes)}
            </Switch>
          </Row> 
          <div className="footer-wrapper">
          <p className="mt-5 text-white text-md-start text-center cstm-footer">
                © {year} ScaleUpHQ. All Rights Reserved.
              </p>
          </div>
        </Container>
      </div>
      

    </>
  );
};

export default Auth;
